import React from "react";
import backgroundImage from '../img/fdo-plancorporativo.jpg';

const containerStyle = {
  backgroundImage: `url(${backgroundImage})`,
  backgroundSize: 'cover',
  minHeight: "75vh",
  minWidth: '40vh',
};

class DivHome extends React.Component {
  render() {
    return (
      <div style={containerStyle}></div>
    );
  }
}

export default DivHome;
