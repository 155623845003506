import React, { Component } from "react";
import Box from '@mui/material/Box';
import $ from 'jquery';

import { url, estilosSelect } from "../../utils/Globals";
import { H, FormInput, BotonCancelar, SwitchActivo, FormInputEditablePesos, FormInputEditable } from "../../utils/Controles";

import SelectReact from 'react-select';
import { Alert, FormControl, FormControlLabel, FormGroup, Radio } from "@mui/material";
import { format } from "date-fns";
import Swal from 'sweetalert2';

//Grilla
import { AgGridReact } from 'ag-grid-react';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { localeEs } from "../../utils/locale.es";

/*DatePicker */
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from 'date-fns/locale/es';

class BotonDescargar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: props.token,
    };
  }

  componentDidMount() {
    const botonDescargar = document.getElementById('boton_descargar');
    botonDescargar.addEventListener('click', this.fetchData); // Utiliza this.handleClick sin bind
  }

  componentWillUnmount() {
    const botonDescargar = document.getElementById('boton_descargar');
    botonDescargar.removeEventListener('click', this.handleClick); // Limpia el evento al desmontar el componente
  }

  fetchData = async () => {
    try {
      const headers = {
        'x-access-token': this.state.token,
      };
      const response = await fetch(`${url}precios/importar`, { headers });

      if (response.ok) {
        const blob = await response.blob();

        // Crear un objeto URL a partir del blob
        const fileURL = URL.createObjectURL(blob);

        // Crear un enlace <a> oculto para descargar el archivo
        const downloadLink = document.createElement('a');
        downloadLink.href = fileURL;
        downloadLink.download = 'Plantilla Precios.xlsx';
        downloadLink.style.display = 'none';

        // Agregar el enlace al DOM y simular un clic
        document.body.appendChild(downloadLink);
        downloadLink.click();

        // Limpiar el enlace y el objeto URL después de la descarga
        URL.revokeObjectURL(fileURL);
        document.body.removeChild(downloadLink);
      } else {
        console.error('No se pudo descargar el archivo:', response.status);
      }
    } catch (error) {
      console.error('Error al obtener los datos:', error);
    }
  };

  render() {
    return (
      <div className="form-floating col-2">
        <img src="https://verticall.ar/broadcast/img/icons/descarga.png" width="32" height="32" alt="Icono de Descarga" />
        <button id="boton_descargar" type="button" className="btn btn-met">
          Descargar Plantilla
        </button>
      </div>
    );
  }
}
class RadioImportar extends Component {
  render() {
    return (
      <FormControl component="fieldset">
        <FormGroup aria-label="position">
          <FormControlLabel
            value={"Nueva"}
            control={<Radio />}
            label="Nueva Lista de precios"
            checked={this.props.importar === 'Nueva'}
            onChange={this.props.handleImportarChange}
          />
          <FormControlLabel
            value={"Actualizar"}
            control={<Radio />}
            label="Actualizar Lista de precios existente"
            checked={this.props.importar === 'Actualizar'}
            onChange={this.props.handleImportarChange}
          />
        </FormGroup>
      </FormControl>
    );
  }
}
class FormPrecios extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: this.props.token,
      titulo: "Precios",
      tituloExistente: "",
      listaDePrecios: "",
      plan: "",
      rangoEtario: "",
      precio: "",
      habilitado: "",
      creadoEl: "",
      idRegistro: "",

      data: [],
      dataPlan: [],
      dataListaDePrecio: [],
      dataRangos: [],
      clearSelectPlan: false,
      clearSelectRangos: false,
      clearSelectlistaDePrecios: false,
      listaKey: '',
      rangosKey: '',
      importar: 'Nueva',
      nombre: '',
      desde: "",
      hasta: "",
      archivo: '',
      error: '',
      headers: {
        'x-access-token': `${this.props.token}`,
      },
    }
  }
  componentDidMount() {
    this.fetchData();
    this.fetchDataListaDePrecio();
    this.fetchDataPlanes();
    $("#agregar").hide();
    $("#importar").hide();
    $("#existente").hide();
    $("#creado_el").parent().hide();
    $("#importarNueva").show();
    $("#importarActualizar").hide();
    $("#switchActivo").hide();
    $(".btn-met-cancelar").on("click", () => {
      this.setState({ importar: 'Nueva', listaKey: Date.now() });
      this.limpiarFormulario();
      document.getElementById("archivo_importar").value = "";
      $("#existente").hide();
      $("#listado").show();
      $("#agregar").hide();
      $("#importar").hide();
    });

  }
  crearRegistro = () => {
    this.setState({ listaDePrecios: '', plan: '', rangoEtario: '', precio: '', idRegistro: "", dataRangos: [], clearSelectPlan: true, rangosKey: Date.now(), listaKey: Date.now() }, () => {
      $("#precio").css({
        'border-color': '',
        'color': '',
        'border-width': '',
        'padding-left': '1.5rem'
      });
      $("#selectListaDePrecio .css-1cza3nx-control").removeAttr('style');
      $("#selectPlan .css-1cza3nx-control").removeAttr('style');
      $("#selectRangos .css-1cza3nx-control").removeAttr('style');
      $("#listado").hide();
      $("#existente").hide();
      $("#agregar").show();
    })
  }
  importar = () => {
    $("#listado").hide();
    $("#importar").show();
  }
  guardarNuevo = () => {
    let validacion = this.validar();
    if (validacion) {
      var respuestaJson;
      const Data = {
        listaDePrecios: this.state.listaDePrecios,
        rangoEtario: this.state.rangoEtario,
        precio: `${this.state.precio}`,
      };
      const headers = {
        'x-access-token': `${this.state.token}`,
        "Content-Type": "application/json"
      };

      fetch(url + "precios", {
        method: 'POST',
        body: JSON.stringify(Data),
        headers
      }).then(response => {
        if (!response.ok) {
          console.log("Error en la petición");
          console.log(response);
        }
        else {
          respuestaJson = response.json();
        }
        return respuestaJson;
      }).then(respuestaJson => {
        this.actualizarGrilla();
        Swal.fire({
          icon: "success",
          title: "Precio creado",
          timer: 2000,
          text: "Se creo el registro correctamente.",
        });
        $("#listado").show();
        $("#agregar").hide();
      })
        .catch(err => {
          console.error('Error al enviar la petición POST:', err);
        });
    } else {
      Swal.fire({
        icon: "error",
        text: 'Para continuar debe completar todos los campos.',
        width: 500,
        height: 200,
      });
    }

  }
  actualizarRegistro = () => {
    let validacion = this.validar();
    if (validacion) {
      var respuestaJson;
      const headers = {
        'x-access-token': `${this.state.token}`,
        "Content-Type": "application/json"
      };
      let precio = this.state.precio;
      precio = precio.replace("$", "");
      precio = precio.replace("%", "");

      precio = precio.replace(",", ".");
      const Data = {
        listaDePrecios: this.state.listaDePrecios,
        rangoEtario: this.state.rangoEtario,
        precio: `${precio}`,
      };

      fetch(url + "precios/" + this.state.idRegistro, {
        method: 'PUT',
        body: JSON.stringify(Data),
        headers
      }).then(response => {
        if (!response.ok) {
          console.log("Error en la petición");
          console.log(response);
        }
        else {
          respuestaJson = response.json();
        }
        return respuestaJson;
      }).then(() => {
        this.limpiarFormulario();
        Swal.fire({
          icon: "success",
          title: "Precio actualizado",
          timer: 2000,
          text: "Se actualizo el registro correctamente.",
        });
        this.actualizarGrilla();
        $("#existente").hide();
        $("#listado").show();
        $("#agregar").hide();
        $("#importar").hide();
      }).catch(err => {
        console.error('Error al enviar la petición POST:', err);
      });
    } else {
      Swal.fire({
        icon: "error",
        text: 'Para continuar debe completar todos los campos.',
        width: 500,
        height: 200,
      });
    }

  }
  async eliminar(id) {
    Swal.fire({
      title: "¿Está seguro que quiere eliminar el registro?",
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "No",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si"
    }).then((result) => {
      if (result.isConfirmed) {
        const headers = {
          'x-access-token': `${this.state.token}`,
          "Content-Type": "application/json"
        };
        fetch(url + "precios/" + id, {
          method: 'Delete',
          headers
        }).then(() => {
          Swal.fire({
            title: "Se elimino el registro",
            icon: "success",
            timer: 2000
          });
          this.actualizarGrilla();
        }).catch(err => {
          console.error('Error al enviar la petición POST:', err);
        });

      }
    });
  }

  importarPrecios = () => {
    var respuestaJson;
    let validado = this.validar("importar");

    if (validado) {
      const formData = new FormData();
      if (this.state.importar === 'Actualizar') {
        formData.append('listaDePrecios', this.state.listaDePrecios);
      } else {
        var desde = null;
        var hasta = null;
        if (this.state.desde !== null && this.state.desde !== '') {
          var parts = $("#desde").val().split(/[\/-]/);
          var aux = parts[2] + "-" + parts[1] + "-" + parts[0];
          desde = aux + "T00:00:00.000Z";
          formData.append('desde', desde);
        }
        if (this.state.hasta !== null && this.state.hasta !== '') {
          var parts = $("#hasta").val().split(/[\/-]/);
          var aux = parts[2] + "-" + parts[1] + "-" + parts[0];
          hasta = aux + "T00:00:00.000Z";
          formData.append('hasta', hasta);
        }


        formData.append('nuevaListaDePrecios', this.state.nombre);
      }
      formData.append('archivo', this.state.archivo);
      const headers = {
        'x-access-token': `${this.state.token}`,
      };
      console.log(formData)
      fetch(url + "precios/importar", {
        method: 'POST',
        body: formData,
        headers
      })
        .then(response => {
          if (response.ok) {
            respuestaJson = response.json();
            return respuestaJson;
          }
          else {
            if (response.status >= 400 && response.status < 500) {
              return Promise.reject("Los datos ingresados son inválidos, por favor revíselos e intente nuevamente");
            }
            else {
              console.log(response);
              return Promise.reject("Se produjo un error al importar, por favor intente nuevamente");
            }
          }
        })
        .then(respuestaJson => {
          if (respuestaJson.error !== null) {
            var mensajeError = respuestaJson.error.texto;
            if (respuestaJson.error.listado) {
              mensajeError += "<br>" + respuestaJson.error.listado.join('<br>')
            }
            Swal.fire({
              icon: "error",
              html: mensajeError,
              width: 500,
              height: 200,
            });
          }
          else {
            this.actualizarGrilla();
            Swal.fire({
              icon: "success",
              title: "Precios importados",
              timer: 2000,
              text: "Se importaron los registros correctamente.",
            });
            this.fetchDataListaDePrecio();
            $("#listado").show();
            $("#importar").hide();
            document.getElementById("archivo_importar").value = "";
            this.setState({ importar: 'Nueva', listaKey: Date.now() });
            this.limpiarFormulario();

          }

        })
        .catch(error => {
          if (typeof error !== "string") {
            error = "Se produjo un error al importar, por favor intente nuevamente";
          }
          Swal.fire({
            icon: "error",
            html: error,
            width: 500,
            height: 200,
          });
        });
    }
  }

  async fetchData() {
    try {
      const { headers } = this.state;
      const response = await fetch(url + "precios?conPrecio=1&detallado=1&habilitado=1", { headers });
      const responseData = await response.json();
      this.setState({ data: responseData }, () => { });
    } catch (error) {
      console.error('Error al obtener los datos:', error);
    }
  }
  async fetchDataPlanes() {
    try {
      const { headers } = this.state;
      const response = await fetch(url + `planes`, { headers });//?conPrecio=1&listaDePrecios=${this.state.listaDePrecios}
      const responseData = await response.json();
      this.setState({ dataPlan: responseData, rangoEtario: '' });
    } catch (error) {
      console.error('Error al obtener los datos:', error);
    }
  }
  async fetchDataListaDePrecio() {
    try {
      const { headers } = this.state;
      const response = await fetch(url + "listas-de-precios?habilitado=1", { headers });
      const responseData = await response.json();
      this.setState({ dataListaDePrecio: responseData });
    } catch (error) {
      console.error('Error al obtener los datos:', error);
    }
  }
  async fetchDataRango() {
    try {
      const { headers } = this.state;
      const response = await fetch(url + `rangos-etarios?plan=${this.state.plan}`, { headers });
      const responseData = await response.json();
      this.setState({ dataRangos: responseData });
    } catch (error) {
      console.error('Error al obtener los datos:', error);
    }
  }
  async obtenerRegistro(id) {
    try {
      this.fetchDataPlanes();
      const { headers } = this.state;
      const response = await fetch(url + `precios/${id}?completo=1`, { headers });
      let responseData = await response.json();

      if (responseData) {
        var listaDePrecios = responseData.listaDePrecios.id;
        var plan = responseData.rangoEtario.plan.id;
        var rangoEtario = responseData.rangoEtario.id;
        var creado_el = responseData.creadoEl.substring(0, 10);
        var habilitado = responseData.deshabilitadoEl === null ? 'Si' : 'No';
        var precio = responseData.precio;

        this.setState({ listaDePrecios: listaDePrecios, plan: plan, rangoEtario: rangoEtario, creadoEl: creado_el, habilitado: habilitado, precio: precio, idRegistro: responseData.id }, async () => {
          await this.fetchDataRango();
          let tituloExistente = this.state.precio !== null ? `Precio: $${this.formatearPrecio(this.state.precio)}` : "";
          this.setState({ tituloExistente: tituloExistente });

          $("#listado").hide();
          $("#existente").show();
          $("#agregar").hide();
        });
      }
    } catch (error) {
      console.error('Error al obtener los datos:', error);
    }
  }
  limpiarFormulario = () => {
    $("#importarNueva").show();
    $("#importarActualizar").hide();
    this.setState({ listaDePrecios: '', plan: '', rangoEtario: '', precio: '', idRegistro: "", nombre: '', desde: '', hasta: '' }, () => {
      var campos = [
        { nombre: "#selectPlan .css-1cza3nx-control" },
        { nombre: "#selectRangos .css-1cza3nx-control" },
        { nombre: "#desde" },
        { nombre: "#hasta" },
        { nombre: "#archivo_importar" },
        { nombre: "#listaPrecio" },
      ];
      campos.forEach(function (campo) {
        var elementos = document.querySelectorAll(campo.nombre);
        elementos.forEach(function (elemento) {
          $(elemento).removeAttr('style');
        })
      });
      var elementos = document.querySelectorAll("#precio");
      elementos.forEach(function (elemento) {
        $(elemento).css({
          'border-color': '',
          'color': '',
          'border-width': '',
          'padding-left': '1.5rem'
        });
      })
      $("#selectListaDePrecio .css-1cza3nx-control").removeAttr('style');
    })

  }
  validar = (modo) => {
    let respuesta = true;
    let error = "";
    var errorStyles = {
      'border-color': '#f56565',
      'color': '#2a2222',
      'border-width': '3px',
    };
    if (modo !== "importar") {
      let listaDePrecios = this.state.listaDePrecios;
      let rangoEtario = this.state.rangoEtario;
      let plan = this.state.plan;
      var campos = [
        { nombre: this.state.precio, selector: "#precio", tipo: "id" },
      ];
      campos.forEach(function (campo) {
        if (campo.nombre === "" || campo.nombre === null || campo.nombre === undefined) {
          try {
            var elementos = document.querySelectorAll(campo.selector);
            elementos.forEach(function (elemento) {
              $(elemento).css(errorStyles);
            })
          } catch {
            console.log(campo.selector);
          }
          if (campo.tipo === "id") {
            document.getElementById(campo.selector.substring(1)).focus();
            document.getElementById(campo.selector.substring(1)).select();
          }

          respuesta = false;
        } else {
          $(campo.selector).css({
            'border-color': '',
            'color': '',
            'border-width': '',
          });
          $(campo.selector).addClass('form-control');
        }
      });
      if (listaDePrecios === "") {
        $("#selectListaDePrecio .css-1cza3nx-control").css(errorStyles);
        respuesta = false;
      } else {
        $("#selectListaDePrecio .css-1cza3nx-control").removeAttr('style');
      }
      if (plan === "") {
        $("#selectPlan .css-1cza3nx-control").css(errorStyles);
        respuesta = false;
      } else {
        $("#selectPlan .css-1cza3nx-control").removeAttr('style');
      }
      if (rangoEtario === "") {
        $("#selectRangos .css-1cza3nx-control").css(errorStyles);
        respuesta = false;
      } else {
        $("#selectRangos .css-1cza3nx-control").removeAttr('style');
      }
    } else {
      var campos = [];
      if (this.state.importar === "Actualizar") {
        campos.push({ nombre: this.state.archivo, selector: "#archivo_importar", tipo: "id" },);
        if (this.state.listaDePrecios === "") {
          $("#selectListaDePrecio .css-1cza3nx-control").css(errorStyles);
          respuesta = false;
        } else {
          $("#selectListaDePrecio .css-1cza3nx-control").removeAttr('style');
        }
      } else {
        campos.push({ nombre: this.state.nombre, selector: "#listaPrecio", tipo: "id" });
        campos.push({ nombre: this.state.archivo, selector: "#archivo_importar", tipo: "id" },);
        var existeLista = this.state.dataListaDePrecio.find(option => option.nombre === this.state.nombre);
        if (existeLista) {
          respuesta = false;
          error = 'La lista de precios ya existe.'
          var elementos = document.querySelectorAll("#listaPrecio");
          elementos.forEach(function (elemento) {
            $(elemento).css(errorStyles);
          })
          Swal.fire({
            icon: "error",
            text: error,
            width: 500,
            height: 200,
          });
          return respuesta;
        }
        let desde = null;
        let hasta = null;

        if (this.state.desde !== "" && this.state.desde !== undefined && this.state.desde !== null) {
          desde = new Date(this.state.desde);
          campos.push({ nombre: this.state.desde, selector: "#desde", tipo: "id" });
        }
        if (this.state.hasta !== "" && this.state.hasta !== undefined && this.state.hasta !== null) {
          hasta = new Date(this.state.hasta);
          campos.push({ nombre: this.state.hasta, selector: "#hasta", tipo: "id" },);
        }
        if (hasta) {
          let ahora = new Date(format(new Date(), "yyyy-MM-dd"));
          if (hasta.getTime() <= ahora.getTime()) {
            respuesta = false;
            Swal.fire({
              icon: "error",
              text: 'La fecha del campo "Hasta" debe ser posterior a la fecha actual.',
              width: 500,
              height: 200,
            });
            elementos = document.querySelectorAll("#hasta");
            elementos.forEach(function (elemento) {
              $(elemento).css(errorStyles);
            })
          }
          else if (desde) {
            if (hasta.getTime() <= desde.getTime()) {
              respuesta = false;
              Swal.fire({
                icon: "error",
                text: 'La fecha del campo "Desde" debe ser anterior que la del campo "Hasta".',
                width: 500,
                height: 200,
              });
              elementos = document.querySelectorAll("#hasta");
              elementos.forEach(function (elemento) {
                $(elemento).css(errorStyles);
              })
            }
          }
        }

      }
      let vacio = false;
      if (respuesta) {
        campos.forEach(function (campo) {
          if (campo.nombre === "" || campo.nombre === null || campo.nombre === undefined) {
            vacio = true;
            try {
              var elementos = document.querySelectorAll(campo.selector);
              elementos.forEach(function (elemento) {
                $(elemento).css(errorStyles);
              })
            } catch {
            }
            if (campo.tipo === "id") {
              document.getElementById(campo.selector.substring(1)).focus();
              document.getElementById(campo.selector.substring(1)).select();
            }
          } else {
            $(campo.selector).removeAttr('style');
            $(campo.selector).addClass('form-control');
          }
        });
      }
      if (vacio) {
        respuesta = false;
        if (this.state.error === "") {
          error = 'Para continuar debe completar todos los campos.'
        }
      }
      if (error) {
        Swal.fire({
          icon: "error",
          text: error,
          width: 500,
          height: 200,
        });
      }
    }
    return respuesta;
  }

  handleListaChange = (opcion) => {
    if (opcion) {
      this.setState(
        {
          listaDePrecios: opcion.id,
        }
      );
    }
  }
  handlePlanChange = (opcion) => {
    if (opcion) {
      this.setState({
        plan: opcion.id,
        clearSelectPlan: false,
        rangoEtario: '',
        clearSelectRangos: true,
        rangosKey: Date.now(),
      }, () => {
        this.fetchDataRango();
      });
    }

  }
  handleRangosChange = (opcion) => {
    if (opcion) {
      this.setState({
        rangoEtario: opcion.id,
        clearSelectRangos: false,
      });
    }
  }
  handlePrecioChange = (newValue) => {
    var precio = String(newValue).replace(/[^0-9.]/g, '');
    this.setState({ precio: precio });
  }
  handleLista = (newValue) => {
    this.setState({ nombre: newValue });
  }
  handleFileChange = (event) => {
    const archivoCargado = event.target.files[0]; // Obtener el archivo cargado
    this.setState({ archivo: archivoCargado });
  }
  setFechaDesde = (fecha) => {
    this.setState({ desde: fecha });
  }
  setFechaHasta = (fecha) => {
    this.setState({ hasta: fecha });
  }
  handleActivoChange = (event) => {
    let habilitar = ''
    if (event.target.checked === true) {
      habilitar = 'Si';
    } else {
      habilitar = 'No';
    }
    this.setState({ habilitado: habilitar });
  }
  handleImportarChange = (event) => {
    var importar = event.target.value;
    /*Esto queda por si se cambia a un switch
    let nuevo = ''
    if (event.target.checked === true) {
      nuevo = 'Actualizar';
    } else {
      nuevo = 'Nueva';
    }*/
    this.limpiarFormulario();
    if (importar === 'Nueva') {
      $("#importarNueva").show();
      $("#importarActualizar").hide();
      this.setState({ nombre: '', desde: '', hasta: '' })
    } else {
      $("#importarNueva").hide();
      $("#importarActualizar").show();
    }
    this.setState({ importar: importar });
  }
  actualizarGrilla() {
    this.fetchData();
  }
  formatearPrecio = valor => {
    let numero = parseFloat(valor);
    if (!numero) {
      return "0,00";
    }
    else {
      numero = Math.round(numero * 100) / 100
      let resultado = numero.toLocaleString("es-ar");
      if (resultado === "0") {
        return "0,00";
      }
      else {
        let posicionComa = resultado.indexOf(",");
        if (posicionComa === -1) {
          resultado = resultado.concat(",00");
        }
        else if (resultado.length - posicionComa === 2) {
          resultado = resultado.concat("0");
        }
        return resultado;
      }
    }
  }
  handleDoubleClick = (event) => {
    this.obtenerRegistro(event.data.id);
  }
  render() {
    const { data } = this.state;
    const rows = data.map((item) => ({
      id: item.id,
      nombre: item.nombre,
      listaDePrecios: item.listaDePrecios,
      plan: item.plan,
      rangoEtario: item.rangoEtario,
      precio: "$" + this.formatearPrecio(item.precio),
    }));
    const agregarBotones = props => {
      return (
        <div className="contenedorBoton">
          <button className="botonVer" onClick={() => this.obtenerRegistro(props.data.id)}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-eye" viewBox="0 0 16 16">
              <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
              <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
            </svg>
          </button>
          <button onClick={() => this.eliminar(props.data.id)} className="botonBorrar">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16">
              <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z" />
              <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z" />
            </svg>
          </button>
        </div>

      )
    }
    const Columnas = [
      {
        field: 'listaDePrecios', flex: 1, type: 'text', filter: 'agSetColumnFilter', renderHeader: () => (<strong> {'Lista de Precios'}</strong>),
      },
      {
        field: 'plan', flex: 1, type: 'text', filter: 'agSetColumnFilter', renderHeader: () => (<strong> {'Plan'}</strong>),
      },
      {
        field: 'rangoEtario', flex: 1, type: 'text', filter: 'agSetColumnFilter', renderHeader: () => (<strong> {'Rango Etario'}</strong>),
      },
      {
        field: 'precio', flex: 1, type: 'text', filter: 'agSetColumnFilter', renderHeader: () => (<strong> {'Precio'}</strong>),
      },
      {
        headerName: "",
        field: "",
        sortable: false,
        filter: false,
        width: "100px",
        cellRenderer: agregarBotones,
        cellRendererParams: {
          id: 1
        }
      },
    ];
    return (
      <div style={{ height: '100%', width: '100%' }}>
        <div id="listado">
          <div>
            <section>
              <div style={{ height: '50%', width: '100%', padding: "10px 0px 0px 0px" }}>
                <Box sx={{ width: '100%' }} style={{ backgroundColor: "white" }}>
                  <H texto={this.state.titulo} tamano={3}></H>
                  <div className="row g-3">
                    <div className="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups">
                      <div className="btn-group btn-group-lg" role="group" aria-label="First group">
                        <button type="button" className="btn btn-secondary" onClick={() => this.crearRegistro()} id="boton_nuevo">
                          <center>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-plus" viewBox="0 0 16 16">
                              <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                            </svg>
                          </center>
                        </button>
                        <button type="button" className="btn btn-secondary" onClick={() => this.importar()} id="boton_importar">
                          <center>
                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-file-earmark-arrow-up" viewBox="0 0 16 16">
                              <path d="M8.5 11.5a.5.5 0 0 1-1 0V7.707L6.354 8.854a.5.5 0 1 1-.708-.708l2-2a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 7.707z" />
                              <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2M9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z" />
                            </svg>
                          </center>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div
                    className="ag-theme-quartz"
                    style={{
                      width: "100%",
                      "--ag-font-size": "16px"
                    }}
                  >
                    <AgGridReact
                      columnDefs={Columnas}
                      rowData={rows}
                      pagination={true}
                      paginationPageSize={10}
                      paginationPageSizeSelector={[10, 20, 50, 100]}
                      domLayout='autoHeight'
                      localeText={localeEs}
                      reactiveCustomComponents={true}
                      onCellDoubleClicked={this.handleDoubleClick}
                    />

                  </div>
                </Box>
              </div>
            </section>
          </div>
        </div>
        <div id="importar">
          <div>
            <br />
            <H texto={"Importar - Precios"} tamano={3}></H>
            <section>
              <div className="row g-3">
                <div id="switchLista" className="row g-3">
                  <div className="form-floating col-md-3">
                    <RadioImportar importar={this.state.importar} handleImportarChange={this.handleImportarChange} />
                  </div>
                </div>
                <BotonDescargar token={this.state.token} />
                <div className="form-floating col-4">
                  <Alert severity="info" color="success">
                    Recuerde descargar la última versión de la plantilla.
                  </Alert>
                </div>
              </div>
              <br />
              <div className="row g-3" id="importarNueva">
                <FormInputEditable
                  tamano={3}
                  type="text"
                  id="listaPrecio"
                  value={this.state.nombre}
                  placeholder="Lista de precios"
                  name="listaPrecio"
                  readonly={"false"}
                  onChange={this.handleLista}
                />
                <div className="col-md-3">
                  <div className="date-picker">
                    <label htmlFor="desde" className="date-picker-label">Desde</label>
                    <DatePicker
                      id="desde"
                      dateFormat="dd/MM/yyyy"
                      selected={this.state.desde}
                      onChange={(fecha) => this.setFechaDesde(fecha)}
                      locale="es"
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="date-picker">
                    <label htmlFor="hasta" className="date-picker-label">Hasta</label>
                    <DatePicker
                      id="hasta"
                      dateFormat="dd/MM/yyyy"
                      selected={this.state.hasta}
                      onChange={(fecha) => this.setFechaHasta(fecha)}
                      locale="es"
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <div className="row g-3" id="importarActualizar">
                <div className="form-floating select-met col-md-3" id="selectListaDePrecio">
                  <SelectReact
                    name={"listaDePrecios"}
                    inputId={"listaDePrecios"}
                    isClearable={false}
                    isSearchable={false}
                    options={this.state.dataListaDePrecio}
                    getOptionLabel={(opcion) => opcion.nombre}
                    getOptionValue={(opcion) => opcion.id}
                    placeholder=""
                    menuPortalTarget={document.body}
                    menuPosition={'fixed'}
                    menuPlacement="auto"
                    value={this.state.dataListaDePrecio.find(option => option.id === this.state.listaDePrecios)}
                    styles={estilosSelect}
                    onChange={this.handleListaChange}
                    key={this.state.listaKey}
                  />
                  <label htmlFor="listaDePrecios">Lista de Precios</label>
                </div>
              </div>
              <br />
              <div className="row g-3">
                <div className="col-md-6">
                  <input
                    type="file"
                    id="archivo_importar"
                    name="archivo_importar"
                    className="form-control"
                    accept=".xlsx"
                    onChange={this.handleFileChange}
                  />
                </div>

              </div>

              <br />
              <div className="row g-3" style={{zIndex: -1}}>
                <div className="form-floating mb-3 col-md-8"></div>
                <div className="form-floating mb-3 col-md-2" id="div_boton">
                  <button
                    type="button"
                    className="btn btn-met float-end"
                    id="boton_importarPrecios"
                    onClick={this.importarPrecios}
                  >
                    Importar
                  </button>
                </div>
                <BotonCancelar />
              </div>
            </section>
          </div>
        </div>
        <div id="agregar">
          <br></br>
          <H texto={`Nuevo Precio`} tamano={3}></H>
          <br></br>
          <div className="card col-sm-12" >
            <br></br>
            <div className="row g-3">
              <div className="form-floating select-met col-md-3" id="selectListaDePrecio">
                <SelectReact
                  name={"listaDePrecios"}
                  inputId={"listaDePrecios"}
                  isClearable={false}
                  isSearchable={false}
                  options={this.state.dataListaDePrecio}
                  getOptionLabel={(opcion) => opcion.nombre}
                  getOptionValue={(opcion) => opcion.id}
                  placeholder=""
                  menuPortalTarget={document.body}
                  menuPosition={'fixed'}
                  menuPlacement="auto"
                  value={this.state.dataListaDePrecio.find(option => option.id === this.state.listaDePrecios)}
                  styles={estilosSelect}
                  onChange={this.handleListaChange}
                  key={this.state.listaKey}
                />
                <label htmlFor="listaDePrecios">Lista de Precios</label>
              </div>
              <div className="form-floating select-met col-md-3" id="selectPlan">
                <SelectReact
                  name={"plan"}
                  inputId={"plan"}
                  isClearable={false}
                  isSearchable={false}
                  options={this.state.dataPlan}
                  getOptionLabel={(opcion) => opcion.nombre}
                  getOptionValue={(opcion) => opcion.id}
                  placeholder=""
                  menuPortalTarget={document.body}
                  menuPosition={'fixed'}
                  menuPlacement="auto"
                  value={this.state.dataPlan.find(option => option.id === this.state.plan)}
                  styles={estilosSelect}
                  onChange={this.handlePlanChange}
                  key={this.state.clearSelectPlan ? 'clear' : 'not-clear'}
                />
                <label htmlFor="plan">Plan</label>
              </div>
              <div className="form-floating select-met col-md-3" id="selectRangos">
                <SelectReact
                  name={"rangos"}
                  inputId={"rangos"}
                  isClearable={false}
                  isSearchable={false}
                  options={this.state.dataRangos}
                  getOptionLabel={(opcion) => opcion.nombre}
                  getOptionValue={(opcion) => opcion.id}
                  placeholder=""
                  menuPortalTarget={document.body}
                  menuPosition={'fixed'}
                  menuPlacement="auto"
                  value={this.state.dataRangos.find(option => option.id === this.state.rangoEtario)}
                  styles={estilosSelect}
                  onChange={this.handleRangosChange}
                  key={this.state.rangosKey}
                />
                <label htmlFor="rangos">Rango</label>
              </div>
              <FormInputEditablePesos
                tamano={3}
                type="decimal"
                id="precio"
                value={this.state.precio}
                placeholder="Precio"
                name="precio"
                readonly={"false"}
                onChange={this.handlePrecioChange}
              />
            </div>
            <br></br>
            <div className="row g-3">
              <div className="form-floating mb-3 col-md-8"></div>
              <div className="form-floating mb-3 col-md-2" id="div_boton">
                <button
                  type="button"
                  className="btn btn-met float-end"
                  id="boton_generar"
                  onClick={this.guardarNuevo}
                >
                  Guardar
                </button>
              </div>
              <BotonCancelar />
            </div>
          </div>
        </div>
        <div id="existente">
          <div>
            <br></br>
            <div className="card col-sm-12">
              <br></br>
              <H texto={this.state.tituloExistente} tamano={3}></H>
              <br></br>
              <div className="row g-3">
                <div className="form-floating select-met col-md-3" id="selectListaDePrecio">
                  <SelectReact
                    name={"listaDePrecios"}
                    inputId={"listaDePrecios"}
                    isClearable={false}
                    isSearchable={false}
                    options={this.state.dataListaDePrecio}
                    getOptionLabel={(opcion) => opcion.nombre}
                    getOptionValue={(opcion) => opcion.id}
                    placeholder=""
                    menuPortalTarget={document.body}
                    menuPosition={'fixed'}
                    menuPlacement="auto"
                    value={this.state.dataListaDePrecio.find(option => option.id === this.state.listaDePrecios)}
                    styles={estilosSelect}
                    onChange={this.handleListaChange}
                    key={this.state.listaKey}
                  />
                  <label htmlFor="listaDePrecios">Lista de Precios</label>
                </div>
                <div className="form-floating select-met col-md-3" id="selectPlan">
                  <SelectReact
                    name={"plan"}
                    inputId={"plan"}
                    isClearable={false}
                    isSearchable={false}
                    options={this.state.dataPlan}
                    getOptionLabel={(opcion) => opcion.nombre}
                    getOptionValue={(opcion) => opcion.id}
                    placeholder=""
                    menuPortalTarget={document.body}
                    menuPosition={'fixed'}
                    menuPlacement="auto"
                    value={this.state.dataPlan.find(option => option.id === this.state.plan)}
                    styles={estilosSelect}
                    onChange={this.handlePlanChange}
                    key={this.state.clearSelectPlan ? 'clear' : 'not-clear'}
                  />
                  <label htmlFor="plan">Plan</label>
                </div>
                <div className="form-floating select-met col-md-3" id="selectRangos">
                  <SelectReact
                    name={"rangos"}
                    inputId={"rangos"}
                    isClearable={false}
                    isSearchable={false}
                    options={this.state.dataRangos}
                    getOptionLabel={(opcion) => opcion.nombre}
                    getOptionValue={(opcion) => opcion.id}
                    placeholder=""
                    menuPortalTarget={document.body}
                    menuPosition={'fixed'}
                    menuPlacement="auto"
                    value={this.state.dataRangos.find(option => option.id === this.state.rangoEtario)}
                    styles={estilosSelect}
                    onChange={this.handleRangosChange}
                    key={this.state.rangosKey}
                  />
                  <label htmlFor="rangos">Rango</label>
                </div>
                <FormInputEditablePesos
                  tamano={3}
                  type="decimal"
                  id="precio"
                  value={this.state.precio}
                  placeholder="Precio"
                  name="precio"
                  readonly={"false"}
                  onChange={this.handlePrecioChange}
                />
                <FormInput tamano={3} type="text" id="creado_el" value={this.state.creadoEl} placeholder="Creado el" name="creado_el" />
              </div>
              <div id="switchActivo" className="row g-3">
                <FormControl component="fieldset">
                  <FormGroup aria-label="position" row>
                    <FormControlLabel
                      control={<SwitchActivo checked={this.state.habilitado === 'Si'} />}
                      label="Activo"
                      labelPlacement="start"
                      onChange={this.handleActivoChange}
                    />
                  </FormGroup>
                </FormControl>
              </div>
              <div className="row g-3">
                <div className="form-floating mb-3 col-md-8"></div>
                <div className="form-floating mb-3 col-md-2" id="div_boton">
                  <button id="boton_actualizar" type="button" className="btn btn-met float-end" onClick={() => this.actualizarRegistro()}>Actualizar</button>
                </div>
                <div className="form-floating mb-3 col-md-2" id="div_boton">
                  <button id="boton_cancelar" type="button" className="btn btn-met-cancelar float-end">Cancelar</button>
                </div>
              </div>
              <br></br>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export { FormPrecios }