import React, { Component } from "react";
import ReactDOM from 'react-dom/client';
import { H, FormInput } from "../utils/Controles";
import "../styles/Presupuesto.css";
import "bootstrap/dist/css/bootstrap.min.css";
import $ from 'jquery';
import { url } from "../utils/Globals";
import { Cotizaciones } from "./abm/Cotizaciones";
import Cotizador from "./Cotizador";
import Cotizador2 from "./Cotizador2";
const Afiliado = (props) => {
  return (
    <div className="row g-3 filaAfiliado">
      <div className="form-floating col-md-1">
        <div className="form-floating col-md divLabelAfiliado">
          <label
            className="labelAfiliado"
            id={`labelAfiliado${props.index}`}
            style={{
              textAlign: "center",
              padding: "8px 12px 30px 12px"
            }}
          >
            {props.index}
          </label>
        </div>
      </div>
      <div className="form-floating col-md-3">
        <FormInput type="text" value={props.plan} id="plan" placeholder="Plan" name="plan" />
      </div>
      <div className="form-floating col-md-3" id={`ContainerRango${props.index}`}>
        <FormInput type="text" value={props.rangoValue} id="rango_etario" placeholder="Rango etario" name="rango_etario" />
      </div>

    </div>
  );
}
const Bonificaciones = (props) => {
  let observacion = props.observacion;
  let descuento = props.descuento;
  if (!observacion) {
    observacion = " ";
  }

  if (!descuento) {
    descuento = "0.00";
  }
  descuento = `${descuento}%`;
  return (
    <div className="row g-3 filaAfiliado">
      <div className="form-floating col-md-1 ">
        <div className="form-floating col-md divLabelAfiliado">
          <label
            className="labelAfiliado"
            id={`labelBonificacion${props.index}`}
            style={{
              textAlign: "center",
              padding: "8px 12px 30px 12px"
            }}
          >
            {props.index}
          </label>
        </div>
      </div>
      <div className="form-floating col-md-3">
        <FormInput type="text" value={props.tipoBonificacion} id="tipo_de_bonificacion" placeholder="Tipo de bonificación" name="tipo_de_bonificacion" />
      </div>
      <div className="form-floating col-md-3" id={`ContainerRango${props.index}`}>
        <FormInput type="text" value={props.concepto} id="concepto" placeholder="Concepto" />
      </div>
      <div className="form-floating col-md-2" id={`ContainerDescuento${props.index}`}>
        <FormInput type="text" value={descuento} id="descuento" placeholder="Descuento" />
      </div>
      <div className="form-floating col-md-3" id={`ContainerObservacion${props.index}`}>
        <FormInput type="text" value={observacion} id="observacion" placeholder="Observacion" />
      </div>
    </div>
  );
}

class CargarAfiliados extends Component {
  constructor(props) {
    super(props);
    this.state = {
      json: props.json,
    };
  }

  componentDidMount() {
    this.handleLoad();
  }

  handleLoad = () => {
    const containerAfiliado = document.getElementById('afiliado_container');
    const afiliadoComponents = [];

    for (let i = 0; i < this.state.json.afiliados.length; i++) {
      const afiliado = this.state.json.afiliados[i];

      if (afiliado.rangoEtario) {
        const afiliadoComponent = (
          <Afiliado
            key={i + 1}
            index={i + 1}
            plan={afiliado.rangoEtario.plan.nombre}
            rangoValue={afiliado.rangoEtario.nombre}
          />
        );
        afiliadoComponents.push(afiliadoComponent);
      }
    }

    ReactDOM.createRoot(containerAfiliado).render(afiliadoComponents)
  }
  render() {
    return (
      <div id="afiliado_container" className="card g-3">

      </div>
    );
  }
}
class CargarBonificaciones extends Component {
  constructor(props) {
    super(props);
    this.state = {
      json: props.json,
    };
  }

  componentDidMount() {
    this.handleLoad();
  }

  handleLoad = () => {
    const containerBonificacion = document.getElementById('bonificaciones_container');
    const BonificacionComponents = [];
    var cantidadBonificaciones = this.state.json.bonificacionesGenerales.length;
    /*Bonificaciones Generales*/
    if (cantidadBonificaciones > 0) {

      for (let i = 0; i < this.state.json.bonificacionesGenerales.length; i++) {
        const bonificacion = this.state.json.bonificacionesGenerales[i];
        const BonificacionComponent = (
          <Bonificaciones
            key={i + 1}
            index={i + 1}
            tipoBonificacion="Bonificación general"
            concepto={bonificacion.bonificacionGeneral.nombre}
            descuento={bonificacion.bonificacionGeneral.descuento}
            observacion={bonificacion.observaciones}
          />
        );
        BonificacionComponents.push(BonificacionComponent);
      }

      /*Bonificaciones Generales*/
      /*Bonificaciones por plan*/
      for (let i = 0; i < this.state.json.bonificacionesPorPlan.length; i++) {
        const bonificacion = this.state.json.bonificacionesPorPlan[i];
        const BonificacionComponent = (
          <Bonificaciones
            key={cantidadBonificaciones + 1}
            index={cantidadBonificaciones + 1}
            tipoBonificacion="Bonificación por plan"
            concepto={bonificacion.plan.nombre}
            descuento={bonificacion.descuento}
            observacion=" "
          />
        );
        BonificacionComponents.push(BonificacionComponent);
        cantidadBonificaciones++;
      }
    }
    /*Bonificaciones por plan*/

    ReactDOM.createRoot(containerBonificacion).render(BonificacionComponents)
  }
  render() {
    return (
      <div className="card bonificaciones" id="bonificaciones_container">

      </div>
    );
  }
}
class BotonDetalles extends Component {
  componentDidMount() {
    const BotonDetalles = document.getElementById('detalle');
    BotonDetalles.addEventListener('click', this.handleClick);
  }
  handleClick = () => {
    var estilo = $("#containerDetalle").css('display');
    if (estilo === 'none') {
      $("#containerDetalle").css('display', 'block');
      document.getElementById('detalle').innerText = "- Detalles";
    } else {
      $("#containerDetalle").css('display', 'none');
      document.getElementById('detalle').innerText = "+ Detalles";
    }
  }
  render() {
    return (
      <div className="form-floating col-md-2">
        <button id="detalle" type="button" className="btn btn-met float-end">+ Detalles</button>
      </div>
    );
  }
}
class BotonVolver extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: props.token,
      idUsuario: props.idUsuario,
      nombreUsuario: props.nombreUsuario,
    };
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    const botonVolver = document.getElementById('boton_volver');
    botonVolver.addEventListener('click', this.handleClick);
  }
  handleClick = () => {
    const content = ReactDOM.createRoot(document.getElementById('content'));
    content.render(
      <React.StrictMode>
        <Cotizador token={this.props.token} usuarioId={this.props.idUsuario} usuarioNombre={this.props.nombreUsuario} />
      </React.StrictMode>
    );
  }

  render() {
    return (
      <div className="form-floating col-md-2">
        <button id="boton_volver" type="button" className="btn btn-met float-end">Nueva cotización</button>
      </div>
    );
  }
}
const ImporteConIva = ({ importeFinal, importeFinalIva }) => {
  return (
    <div>
      <div className="row g-3 fila-derecha">
        <div className="form-floating col-md-3">
          <div>
            <label className="form-control labelImporteFinal">Importe sin IVA:</label>
          </div>
        </div>
        <FormInput tamano={3} type="text" value={`$${importeFinalIva}`} />
      </div>
      <div className="row g-3 fila-derecha">
        <div className="form-floating col-md-3">
          <div>
            <label className="form-control labelImporteFinal">Importe final:</label>
          </div>
        </div>
        <FormInput tamano={3} type="text" value={`$${importeFinal}`} id="importe_final" name="importe_final" />
      </div>
    </div>

  )

}
const ImporteSinIva = ({ importeFinal }) => {
  return (
    <div className="row g-3 fila-derecha">
      <div className="form-floating col-md-3">
        <div>
          <label className="form-control labelImporteFinal">Importe final:</label>
        </div>
      </div>
      <FormInput tamano={3} type="text" value={`$${importeFinal}`} id="importe_final" name="importe_final" />
    </div>
  )
}
class InputImporte extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showImporteSinIva: false,
    };
  }

  componentDidMount() {
    const importeFinal = this.props.importeFinal;
    const importeFinalIva = this.props.importeSinIva;

    if (importeFinal !== importeFinalIva && importeFinalIva !== undefined) {
      this.setState({ showImporteSinIva: true }, () => {
      });
    }

  }

  render() {
    if (this.state.showImporteSinIva) {
      return <ImporteConIva importeFinal={this.props.importeFinal} importeFinalIva={this.props.importeSinIva} />;
    } else {
      return <ImporteSinIva importeFinal={this.props.importeFinal} />;

    }
  }
}
class BotonListado extends Component {
  
  componentDidMount() {
    const BotonDetalles = document.getElementById('listado');
    BotonDetalles.addEventListener('click', this.handleClick);
  }
  handleClick = () => {
    const content = ReactDOM.createRoot(document.getElementById('content'));
    content.render(
        <React.StrictMode>
            <Cotizaciones token={this.props.token} usuarioId={this.props.usuarioId} origen={this.props.origen} desde={this.props.desde} hasta={this.props.hasta}/>
        </React.StrictMode>
    );
  }
  render() {
    return (
      <div className="form-floating col-md-2">
        <button id="listado" type="button" className="btn btn-met float-end">Ir al listado</button>
      </div>
    );
  }
}
class BotonGenerarNuevoPresupuesto extends Component {
  
  componentDidMount() {
    const BotonGenerar = document.getElementById('nuevoPresupuesto');
    BotonGenerar.addEventListener('click', this.handleClick);
  }
  handleClick = () => {
    const content = ReactDOM.createRoot(document.getElementById('content'));
    content.render(
        <React.StrictMode>
            <Cotizador2 token={this.props.token} usuarioId={this.props.usuarioId} usuarioNombre={this.props.json.usuario.nombre} json={this.props.json}/>
        </React.StrictMode>
    );
  }
  render() {
    return (
      <div className="form-floating col-md-2">
        <button id="nuevoPresupuesto" type="button" className="btn btn-met float-end">Re Cotizar</button>
      </div>
    );
  }
}
class BotonDescargar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: props.token,
      idCotizacion: props.idCotizacion,
    };
  }

  componentDidMount() {
    const botonDescargar = document.getElementById('botonDescargar');
    botonDescargar.addEventListener('click', this.fetchData); // Utiliza this.handleClick sin bind
  }

  componentWillUnmount() {
    const botonDescargar = document.getElementById('botonDescargar');
    botonDescargar.removeEventListener('click', this.handleClick); // Limpia el evento al desmontar el componente
  }

  fetchData = async () => {
    try {
      const headers = {
        'x-access-token': this.state.token,
      };
      const response = await fetch(url + `cotizaciones/${this.props.idCotizacion}/pdf`, { headers });

      if (response.ok) {
        const blob = await response.blob();

        // Crear un objeto URL a partir del blob
        const fileURL = URL.createObjectURL(blob);

        // Crear un enlace <a> oculto para descargar el archivo
        const downloadLink = document.createElement('a');
        downloadLink.href = fileURL;
        downloadLink.download = 'Cotizacion.pdf';
        downloadLink.style.display = 'none';

        // Agregar el enlace al DOM y simular un clic
        document.body.appendChild(downloadLink);
        downloadLink.click();

        // Limpiar el enlace y el objeto URL después de la descarga
        URL.revokeObjectURL(fileURL);
        document.body.removeChild(downloadLink);
      } else {
        console.error('No se pudo descargar el archivo:', response.status);
      }
    } catch (error) {
      console.error('Error al obtener los datos:', error);
    }
  };

  render() {
    return (
      <div className="form-floating col-md-2">
        <button id="botonDescargar" type="button" className="btn btn-met float-end">
          Descargar PDF
        </button>
      </div>
    );
  }
}
const Presupuesto = ({ json, token,origen,desde,hasta}) => {
  const formatearPrecio = valor => {
    let numero = parseFloat(valor);
    if (!numero) {
      return "0,00";
    }
    else {
      numero = Math.round(numero * 100) / 100
      let resultado = numero.toLocaleString("es-ar");
      if (resultado === "0") {
        return "0,00";
      }
      else {
        let posicionComa = resultado.indexOf(",");
        if (posicionComa === -1) {
          resultado = resultado.concat(",00");
        }
        else if (resultado.length - posicionComa === 2) {
          resultado = resultado.concat("0");
        }
        return resultado;
      }
    }
  }
  var creadoEl = json.creadoEl.substring(0, 10);
  var validoHasta = json.validaHasta.substring(0, 10);
  return (
    <div>
      <div className="presupuesto" style={{ backgroundColor: "#f0f0f0" }}>
        <div className="titulo">
          <center>
            <H texto={"Cotización"} tamano={1} />
          </center>
        </div>

        <div className="row g-3">
          <div className="col-sm-12 mb-3 mb-sm-0 subtitulo">
            <H texto="Presupuestos" tamano={3} />
          </div>
        </div>

        <div className="row g-3">
          <div className="col-sm-12 mb-3 mb-sm-0">
            <div className="card" >
              <div className="row g-3" id="presupuesto_container">
                <FormInput tamano={3} type="text" value={`$${formatearPrecio(json.presupuesto.importeSinDescuentos)}`} id="importe_sin_descuento" placeholder="Importe sin descuento" name="importe_sin_descuento" />
                <FormInput tamano={3} type="text" value={`$${formatearPrecio(json.presupuesto.aportesEstimados)}`} id="aportes_estimados" placeholder="Aportes estimados" name="aportes_estimados" />
                <FormInput tamano={3} type="text" value={`$${formatearPrecio(json.presupuesto.bonificacionesPorPlan)}`} id="bonificaciones_por_plan" placeholder="Bonificaciones por plan" name="bonificaciones_por_plan" />
                <FormInput tamano={3} type="text" value={`$${formatearPrecio(json.presupuesto.bonificacionesAdicionales)}`} id="bonificaciones_adicionales" placeholder="Bonificaciones adicionales" name="bonificaciones_adicionales" />
              </div>
              <InputImporte importeFinal={formatearPrecio(json.presupuesto.importeFinal)} importeFinalIva={formatearPrecio(json.presupuesto.importeSinIva)} />
            </div>
          </div>
        </div>
        <div className="card row g-3 col-sm-12 mb-3 mb-sm-0 fechas">

          <div className="row g-3">
            <div className="row g-2 form-floating col-md-2"></div>
            <BotonDetalles />
            <BotonDescargar token={token} idCotizacion={json.id} />
            <BotonVolver idUsuario={json.usuario.id} nombreUsuario={json.usuario.nombre} token={token} />
            <BotonListado token={token} usuarioId = {json.usuario.id} nombre = {json.usuario.nombre} origen = {origen} desde={desde} hasta={hasta}/>
            <BotonGenerarNuevoPresupuesto token={token} usuarioId={json.usuario.id} json = {json}/>
          </div>

        </div>

        <container id="containerDetalle" style={{ display: "none" }}>
          <div className="row g-3">
            <div className="col-sm-12 mb-3 mb-sm-0">
              <div className="card fechas" >
                <div className="row g-3">
                  <FormInput tamano={2} type="text" value={`${creadoEl}`} id="fecha_actual" placeholder="Fecha" name="fecha_actual" />
                  <div className="form-floating mb-3 col-md-3"></div>
                  <FormInput tamano={2} type="text" value={`${validoHasta}`} id="fecha_hasta" placeholder="Válido hasta" name="fecha_hasta" />
                  <div className="form-floating mb-3 col-md-3"></div>
                  <FormInput tamano={2} type="text" value={`${json.usuario.nombre}`} id="vendedor" placeholder="Vendedor" name="vendedor" />
                </div>
              </div>
            </div>
          </div>

          <div className="row g-3">
            <div className="col-sm-12 mb-3 mb-sm-0 subtitulo">
              <H texto="Datos del cliente" tamano={3}></H>
            </div>
          </div>

          <div className="row g-3">
            <div className="col-sm-12 mb-3 mb-sm-0">
              <div className="card" >
                <div className="row g-3">
                  <FormInput tamano={3} type="text" value={`${json.nombre}`} id="nombre" placeholder="Nombre" name="nombre" />
                  <FormInput tamano={3} type="text" value={`${json.apellido}`} id="apellido" placeholder="Apellido" name="apellido" />
                  <FormInput tamano={3} type="text" value={`${json.documento}`} id="documento" placeholder="Documento" name="documento" />
                  <FormInput tamano={3} type="text" value={`${json.celular}`} id="celular" placeholder="Celular" name="celular" />
                </div>
                <div className="row g-3" id="ubicacionContainer">
                  <FormInput tamano={3} type="text" value={`${json.localidad.provincia.nombre}`} id="provincia" placeholder="Provincia" name="provincia" />
                  <FormInput tamano={3} type="text" value={`${json.localidad.nombre}`} id="localidad" placeholder="Localidad" name="localidad" />
                  <FormInput tamano={3} type="decimal" value={`$${formatearPrecio(json.sueldo)}`} id="sueldo" placeholder="Sueldo" name="sueldo" />
                </div>
              </div>
            </div>
          </div>

          <div className="row g-3">
            <div className="col-sm-12 mb-3 mb-sm-0 subtitulo">
              <H texto="Datos Generales" tamano={3}></H>
            </div>
          </div>

          <div className="row g-3">
            <div className="col-sm-12 mb-3 mb-sm-0">
              <div className="card" >
                <div className="row g-3">
                  <FormInput tamano={3} type="text" value={`${json.categoriaDeVenta.tipoDeVenta.nombre}`} id="tipo_de_venta" placeholder="Tipo de venta" name="tipo_de_venta" />
                  <FormInput tamano={3} type="text" value={`${json.categoriaDeVenta.nombre}`} id="categoria" placeholder="Categoría" name="categoria" />
                  <FormInput tamano={3} type="text" value={`${json.obraSocial.nombre}`} id="obra_social" placeholder="Obra social" name="obra_social" />
                  <FormInput tamano={3} type="text" value={`${json.afiliados.length}`} id="cantidad_afiliado" placeholder="Cantidad de afiliados" name="cantidad_afiliados" />
                </div>
              </div>
            </div>
          </div>

          <div className="row g-3">
            <div className="col-sm-12 mb-3 mb-sm-0 subtitulo">
              <H texto="Planes" tamano={3}></H>
            </div>
          </div>
          <div className="row g-3">
            <div className="col-sm-12 mb-3 mb-sm-0">
              <CargarAfiliados json={json} />
            </div>
          </div>

          <div className="row g-3">
            <div className="col-sm-12 mb-3 mb-sm-0 subtitulo">
              <H texto="Bonificaciones" tamano={3}></H>
            </div>
          </div>

          <div className="row g-3">
            <div className="col-sm-12 mb-3 mb-sm-0">
              <CargarBonificaciones json={json} />
            </div>
          </div>

        </container>
      </div>
    </div>
  )
}
export default Presupuesto;