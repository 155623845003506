import React, { Component } from "react";
import Box from '@mui/material/Box';
import $ from 'jquery';

//Grilla
import { AgGridReact } from 'ag-grid-react';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { localeEs } from "../../utils/locale.es";

import { url } from "../../utils/Globals";
import { FormInputEditable, H, FormInput, BotonCancelar, SwitchActivo, FormInputEditablePorcentaje } from "../../utils/Controles";
import { FormControl, FormControlLabel, FormGroup } from "@mui/material";

//alerts
import Swal from 'sweetalert2';

class Reportes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: this.props.token,
      bonificacionGeneral: null,
      tituloExistente: null,
      creadoEl: null,
      descuento: null,
      idRegistro: null,
      habilitado: null,
      data: [],
      headers: {
        'x-access-token': `${this.props.token}`,
      },
    }
  }
  componentDidMount(){
    this.ajustarIFrame("iframe_bi");
  }
  ajustarIFrame = (id) => {
    
    var frame = document.getElementById(id);
    
    if(frame){
        frame.style.height = `${window.innerWidth * 0.55}px`
        
    }
  }
  render() {
    
    return (
      <div style={{ height: '100%', width: '100%' }}>
        <div id="listado">
          <div>
            <section>
              <div style={{ height: '50%', width: '100%', padding: "10px 0px 0px 0px" }}>
                <Box sx={{ width: '100%' }} style={{ backgroundColor: "white" }}>
                  <br></br>
                  <div className="row g-3">
                    <iframe title="Budget" id='iframe_bi' width="1024" src="https://app.powerbi.com/view?r=eyJrIjoiYzY4YjU3NmItY2Y1OS00NjA0LWE5YWMtNDJiZmE3MjhjZGQzIiwidCI6ImM3YTZlNzdhLTIyOGMtNDNjNC1hZjBlLTVhNmRiNWYzYTEyNSIsImMiOjR9&embedImagePlaceholder=true" frameborder="0" allowFullScreen="true"></iframe>
                  </div>
                  
                </Box>
              </div>
            </section>
          </div>
        </div>
        
      </div>
    )
  }
}

export { Reportes }