import React, { Component } from "react";
import Box from '@mui/material/Box';
import ReactDOM from 'react-dom/client';

import { url } from "../../utils/Globals";
import { H } from "../../utils/Controles";
import Presupuesto from "../Presupuesto";
import $ from 'jquery';

//Grilla
//npm install ag-grid-react
import { AgGridReact } from 'ag-grid-react';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { localeEs } from "../../utils/locale.es";

//DatePicker
//npm install react-datepicker --save
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from 'date-fns/locale/es';
import { format } from 'date-fns';
import { Button, CircularProgress } from "@mui/material";
import { green } from "@mui/material/colors";
registerLocale('es', es)

const Cotizaciones = ({ token, usuarioId, origen, desde, hasta }) => {

  class ObtenerCotizaciones extends Component {
    constructor(props) {
      super(props);

      function formatearFecha(params) {
        let fecha = params.data.creadoEl.substring(0, 10);
        let partesFecha = fecha.split('-');
        return `${partesFecha[2]}/${partesFecha[1]}/${partesFecha[0]}`;
      }
      let primerDia = new Date();
      primerDia.setDate(1);

      this.state = {
        data: [],
        auxData: [],
        fetchingData: false,
        fechaDesde: desde ? desde : primerDia,
        fechaHasta: hasta ? hasta : new Date(),
        columnas: [
          {
            headerName: "Fecha",
            field: "creadoEl",
            sortable: true,
            filter: "agDateColumnFilter",
            flex: 1,
            valueFormatter: formatearFecha,
            minWidth: 120
          },
          { headerName: "Nombre", field: "nombre", sortable: true, filter: true, flex: 1, minWidth: 120 },
          { headerName: "Apellido", field: "apellido", sortable: true, filter: true, flex: 1, minWidth: 120 },
          { headerName: "Documento", field: "documento", sortable: true, filter: true, flex: 1, minWidth: 120 },
          { headerName: "Celular", field: "celular", sortable: true, filter: true, flex: 1, minWidth: 120 },
        ],
        descargandoExcel: false,
        excelListo: false,
        cargandoGrilla: false,
        grillaLista: false,
        usuarioId: this.props.usuarioId
      };
    }

    componentDidMount() {
      
      this.fetchData();
    }

    async ver(id) {
      try {
        const headers = {
          'x-access-token': `${this.props.token}`,
        };
        const response = await fetch(url + "cotizaciones/" + id + "?completo=1", { headers });
        let responseData = await response.json();
        if (responseData) {
          const content = ReactDOM.createRoot(document.getElementById('content'));
          content.render(
            <Presupuesto json={responseData} token={token} origen={origen} desde={this.state.fechaDesde} hasta={this.state.fechaHasta} />
          );
        }
      } catch (error) {
        console.error('Error al obtener los datos:', error);
      }
    }

    actualizarGrilla() {
      this.fetchData();
    }

    async fetchData() {
      console.log(this.props.usuarioId)
      let datos = [];
      $(".ag-overlay-no-rows-wrapper").css("display","none");
      try {
        this.setState({ grillaLista: false, cargandoGrilla: true });
        const headers = {
          'x-access-token': `${this.props.token}`,
        };

        const desde = format(this.state.fechaDesde, "yyyy-MM-dd") + " 00:00:00";
        const hasta = format(this.state.fechaHasta, "yyyy-MM-dd") + " 23:59:59";
        let urlOrigen = origen === "usuario" ? `${url}cotizaciones?usuario=${this.props.usuarioId}&desde=${desde}&hasta=${hasta}` : `${url}cotizaciones?desde=${desde}&hasta=${hasta}`;
        console.log(urlOrigen);
        const response = await fetch(
          `${urlOrigen}`,
          { headers }
        );
        const responseData = await response.json();
        //const cantidadDePaginas = responseData.totalDePaginas;

        if (Array.isArray(responseData.datos)) {
          datos = responseData.datos;
        }
        /*
                for (let i = 2; i <= cantidadDePaginas; i++) {
                  try {
                    let urlOrigen = origen == "usuario" ? `${url}cotizaciones?usuario=${this.props.usuarioId}&desde=${desde}&hasta=${hasta}&pagina=${i}`:`${url}cotizaciones?desde=${desde}&hasta=${hasta}&pagina=${i}`;
                    var responsePagina = await fetch(
                      `${url}cotizaciones?usuario=${this.props.usuarioId}&desde=${desde}&hasta=${hasta}&pagina=${i}`,
                      { headers }
                    );
                    var responseDataPagina = await responsePagina.json();
                    
                    if (Array.isArray(responseDataPagina.datos)) {
                      datos = datos.concat(responseDataPagina.datos);
                    } else {
                      console.error('responseDataPagina no es un array válido:', responseDataPagina);
                    }
                
                  }
                  catch (error) {
                    console.error('Error al obtener los datos:', error);
                  }
                }
        */
      } catch (error) {
        console.error('Error al obtener los datos:', error);
      }
      if(datos.length === 0){
        $(".ag-overlay-no-rows-wrapper").css("display","block !important");
      }
      this.setState({ data: datos.reverse() });
      this.setState({ grillaLista: true, cargandoGrilla: false });
    }
    async exportar() {
      try {
        this.setState({ excelListo: false, descargandoExcel: true });

        const desde = format(this.state.fechaDesde, "yyyy-MM-dd") + " 00:00:00";
        const hasta = format(this.state.fechaHasta, "yyyy-MM-dd") + " 23:59:59";

        const headers = {
          'x-access-token': this.props.token,
        };
        let urlOrigen = origen === "usuario" ? `${url}cotizaciones/exportar?usuario=${this.props.usuarioId}&desde=${desde}&hasta=${hasta}` : `${url}cotizaciones/exportar?desde=${desde}&hasta=${hasta}`;
        const response = await fetch(
          `${urlOrigen}`,
          { headers }
        );

        if (response.ok) {
          const blob = await response.blob();

          // Crear un objeto URL a partir del blob
          const fileURL = URL.createObjectURL(blob);

          // Crear un enlace <a> oculto para descargar el archivo
          const downloadLink = document.createElement('a');
          downloadLink.href = fileURL;
          downloadLink.download = 'Cotizacion.xlsx';
          downloadLink.style.display = 'none';

          // Agregar el enlace al DOM y simular un clic
          document.body.appendChild(downloadLink);
          downloadLink.click();
          this.setState({ excelListo: true, descargandoExcel: false });
          // Limpiar el enlace y el objeto URL después de la descarga
          URL.revokeObjectURL(fileURL);
          document.body.removeChild(downloadLink);
        } else {
          console.error('No se pudo descargar el archivo:', response.status);
        }
      } catch (error) {
        console.error('Error al obtener los datos:', error);
      }
    }
    render() {
      const { data } = this.state;
      const datos = data.map((item) => {
        let fecha = item.creadoEl.substring(0, 10);
        return {
          id: item.id,
          nombre: item.nombre,
          apellido: item.apellido,
          documento: item.documento,
          celular: item.celular,
          creadoEl: fecha,
          hasta: item.hasta,
        }
      }
      );
      /*
      --ag-header-height: calc(var(--ag-font-size) + var(--ag-grid-size) * 4.25);
      --ag-row-height: calc(var(--ag-font-size) + var(--ag-grid-size) * 3.5);
      */
      const agregarBotones = props => {
        return (
          <div className="contenedorBoton">
            <button className="botonVer" onClick={() => this.ver(props.data.id)}>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-eye" viewBox="0 0 16 16">
                <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
              </svg>
            </button>

          </div>
        )
      }
      const columnasConBotones = [
        ...this.state.columnas,
        {
          headerName: "",
          field: "",
          sortable: false,
          filter: false,
          width: "100px",
          cellRenderer: agregarBotones,
          cellRendererParams: {
            id: 1
          }
        }
      ]
      const setFechaDesde = (fecha) => {
        this.setState({ fechaDesde: fecha }, () => {
          this.actualizarGrilla();
        });
      }
      const setFechaHasta = (fecha) => {
        this.setState({ fechaHasta: fecha }, () => {
          this.actualizarGrilla();
        });
      }
      const buttonSx = {
        ...(this.state.excelListo && {
          bgcolor: green[500],
          '&:hover': {
            bgcolor: green[700],
          },
        }),
      };
      return (
        <div>
          <section>
            <div style={{ height: '50%', width: '100%', padding: "10px 0px 0px 0px" }}>
              <Box sx={{ width: '100%' }} style={{ backgroundColor: "white", display: "flex", flexDirection: "column" }}>
                <H texto={origen == "usuario" ? "Mis cotizaciones" : "Cotizaciones"} tamano={3}></H>
                <div className="row g-3">
                  <div className="col-md-2">
                    <div className="date-picker">
                      <label htmlFor="fechaDesde" className="date-picker-label">Desde</label>
                      <DatePicker
                        id="fechaDesde"
                        dateFormat="dd/MM/yyyy"
                        selected={this.state.fechaDesde}
                        onChange={(fecha) => setFechaDesde(fecha)}
                        locale="es"
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="date-picker">
                      <label htmlFor="fechaHasta" className="date-picker-label">Hasta</label>
                      <DatePicker
                        id="fechaHasta"
                        dateFormat="dd/MM/yyyy"
                        selected={this.state.fechaHasta}
                        onChange={(fecha) => setFechaHasta(fecha)}
                        locale="es"
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="btn-group btn-group-lg" role="group" aria-label="First group">
                      <Button
                        variant="contained"
                        className="btn btn-secondary"
                        id="botonExportar"
                        sx={buttonSx}
                        disabled={this.state.descargandoExcel}
                        onClick={() => this.exportar()}
                      >
                        <center>
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-download" viewBox="0 0 16 16">
                            <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5" />
                            <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708z" />
                          </svg>
                        </center>
                      </Button>
                      {this.state.descargandoExcel && (
                        <CircularProgress
                          size={24}
                          sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            marginTop: '-12px',
                            marginLeft: '-12px',
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>

                <div
                  className="ag-theme-quartz"
                  style={{
                    width: "100%",
                    "--ag-font-size": "16px"
                  }}
                >
                    <AgGridReact
                      columnDefs={columnasConBotones}
                      rowData={datos}
                      pagination={true}
                      paginationPageSize={10}
                      paginationPageSizeSelector={[10, 20, 50, 100]}
                      domLayout='autoHeight'
                      localeText={localeEs}
                      reactiveCustomComponents={true}
                    />
                    {this.state.cargandoGrilla && (
                      <CircularProgress
                        size={24}
                        sx={{
                          position: 'absolute',
                          top: '50%',
                          left: '50%',
                          marginTop: '-12px',
                          marginLeft: '-12px',
                        }}
                      />
                    )}
                </div>
              </Box>
            </div>
          </section>
        </div>
      );
    }
  }

  class FormCotizaciones extends Component {
    componentDidMount() {
      this.handleLoad();
    }
    handleLoad() {
    }
    render() {
      return (
        <div style={{ height: '100%', width: '100%' }}>
          <div id="listado">
            <ObtenerCotizaciones token={token} usuarioId={usuarioId} />
          </div>
        </div>
      )
    }
  }
  return (
    <FormCotizaciones />
  )
}
export { Cotizaciones }