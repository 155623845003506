import React, { Component } from "react";
import ReactDOM from 'react-dom/client';
import { H, FormInputEditable } from "../utils/Controles";
import $ from 'jquery';
import SelectReact from 'react-select';
import Presupuesto from "./Presupuesto";
import { url, estilosSelect } from "../utils/Globals";
import Swal from 'sweetalert2';
class SelectAfiliado2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      headers: {
        'x-access-token': `${this.props.token}`,
      },
      index: this.props.index,
      idControl: this.props.idControl,
      dataPlanes: this.props.dataPlanes && this.props.dataPlanes.length > 0 ? this.props.dataPlanes : [],
      plan: this.props.plan,
      listaDePrecios: this.props.listaDePrecios !== 0 ? this.props.listaDePrecios : 0,
      dataRangos: []
    }
    this.fetchDataPlanes();
  }

  handlePlanChange = () => {
    this.fetchRangos();
  }
  async fetchRangos() {
    try {
      const response = await fetch(url + "rangos-etarios?" + new URLSearchParams({
        conPrecio: "1",
        listaDePrecios: this.state.listaDePrecios,
        plan: this.state.plan
      }), {
        headers: this.state.headers
      });
      const responseData = await response.json();
      this.setState({ dataRangos: responseData }, () => {
        const containerId = `divRangoEtario${this.props.idControl}`;
        const container = document.getElementById(containerId);
        if (container) {
          var contenedor = ReactDOM.createRoot(container);
          contenedor.render(
            <React.StrictMode>
              <SelectRango
                idControl={this.props.idControl}
                dataRangos={this.state.dataRangos}
                rangoEtario={this.props.rangoEtario}
              />
            </React.StrictMode>
          );
        } else {
          console.error(`Container with id ${containerId} not found.`);
        }
      });
    } catch (error) {
      console.error('Error al obtener los datos:', error);
    }
  }
  async fetchDataPlanes() {
    try {
      const response = await fetch(url + "planes?" + new URLSearchParams({
        conPrecio: "1",
        listaDePrecios: this.state.listaDePrecios
      }), {
        headers: this.state.headers
      });

      const responseData = await response.json();
      this.setState({ dataPlanes: responseData }, () => {
        this.fetchRangos();
      });
    } catch (error) {
      console.error('Error al obtener los datos:', error);
    }

  }

  render() {
    return (
      <div className="row g-3 filaAfiliado" >
        <div className="form-floating col-md-1">
          <div className="form-floating col-md divLabelAfiliado">
            <label
              className="labelAfiliado"
              id={`labelAfiliado${this.props.index}`}
              style={{
                textAlign: "center",
                padding: "8px 12px 30px 12px"
              }}
            >
              {this.props.index}
            </label>
          </div>
        </div>
        <div className="form-floating select-met col-md-3">
          <SelectReact
            name={this.props.idControl}
            inputId={this.props.idControl}
            isClearable={false}
            isSearchable={false}
            isDisabled={this.state.dataPlanes.length > 0 ? false : true}
            options={this.state.dataPlanes}
            getOptionLabel={(opcion) => opcion.nombre}
            getOptionValue={(opcion) => opcion.id}
            placeholder=""
            menuPortalTarget={document.body}
            menuPosition={'fixed'}
            menuPlacement="auto"
            styles={estilosSelect}
            value={this.state.dataPlanes.find(option => option.id === this.state.plan)}
            onChange={(opcion) => {
              this.setState({ plan: opcion.id }, () => {
                this.handlePlanChange();
              })
            }}
          />
          <label htmlFor={this.props.idControl}>Plan</label>
        </div>
        <div id={`divRangoEtario${this.props.idControl}`} className="form-floating select-met col-md-3">
          <div className="form-floating select-met">
            <SelectRango
              idControl={this.props.idControl}
              dataRangos={this.state.dataRangos}
              rangoEtario={this.props.rangoEtario}
            />
          </div>
        </div>
      </div>
    )
  }
}
class SelectRango extends Component {
  constructor(props) {
    super(props);
    this.state = {
      headers: {
        'x-access-token': `${this.props.token}`,
      },
      dataRangos: this.props.dataRangos && this.props.dataRangos.length > 0 ? this.props.dataRangos : [],
      rangoEtario: this.props.rangoEtario ? this.props.rangoEtario : 0,
    }
  }

  render() {
    return (
      <div className="form-floating select-met" >
        <SelectReact
          name={`rangoEtario${this.props.idControl}`}
          inputId={`rangoEtario ${this.props.idControl}`}
          isClearable={false}
          isSearchable={false}
          isDisabled={this.state.dataRangos.length > 0 ? false : true}
          options={this.state.dataRangos}
          getOptionLabel={(option) => option.nombre}
          getOptionValue={(option) => option.id}
          placeholder=""
          menuPortalTarget={document.body}
          menuPosition={'fixed'}
          menuPlacement="auto"
          styles={estilosSelect}
          value={this.state.dataRangos.find(option => option.id === this.state.rangoEtario)}
          onChange={(opcion) => {
            this.setState({ rangoEtario: opcion.id })
          }}
        />
        <label htmlFor={this.props.idControl}>Rango etario</label>
      </div>
    );
  }

}
class Cotizador2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: this.props.token,
      usuarioId: this.props.usuarioId,
      usuarioNombre: this.props.usuarioNombre,
      headers: {
        'x-access-token': `${this.props.token}`,
      },
      nombre: this.props.json.nombre !== "" ? this.props.json.nombre : "",
      apellido: this.props.json.apellido !== "" ? this.props.json.apellido : "",
      documento: this.props.json.documento !== "" ? this.props.json.documento : "",
      sueldo: this.props.json.sueldo !== "0.00" ? this.props.json.sueldo : "",
      celular: this.props.json.celular !== "" ? this.props.json.celular : "",
      //Provincias
      dataProvincias: [],
      provincia: this.props.json?.localidad?.provincia?.id || 6,
      //Localidades
      dataLocalidad: [],
      localidad: this.props.json?.localidad?.id || 1105,
      //ListaDePrecios
      dataListaDePrecios: [],
      listaDePrecios: this.props.json?.listaDePrecios?.id || 0,
      //TiposDeVenta
      dataTiposDeVenta: [],
      tipoDeVenta: this.props.json?.categoriaDeVenta?.tipoDeVenta?.id || 0,
      //Categorias
      dataCategoria: [],
      categoria: 0,
      //ObrasSociales
      dataObrasSociales: [],
      obraSocial: this.props.json?.obraSocial?.id || 0,
      keyObraSocial: Date.now(),
      //CantidadAfiliados
      opcionesAfiliados: [
        { value: 1, label: '1' },
        { value: 2, label: '2' },
        { value: 3, label: '3' },
        { value: 4, label: '4' },
        { value: 5, label: '5' },
        { value: 6, label: '6' },
        { value: 7, label: '7' },
        { value: 8, label: '8' },
        { value: 9, label: '9' },
        { value: 10, label: '10' },
      ],
      //Afiliados
      cantidadAfiliados: this.props.json?.afiliados?.length || 1,
      //Planes
      dataPlanes: [],
      //rangos

      //Bonificaciones
      dataBonificaciones: [],
      bonificaciones: [],
      bonificacionID: 0,
      bonificacionNombre: "",
      keyBonificacion: Date.now(),
      observaciones: "",
      //chequear si cargo

    }
    console.log(this.props.json)
    this.agregarBeneficioJson();
  }
  conJson = () => {
    if (this.state.tipoDeVenta !== "") {
      this.fetchDataCategorias();
    }
  }
  agregarBeneficioJson() {
    let bonificacionesGenerales = this.props.json?.bonificacionesGenerales || [];
    if (bonificacionesGenerales.length > 0) {
      bonificacionesGenerales.forEach(async bonificacion => {
        const bonificacionGeneral = bonificacion.bonificacionGeneral;
        const bonificacionId = bonificacionGeneral.id;
        let bonificacionEncontrada = await this.state.bonificaciones.includes(bonificacionId);

        const bonificacionNombre = bonificacionGeneral.nombre;
        const observaciones = bonificacion.observaciones;

        if (!bonificacionEncontrada && bonificacionId !== 0) {
          this.setState(prevState => ({
            bonificaciones: [...prevState.bonificaciones, { bonificacionGeneral: bonificacionId, observaciones: observaciones }]
          }), () => {
            let boton = '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">  <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6Z"/>  <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z"/></svg>';
            var nvaFila = '<tr id="' + bonificacionNombre + '">   <td id = ' + bonificacionId + '>' + bonificacionNombre + '</td>  <td>' + observaciones + '</td><td><button type="button" class="eliminar">' + boton + '</button></td></tr>'
            $("#tablaBeneficiarios tbody").append(nvaFila);
            $("#tablaBeneficiarios").on("click", ".eliminar", (event) => {
              const fila = $(event.currentTarget).closest("tr");
              const id = fila.find("td:first").attr("id");
              this.setState(prevState => ({
                bonificaciones: prevState.bonificaciones.filter(bonificacionID => bonificacionID !== parseInt(id))
              }), () => {
                fila.remove();
              });
            });
          });
        }
      });
    }

  }
  async componentDidMount() {
    //alert(this.state.isMounted)

    this.setState({ bonificaciones: [] })
    this.fetchDataProvincias();
    this.fetchDataListaDePrecios();
    this.fetchDataTiposDeVenta();
    this.fetchDataBonificaciones();
    this.conJson();


    if (this.state.listaDePrecios) {
      this.fetchDataPlanes();
    }
    this.mostrarAfiliados();

    // Select the node that will be observed for mutations
    const targetNode = document.getElementById("divAfiliados");

    // Options for the observer (which mutations to observe)
    const config = { attributes: false, childList: true, subtree: false };

    // Callback function to execute when mutations are observed
    const callback = (mutationList, observer) => {
      for (const mutation of mutationList) {
        if (mutation.type === "childList") {
          this.mostrarAfiliados();
        }
      }
    };

    // Create an observer instance linked to the callback function
    const observer = new MutationObserver(callback);

    // Start observing the target node for configured mutations
    observer.observe(targetNode, config);
  }
  componentDidUpdate() {
    this.mostrarAfiliados();
  }
  /*

  componentDidUpdate() {
    var contenedor = ReactDOM.createRoot(document.getElementById("divTablaBeneficiarios"));
    contenedor.render(
      <React.StrictMode>
        <table class="table table-striped" id="tablaBeneficiarios">
          <thead>
            <tr>
              <th scope="col">Bonificación</th>
              <th scope="col">Observaciones</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
          </tbody>
        </table>
      </React.StrictMode>
    );
  }*/
  /* Provincias */
  async fetchDataProvincias() {
    try {
      const response = await fetch(url + "provincias", {
        headers: this.state.headers
      });
      const responseData = await response.json();
      this.setState({ dataProvincias: responseData }, () => {
        this.fetchDataLocalidades();
      });
    } catch (error) {
      console.error('Error al obtener los datos:', error);
    }
  }
  /* Provincias */
  /* Localidades */
  async fetchDataLocalidades() {
    try {
      const response = await fetch(url + "localidades?" + new URLSearchParams({
        provincia: this.state.provincia
      }), {
        headers: this.state.headers
      });
      const responseData = await response.json();
      this.setState({ dataLocalidad: responseData });
    } catch (error) {
      console.error('Error al obtener los datos:', error);
    }
  }
  /* Localidades */
  /* Lista de Precios */
  async fetchDataListaDePrecios() {
    try {
      const response = await fetch(url + "listas-de-precios?conPrecio=1", {
        headers: this.state.headers
      });
      const responseData = await response.json();
      this.setState({ dataListaDePrecios: responseData }, () => {
      });
    } catch (error) {
      console.error('Error al obtener los datos:', error);
    }
  }

  /* Lista de Precios */
  /* Tipos de Venta */
  async fetchDataTiposDeVenta() {
    try {
      const response = await fetch(url + "tipos-de-venta?" + new URLSearchParams({
        conDescuento: "1"
      }), {
        headers: this.state.headers
      });

      const responseData = await response.json();
      this.setState({ dataTiposDeVenta: responseData }, () => {
      });
    } catch (error) {
      console.error('Error al obtener los datos:', error);
    }
  }
  /* Tipos de Venta */
  /* Categorías */
  async fetchDataCategorias() {
    try {
      this.setState({ keyObraSocial: Date.now(), dataObrasSociales: [] })
      const response = await fetch(url + "categorias-de-venta?" + new URLSearchParams({
        conDescuento: "1",
        tipoDeVenta: this.state.tipoDeVenta
      }), {
        headers: this.state.headers
      });

      const responseData = await response.json();
      this.setState({ dataCategoria: responseData }, () => {
        if (this.state.dataCategoria.length === 1) {
          this.setState({ categoria: this.state.dataCategoria[0].id }, () => {
            this.fetchDataObrasSociales();
          });
        }
      });
    } catch (error) {
      console.error('Error al obtener los datos:', error);
    }
  }
  /* Categorías */
  /* Obras Sociales */
  async fetchDataObrasSociales() {
    try {
      const response = await fetch(url + "obras-sociales?" + new URLSearchParams({
        conDescuento: "1",
        categoriaDeVenta: this.state.categoria
      }), {
        headers: this.state.headers
      });

      const responseData = await response.json();
      this.setState({ dataObrasSociales: responseData }, () => {
        if (this.state.dataObrasSociales.length === 1) {
          this.setState({ obraSocial: this.state.dataObrasSociales[0].id });
        }
      });
    } catch (error) {
      console.error('Error al obtener los datos:', error);
    }
  }
  /* Obras Sociales */
  /* Afiliados */
  mostrarAfiliados() {
    let afiliadoContainer = $('#divAfiliados');
    afiliadoContainer.find('.filaAfiliado').each(function (index, element) {
      $(element).show();
    });
    afiliadoContainer = $('#divAfiliados');
    afiliadoContainer.find('.filaAfiliado').each(function (index, element) {
      if (index >= this.state.cantidadAfiliados) {
        $(element).hide();
      }
    }.bind(this));
  }

  cargarAfiliados = (planes, listaDePrecios) => {
    Swal.fire({
      title: 'Cargando',
      html: 'Por favor espere',
      allowOutsideClick: false,
      showConfirmButton: false,
      willOpen: () => {
        Swal.showLoading();
      },
      customClass: {
        loader: 'custom-loader',
        popup: 'custom-popup'
      }
    });
    var contenedor = ReactDOM.createRoot(document.getElementById("divAfiliados"));
    contenedor.render(
      <React.StrictMode>
        <React.StrictMode>
              <SelectAfiliado2 token={this.state.token} index={1} idControl={1} dataPlanes={planes} listaDePrecios={listaDePrecios} plan={this.props.json?.afiliados?.[0]?.rangoEtario?.plan?.id || 0} rangoEtario={this.props.json?.afiliados?.[0]?.rangoEtario?.id || 0} />
              <SelectAfiliado2 token={this.state.token} index={2} idControl={2} dataPlanes={planes} listaDePrecios={listaDePrecios} plan={this.props.json?.afiliados?.[1]?.rangoEtario?.plan?.id || 0} rangoEtario={this.props.json?.afiliados?.[0]?.rangoEtario?.id || 0} />
              <SelectAfiliado2 token={this.state.token} index={3} idControl={3} dataPlanes={planes} listaDePrecios={listaDePrecios} plan={this.props.json?.afiliados?.[2]?.rangoEtario?.plan?.id || 0} rangoEtario={this.props.json?.afiliados?.[0]?.rangoEtario?.id || 0} />
              <SelectAfiliado2 token={this.state.token} index={4} idControl={4} dataPlanes={planes} listaDePrecios={listaDePrecios} plan={this.props.json?.afiliados?.[3]?.rangoEtario?.plan?.id || 0} rangoEtario={this.props.json?.afiliados?.[0]?.rangoEtario?.id || 0} />
              <SelectAfiliado2 token={this.state.token} index={5} idControl={5} dataPlanes={planes} listaDePrecios={listaDePrecios} plan={this.props.json?.afiliados?.[4]?.rangoEtario?.plan?.id || 0} rangoEtario={this.props.json?.afiliados?.[0]?.rangoEtario?.id || 0} />
              <SelectAfiliado2 token={this.state.token} index={6} idControl={6} dataPlanes={planes} listaDePrecios={listaDePrecios} plan={this.props.json?.afiliados?.[5]?.rangoEtario?.plan?.id || 0} rangoEtario={this.props.json?.afiliados?.[0]?.rangoEtario?.id || 0} />
              <SelectAfiliado2 token={this.state.token} index={7} idControl={7} dataPlanes={planes} listaDePrecios={listaDePrecios} plan={this.props.json?.afiliados?.[6]?.rangoEtario?.plan?.id || 0} rangoEtario={this.props.json?.afiliados?.[0]?.rangoEtario?.id || 0} />
              <SelectAfiliado2 token={this.state.token} index={8} idControl={8} dataPlanes={planes} listaDePrecios={listaDePrecios} plan={this.props.json?.afiliados?.[7]?.rangoEtario?.plan?.id || 0} rangoEtario={this.props.json?.afiliados?.[0]?.rangoEtario?.id || 0} />
              <SelectAfiliado2 token={this.state.token} index={9} idControl={9} dataPlanes={planes} listaDePrecios={listaDePrecios} plan={this.props.json?.afiliados?.[8]?.rangoEtario?.plan?.id || 0} rangoEtario={this.props.json?.afiliados?.[0]?.rangoEtario?.id || 0} />
              <SelectAfiliado2 token={this.state.token} index={10} idControl={10} dataPlanes={planes} listaDePrecios={listaDePrecios} plan={this.props.json?.afiliados?.[9]?.rangoEtario?.plan?.id || 0} rangoEtario={this.props.json?.afiliados?.[0]?.rangoEtario?.id || 0} />
        </React.StrictMode>
      </React.StrictMode>
    )
    
    // Cerrar el Swal después de renderizar
    Swal.close();
  }
  /* Afiliados */
  /* Planes */
  async fetchDataPlanes() {
    try {
      const response = await fetch(url + "planes?" + new URLSearchParams({
        conPrecio: "1",
        listaDePrecios: this.state.listaDePrecios
      }), {
        headers: this.state.headers
      });

      const responseData = await response.json();
      this.setState({ dataPlanes: responseData }, () => {
        this.cargarAfiliados(responseData, this.state.listaDePrecios, this.state.cantidadAfiliados)
        //console.log(this.state.dataPlanes)
      });
    } catch (error) {
      console.error('Error al obtener los datos:', error);
    }

  }
  /* Planes */
  /* Bonificaciones */
  async fetchDataBonificaciones() {
    try {
      const response = await fetch(url + "bonificaciones-generales?habilitado=1", {
        headers: this.state.headers
      });
      const responseData = await response.json();
      this.setState({ dataBonificaciones: responseData }, () => {
      });
    } catch (error) {
      console.error('Error al obtener los datos:', error);
    }
  }
  agregarBeneficio() {
    let bonificacionEncontrada = this.state.bonificaciones.find(bonificacion => bonificacion.bonificacionGeneral === this.state.bonificacionID);
    if (!bonificacionEncontrada && this.state.bonificacionID !== 0) {
      this.setState(prevState => ({
        bonificaciones: [...prevState.bonificaciones, { bonificacionGeneral: prevState.bonificacionID, observaciones: this.state.observaciones }]
      }), () => {
        let boton = '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">  <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6Z"/>  <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z"/></svg>';
        var nvaFila = '<tr id="' + this.state.bonificacionNombre + '">   <td id = ' + this.state.bonificacionID + '>' + this.state.bonificacionNombre + '</td>  <td>' + this.state.observaciones + '</td><td><button type="button" class="eliminar">' + boton + '</button></td></tr>'
        $("#tablaBeneficiarios tbody").append(nvaFila);
        $("#tablaBeneficiarios").on("click", ".eliminar", (event) => {
          const fila = $(event.currentTarget).closest("tr");
          const id = fila.find("td:first").attr("id");
          this.setState(prevState => ({
            bonificaciones: prevState.bonificaciones.filter(bonificacionID => bonificacionID !== parseInt(id))
          }), () => {
            fila.remove();
          });
        });
        this.setState({ observaciones: "", bonificacionID: 0, bonificacionNombre: "", keyBonificacion: Date.now() })

      });
    }
    else {
      if (bonificacionEncontrada) {
        Swal.fire({
          icon: "error",
          text: "No puede cargar 2 veces la misma bonificación.",
          width: 500,
          height: 200,
        });
      } else if (this.state.bonificacionID === 0) {
        Swal.fire({
          icon: "error",
          text: "La bonificación no puede quedar vacia.",
          width: 500,
          height: 200,
        });
      }
    }
  }
  /* Bonificaciones */
  /* Cambios */
  handleNombreChange = (newValue) => {
    this.setState({ nombre: newValue });
  }
  handleApellidoChange = (newValue) => {
    this.setState({ apellido: newValue });
  }
  handleDocumentoChange = (newValue) => {
    this.setState({ documento: newValue });
  }
  handleSueldoChange = (newValue) => {
    var sueldo = String(newValue).replace(/[^0-9]/g, '');
    this.setState({ sueldo: sueldo });
  }
  handleCelularChange = (newValue) => {
    var celular = String(newValue).replace(/[^0-9]/g, '');
    this.setState({ celular: celular });
  }
  handleObservacionChange = (newValue) => {
    this.setState({ observaciones: newValue });
  }
  /* Cambios */
  scrollToElement(element) {
    $('html, body').animate({
      scrollTop: $(element).offset().top
    }, 500);
  }
  guardarPresupuesto = () => {

    let sueldo = this.state.sueldo;
    if (this.state.tipoDeVenta !== 2) {
      if (this.state.sueldo === undefined || this.state.sueldo === "") {
        sueldo = "0";
      }
    }
    /*-----------Afiliados---------------*/
    let arrayAfiliados = [];
    for (let indice = 1; indice <= this.state.cantidadAfiliados; indice++) {
      var rangoEtario = $(`[name='rangoEtario${indice}']`).val()
      arrayAfiliados.push({ rangoEtario: rangoEtario });
    }
    /*-----------Afiliados---------------*/

    let cotizacion = {
      "usuario": this.state.usuarioId,
      "localidad": this.state.localidad,
      "categoriaDeVenta": this.state.categoria,
      "obraSocial": this.state.obraSocial,
      "listaDePrecios": this.state.listaDePrecios,
      "nombre": this.state.nombre,
      "apellido": this.state.apellido,
      "documento": this.state.documento,
      "celular": this.state.celular,
      "sueldo": sueldo,
      "afiliados": arrayAfiliados,
      "bonificacionesGenerales": this.state.bonificaciones
    };
    console.log(cotizacion)
    let respuesta = true;
    var errorStyles = {
      'border-color': '#f56565',
      'color': '#2a2222',
      'border-width': '3px',
    };
    var campos = [
      { nombre: this.state.nombre, selector: "#nombre", tipo: "id" },
      { nombre: this.state.apellido, selector: "#apellido", tipo: "id" },
      { nombre: this.state.documento, selector: "#documento", tipo: "id" },
      { nombre: this.state.celular, selector: "#celular", tipo: "id" },
    ];
    if (this.state.tipoDeVenta !== 2) {
      if (this.state.sueldo === undefined || this.state.sueldo === "") {
        campos.push({ nombre: this.state.sueldo, selector: "#sueldo", tipo: "id" })
      }
    }
    campos.forEach(function (campo) {
      if (campo.nombre === "" || campo.nombre === undefined) {
        try {
          $(campo.selector).css(errorStyles);
        } catch {
          console.log(campo.selector);
        }
        if (campo.tipo === "id") {
          document.getElementById(campo.selector.substring(1)).focus();
          document.getElementById(campo.selector.substring(1)).select();
        }
        try {
          this.scrollToElement(campo.selector);
        } catch {
          console.log(campo.selector);
        }
        console.log(campo.selector)
        respuesta = false;
      } else {
        $(campo.selector).removeAttr('style');
        $(campo.selector).addClass('form-control');
      }
    });
    if (this.state.listaDePrecios === 0) {
      $("#divListasDePrecios .select-met .css-1cza3nx-control").css(errorStyles);
      respuesta = false;
    } else {
      $("#divListasDePrecios .select-met .css-1cza3nx-control").removeAttr('style');
    }
    if (this.state.tipoDeVenta === 0) {
      $("#divTiposDeVenta .select-met .css-1cza3nx-control").css(errorStyles);
      respuesta = false;
    } else {
      $("#divTiposDeVenta .select-met .css-1cza3nx-control").removeAttr('style');
    }
    if (this.state.categoria === 0 ) {
      $("#divCategorias .select-met .css-3iigni-container .css-1s42kr9-control").css(errorStyles);
      $("#divCategorias .select-met .css-1cza3nx-control").css(errorStyles);
      respuesta = false;
    } else {
      $("#divCategorias .select-met .css-1s42kr9-control").removeAttr('style');
    }
    if (this.state.obraSocial === 0) {
      $("#divObrasSociales .select-met .css-3iigni-container .css-1s42kr9-control").css(errorStyles);
      $("#divObrasSociales .select-met .css-1s42kr9-control").css(errorStyles);
      respuesta = false;
    } else {
      $("#divObrasSociales .select-met .css-1s42kr9-control").removeAttr('style');
    }
    let cantidadAfiliados = $("[name='cantidadAfiliados']").val();
    for (let indice = 1; indice <= cantidadAfiliados; indice++) {
      rangoEtario = $(`[name='rangoEtario${indice}']`).val()
      if (rangoEtario === "" || rangoEtario === undefined || rangoEtario === " ") {
        $(".form-floating .select-met .css-3iigni-container > .css-1s42kr9-control").css(errorStyles);
        respuesta = false;
      } else {
        $(".form-floating .select-met .css-3iigni-container > .css-1s42kr9-control").removeAttr('style');
      }
    }
    if (respuesta) {
      var respuestaJson;
      fetch(url + "cotizaciones", {
        method: 'POST',
        body: JSON.stringify(cotizacion),
        headers: {
          "Content-Type": "application/json",
          'x-access-token': `${this.state.token}`
        }
      }).then(response => {
        if (!response.ok) {
          Swal.fire({
            icon: "error",
            text: "Hubo un error al procesar la solicitud",
            width: 500,
            height: 200,
          });
        }
        else {
          respuestaJson = response.json();
        }
        return respuestaJson;
      }).then(respuestaJson => {
        if (respuestaJson) {
          console.log(respuestaJson);
          const content = ReactDOM.createRoot(document.getElementById('content'));
          content.render(
            <Presupuesto json={respuestaJson} token={this.state.token}></Presupuesto>
          );
        }
      })
        .catch(err => {
          console.error('Error al enviar la petición POST:', err);
        });
    } else {
      Swal.fire({
        icon: "error",
        text: "Para continuar debe completar todos los campos.",
        width: 500,
        height: 200,
      });
    }
  }
  render() {
    return (
      <div >
        <br></br>
        <form className="row g-3" style={{ backgroundColor: "#f0f0f0" }}>
          <div className="row g-3 col-sm-12 mb-3 mb-sm-0 filaAsesor" >
            <H texto={"Nueva Cotización"} tamano={3}></H>
          </div>
          <br></br>
          <div className="card row g-3 col-sm-12 mb-3 mb-sm-0" >
            <div className="row g-3">
              <H texto="Datos del titular" tamano={5}></H>
              <FormInputEditable
                tamano={3}
                type="text"
                id="nombre"
                value={this.state.nombre}
                placeholder="Nombre"
                name="nombre_titular"
                readonly={"false"}
                onChange={this.handleNombreChange}
              />
              <FormInputEditable
                tamano={3}
                type="text"
                id="apellido"
                value={this.state.apellido}
                placeholder="Apellido"
                name="apellido_titular"
                readonly={"false"}
                onChange={this.handleApellidoChange}
              />
              <FormInputEditable
                tamano={3}
                type="text"
                id="documento"
                value={this.state.documento}
                placeholder="Documento"
                name="documento"
                readonly={"false"}
                onChange={this.handleDocumentoChange}
              />
              <FormInputEditable
                tamano={3}
                type="text"
                id="celular"
                value={this.state.celular}
                placeholder="Celular"
                name="celular"
                readonly={"false"}
                onChange={this.handleCelularChange}
              />
            </div>
            <div className="row g-3">
              <div name="divProvincias" id="divProvincias" className="col-md-3">
                <div className="form-floating select-met">
                  <SelectReact
                    name="provincias"
                    inputId="provincias"
                    placeholder=""
                    isClearable={true}
                    isSearchable={false}
                    options={this.state.dataProvincias}
                    getOptionLabel={(option) => option.nombre}
                    getOptionValue={(option) => option.id}
                    menuPortalTarget={document.body}
                    menuPosition={'fixed'}
                    menuPlacement="auto"
                    styles={estilosSelect}
                    value={this.state.dataProvincias.find(option => option.id === this.state.provincia)}
                    onChange={(opcion) => {
                      this.setState({ provincia: opcion.id, localidad: 0 }, () => {
                        this.fetchDataLocalidades();
                      })
                    }}
                  />
                  <label htmlFor="provincias">Provincia</label>
                </div>
              </div>
              <div name="divLocalidades" id="divLocalidades" className="col-md-3">
                <div className="form-floating select-met">
                  <SelectReact
                    name="localidad"
                    inputId="localidad"
                    isClearable={false}
                    isSearchable={true}
                    isDisabled={this.state.dataLocalidad.length > 0 ? false : true}
                    options={this.state.dataLocalidad}
                    getOptionLabel={(fila) => fila.nombre}
                    getOptionValue={(fila) => fila.id}
                    placeholder=""
                    menuPortalTarget={document.body}
                    menuPosition={'fixed'}
                    menuPlacement="auto"
                    styles={estilosSelect}
                    value={this.state.dataLocalidad.find(option => option.id === this.state.localidad)}
                    onChange={(opcion) => {
                      this.setState({ localidad: opcion.id })
                    }}
                  />
                  <label htmlFor="localidad">Localidad</label>
                </div>
              </div>
            </div>
            <br />
          </div>
          <div className="card row g-3 col-sm-12 mb-3 mb-sm-0" >
            <div className="row g-3">
              <H texto="Datos generales" tamano={5}></H>
              <div name="divListasDePrecios" id="divListasDePrecios" className="col-md-3">
                <div className="form-floating select-met">
                  <SelectReact
                    name="lista_de_precios"
                    inputId="lista_de_precios"
                    isClearable={false}
                    isSearchable={false}
                    options={this.state.dataListaDePrecios}
                    getOptionLabel={(option) => option.nombre}
                    getOptionValue={(option) => option.id}
                    placeholder=""
                    menuPortalTarget={document.body}
                    menuPosition={'fixed'}
                    menuPlacement="auto"
                    styles={estilosSelect}
                    value={this.state.dataListaDePrecios.find(option => option.id === this.state.listaDePrecios)}
                    onChange={(opcion) => {
                      this.setState({ listaDePrecios: opcion.id }, () => {
                        this.fetchDataPlanes().then(() => {
                          this.mostrarAfiliados();
                        });

                      })
                    }}
                  />
                  <label htmlFor="lista_de_precios">Lista de Precios</label>
                </div>
              </div>
              <div name="divTiposDeVenta" id="divTiposDeVenta" className="col-md-3">
                <div className="form-floating select-met">
                  <SelectReact
                    name="tipos_de_venta"
                    inputId="tipos_de_venta"
                    isClearable={false}
                    isSearchable={false}
                    isDisabled={this.state.dataTiposDeVenta.length > 0 ? false : true}
                    options={this.state.dataTiposDeVenta}
                    getOptionLabel={(fila) => fila.nombre}
                    getOptionValue={(fila) => fila.id}
                    placeholder=""
                    menuPortalTarget={document.body}
                    menuPosition={'fixed'}
                    menuPlacement="auto"
                    styles={estilosSelect}
                    value={this.state.dataTiposDeVenta.find(option => option.id === this.state.tipoDeVenta)}
                    onChange={(opcion) => {
                      this.setState({ tipoDeVenta: opcion.id, dataCategoria: [], categoria: 0, obraSocial: -1, dataObrasSociales: [], }, () => {
                        this.fetchDataCategorias();
                      });
                    }}
                  />
                  <label htmlFor="tipos_de_venta">Tipos de Venta</label>
                </div>
              </div>
              <div name="divCategorias" id="divCategorias" className="col-md-3">
                <div className="form-floating  select-met">
                  <SelectReact
                    name="categoria"
                    inputId="categoria"
                    isClearable={false}
                    isSearchable={false}
                    isDisabled={this.state.dataCategoria.length > 0 ? false : true}
                    options={this.state.dataCategoria}
                    getOptionLabel={(option) => option.nombre}
                    getOptionValue={(option) => option.id}
                    placeholder=""
                    menuPortalTarget={document.body}
                    menuPosition={'fixed'}
                    menuPlacement="auto"
                    styles={estilosSelect}
                    value={this.state.dataCategoria.find(option => option.id === this.state.categoria)}
                    onChange={(opcion) => {
                      this.setState({ categoria: opcion.id, obraSocial: 0, dataObrasSociales: [], keyObraSocial: Date.now() }, () => {
                        this.fetchDataObrasSociales();
                      })
                    }}
                  />
                  <label htmlFor="categoria">Categoria</label>
                </div>
              </div>
              <div name="divObrasSociales" id="divObrasSociales" className="col-md-3">
                <div className="form-floating select-met">
                  <SelectReact
                    name="obrasSocial"
                    inputId="obrasSocial"
                    isClearable={false}
                    isSearchable={false}
                    isDisabled={this.state.dataObrasSociales.length > 0 ? false : true}
                    options={this.state.dataObrasSociales}
                    getOptionLabel={(option) => option.nombre}
                    getOptionValue={(option) => option.id}
                    placeholder=""
                    menuPortalTarget={document.body}
                    menuPosition={'fixed'}
                    menuPlacement="auto"
                    value={this.state.dataObrasSociales.find(option => option.id === this.state.obraSocial)}
                    styles={estilosSelect}
                    onChange={(opcion) => {
                      this.setState({ obraSocial: opcion.id });
                    }}
                    key={this.state.keyObraSocial}
                  />
                  <label htmlFor="obraSocial">Obra Social</label>
                </div>
              </div>
            </div>
            <div className="row g-3">
              <FormInputEditable
                tamano={3}
                type="decimal"
                id="sueldo"
                value={this.state.sueldo}
                placeholder="Sueldo"
                name="sueldo"
                readonly={"false"}
                onChange={this.handleSueldoChange}
              />
              <div className="form-floating select-met col-md-3">
                <SelectReact
                  name="cantidadAfiliados"
                  inputId="cantidadAfiliados"
                  isClearable={false}
                  isSearchable={false}
                  options={this.state.opcionesAfiliados}
                  placeholder=""
                  menuPortalTarget={document.body}
                  menuPosition={'fixed'}
                  menuPlacement="auto"
                  styles={estilosSelect}
                  defaultValue={{ value: this.props.json?.afiliados?.length || 1, label: this.props.json?.afiliados?.length || '1' }}
                  onChange={(opcion) => {
                    this.setState({ cantidadAfiliados: opcion.value }, () => {
                      this.mostrarAfiliados();
                    })
                  }}
                />
                <label htmlFor="Cantidad de afiliados">Cantidad de afiliados</label>
              </div>
            </div>
            <br></br>
          </div>
          <div className="card row g-3 col-sm-12 mb-3 mb-sm-0" >
            <H texto={"Planes"} tamano={5}></H>
            <div className="row g-3" id="divAfiliados" name="divAfiliados">
              <SelectAfiliado2 token={this.state.token} index={1} idControl={1} dataPlanes={this.state.dataPlanes} listaDePrecios={this.state.listaDePrecios} plan={this.props.json?.afiliados?.[0]?.rangoEtario?.plan?.id || 0} rangoEtario={this.props.json?.afiliados?.[0]?.rangoEtario?.id || 0} />
              <SelectAfiliado2 token={this.state.token} index={2} idControl={2} dataPlanes={this.state.dataPlanes} listaDePrecios={this.state.listaDePrecios} plan={this.props.json?.afiliados?.[1]?.rangoEtario?.plan?.id || 0} rangoEtario={this.props.json?.afiliados?.[0]?.rangoEtario?.id || 0} />
              <SelectAfiliado2 token={this.state.token} index={3} idControl={3} dataPlanes={this.state.dataPlanes} listaDePrecios={this.state.listaDePrecios} plan={this.props.json?.afiliados?.[2]?.rangoEtario?.plan?.id || 0} rangoEtario={this.props.json?.afiliados?.[0]?.rangoEtario?.id || 0} />
              <SelectAfiliado2 token={this.state.token} index={4} idControl={4} dataPlanes={this.state.dataPlanes} listaDePrecios={this.state.listaDePrecios} plan={this.props.json?.afiliados?.[3]?.rangoEtario?.plan?.id || 0} rangoEtario={this.props.json?.afiliados?.[0]?.rangoEtario?.id || 0} />
              <SelectAfiliado2 token={this.state.token} index={5} idControl={5} dataPlanes={this.state.dataPlanes} listaDePrecios={this.state.listaDePrecios} plan={this.props.json?.afiliados?.[4]?.rangoEtario?.plan?.id || 0} rangoEtario={this.props.json?.afiliados?.[0]?.rangoEtario?.id || 0} />
              <SelectAfiliado2 token={this.state.token} index={6} idControl={6} dataPlanes={this.state.dataPlanes} listaDePrecios={this.state.listaDePrecios} plan={this.props.json?.afiliados?.[5]?.rangoEtario?.plan?.id || 0} rangoEtario={this.props.json?.afiliados?.[0]?.rangoEtario?.id || 0} />
              <SelectAfiliado2 token={this.state.token} index={7} idControl={7} dataPlanes={this.state.dataPlanes} listaDePrecios={this.state.listaDePrecios} plan={this.props.json?.afiliados?.[6]?.rangoEtario?.plan?.id || 0} rangoEtario={this.props.json?.afiliados?.[0]?.rangoEtario?.id || 0} />
              <SelectAfiliado2 token={this.state.token} index={8} idControl={8} dataPlanes={this.state.dataPlanes} listaDePrecios={this.state.listaDePrecios} plan={this.props.json?.afiliados?.[7]?.rangoEtario?.plan?.id || 0} rangoEtario={this.props.json?.afiliados?.[0]?.rangoEtario?.id || 0} />
              <SelectAfiliado2 token={this.state.token} index={9} idControl={9} dataPlanes={this.state.dataPlanes} listaDePrecios={this.state.listaDePrecios} plan={this.props.json?.afiliados?.[8]?.rangoEtario?.plan?.id || 0} rangoEtario={this.props.json?.afiliados?.[0]?.rangoEtario?.id || 0} />
              <SelectAfiliado2 token={this.state.token} index={10} idControl={10} dataPlanes={this.state.dataPlanes} listaDePrecios={this.state.listaDePrecios} plan={this.props.json?.afiliados?.[9]?.rangoEtario?.plan?.id || 0} rangoEtario={this.props.json?.afiliados?.[0]?.rangoEtario?.id || 0} />
            </div>
            <br></br>

          </div>
          <div className="card row g-3 col-sm-12 mb-3 mb-sm-0" >
            <H texto={"Bonificaciones"} tamano={5}></H>
            <div className="row g-3" id="divBonificaciones" name="divBonificaciones">
              <div className="form-floating col-md-3">
                <div name="divBonificaciones" className="form-floating select-met">
                  <SelectReact
                    name="bonificaciones"
                    inputId="bonificaciones"
                    isClearable={false}
                    isSearchable={false}
                    options={this.state.dataBonificaciones}
                    getOptionLabel={(option) => option.nombre}
                    getOptionValue={(option) => option.id}
                    placeholder=""
                    menuPortalTarget={document.body}
                    menuPosition={'fixed'}
                    menuPlacement="auto"
                    styles={estilosSelect}
                    onChange={(opcion) => {
                      this.setState({ bonificacionID: opcion.id, bonificacionNombre: opcion.nombre })
                    }}
                    key={this.state.keyBonificacion}
                  />
                  <label htmlFor="bonificaciones">Bonificaciones</label>
                </div>
              </div>
              <FormInputEditable
                tamano={4}
                type="text"
                id="observaciones"
                value={this.state.observaciones}
                placeholder="Observaciones"
                name="observaciones"
                readonly={"false"}
                onChange={this.handleObservacionChange}
              />
              <div className="form-floating mb-3 col-md-1"></div>
              <div className="row g-2 form-floating col-md-2">
                <button id="boton_nvo_beneficio" type="button" className="btn btn-met float-end" onClick={() => this.agregarBeneficio()}>Agregar Bonificación</button>
              </div>
              <div className="form-floating col-md-7" id="divTablaBeneficiarios">
                <table className="table table-striped" id="tablaBeneficiarios">
                  <thead>
                    <tr>
                      <th scope="col">Bonificación</th>
                      <th scope="col">Observaciones</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                  </tbody>
                </table>
              </div>
            </div>
            <br></br>

          </div>
          <div className="card row g-3 col-sm-12 mb-3 mb-sm-0" >
            <div className="row g-3" >
              <div className="form-floating mb-3 col-md-10"></div>
              <div className="form-floating mb-3 col-md-2" id="div_boton">
                <button id="boton_cotizar" type="button" className="btn btn-met float-end" onClick={this.guardarPresupuesto}>Cotizar</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    )
  }
  
};

export default Cotizador2;