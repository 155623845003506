import React, { useEffect, useState, Component } from "react";
import ReactDOM from 'react-dom/client';
import { Input, H,FormInputEditable } from "../utils/Controles";
import $ from 'jquery';
import SelectReact from 'react-select';
import Presupuesto from "./Presupuesto";
import { url, estilosSelect } from "../utils/Globals";
import Swal from  'sweetalert2';
class ObtenerProvincias extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      valorPorDefecto: 6,
      valorPorDefectoEnData: null,
    };
  }
  componentDidMount() {
    this.fetchData();
  }
  async fetchData() {
    try {
      const headers = {
        'x-access-token': `${this.props.token}`,
      };
      const response = await fetch(url + "provincias", { headers });
      const responseData = await response.json();
      this.setState({ data: responseData }, () => {
        this.actualizarValorPorDefecto();
      });
    } catch (error) {
      console.error('Error al obtener los datos:', error);
    }
  }

  actualizarValorPorDefecto() {
    const { data, valorPorDefecto } = this.state;
    const valorDefectoEnData = data.find((option) => option.id === valorPorDefecto);
    if (valorDefectoEnData) {
      this.setState({ valorPorDefectoEnData: valorDefectoEnData });
      CambioProvincia(valorDefectoEnData.id, this.props.token);
    }
  }

  render() {
    const { data, valorPorDefectoEnData } = this.state;
    return (
      <div className="form-floating select-met">
        <SelectReact
          name="provincias"
          inputId="provincias"
          isClearable={true}
          isSearchable={false}
          options={data}
          getOptionLabel={(option) => option.nombre}
          getOptionValue={(option) => option.id}
          placeholder=""
          menuPortalTarget={document.body}
          menuPosition={'fixed'}
          menuPlacement="auto"
          styles={estilosSelect}
          value={valorPorDefectoEnData}
          onChange={(opcion) => {
            if (opcion) {
              this.setState({ valorPorDefectoEnData: opcion });
              CambioProvincia(opcion.id, this.props.token);
            } else {
              this.setState({ valorPorDefectoEnData: null });
              CambioProvincia(0, this.props.token);
            }
          }}
        />
        <label htmlFor="provincias">Provincia</label>
      </div>
    );
  }
}
class ObtenerLocalidades extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      provinciaPorDefecto: 6,
      deshabilitado: true,
      valorPorDefecto: 1105,
      valorPorDefectoEnData: null,
    };
  }
  componentDidMount() {
    this.fetchData();
  }
  async fetchData() {
    try {
      const headers = {
        'x-access-token': `${this.props.token}`,
      };
      if (this.props.provincia !== undefined || this.props.provincia !== '') {
        const response = await fetch(url + "localidades?" + new URLSearchParams({
          provincia: this.props.provincia
        }), { headers });

        const responseData = await response.json();
        this.setState({ data: responseData }, () => {
          this.actualizarValorPorDefecto();
        });
      }

    } catch (error) {
      console.error('Error al obtener los datos:', error);
    }
  }

  actualizarValorPorDefecto() {
    var { data, deshabilitado, valorPorDefecto } = this.state;
    if (data.length > 0) {
      const valorDefectoEnData = data.find((option) => option.id === valorPorDefecto);
      deshabilitado = false;
      if (valorDefectoEnData) {
        this.setState({ deshabilitado: deshabilitado, valorPorDefectoEnData: valorDefectoEnData });
      } else {
        this.setState({ deshabilitado: deshabilitado });
      }
    }
  }

  render() {
    const { data, deshabilitado, valorPorDefectoEnData } = this.state;
    return (
      <div className="form-floating select-met">
        <SelectReact
          name="localidad"
          inputId="localidad"
          isClearable={false}
          isSearchable={true}
          isDisabled={deshabilitado}
          options={data}
          getOptionLabel={(fila) => fila.nombre}
          getOptionValue={(fila) => fila.id}
          placeholder=""
          menuPortalTarget={document.body}
          menuPosition={'fixed'}
          menuPlacement="auto"
          styles={estilosSelect}
          value={valorPorDefectoEnData}
          onChange={(opcion) => {
            if (opcion) {
              this.setState({ valorPorDefectoEnData: opcion });
            } else {
              this.setState({ valorPorDefectoEnData: null });
            }
          }}
        />
        <label htmlFor="localidad">Localidad</label>
      </div>
    );
  }
}
class ObtenerListaPrecios extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
    };
  }
  componentDidMount() {
    this.fetchData();
  }
  async fetchData() {
    try {
      const headers = {
        'x-access-token': `${this.props.token}`,
      };
      const response = await fetch(url + "listas-de-precios?conPrecio=1", { headers });
      const responseData = await response.json();
      this.setState({ data: responseData }, () => {
        //this.actualizarValorPorDefecto();
      });
    } catch (error) {
      console.error('Error al obtener los datos:', error);
    }
  }
  actualizarValorPorDefecto() {
    const { data } = this.state;
    const valorDefectoEnData = data.find((option) => option.id === data[0].id);
    var cantidad = data.length;
    if (cantidad === 1) {
      this.setState({ valorPorDefectoEnData: valorDefectoEnData }, () => {
      });
    }
  }
  render() {
    const { data, valorPorDefectoEnData } = this.state;
    return (
      <div className="form-floating select-met">
        <SelectReact
          name="lista_de_precios"
          inputId="lista_de_precios"
          isClearable={false}
          isSearchable={false}
          options={data}
          getOptionLabel={(option) => option.nombre}
          getOptionValue={(option) => option.id}
          placeholder=""
          menuPortalTarget={document.body}
          menuPosition={'fixed'}
          menuPlacement="auto"
          styles={estilosSelect}
          value={valorPorDefectoEnData}
          onChange={(opcion) => {
            if (opcion) {
              CambioListaDePrecios(opcion.id, this.props.token);
            } else {
              CambioListaDePrecios(0, this.props.token);
            }
          }}
        />
        <label htmlFor="lista_de_precios">Lista de Precios</label>
      </div>
    );
  }
}
class ObtenerTiposVenta extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
    };
  }
  componentDidMount() {
    this.fetchData();
  }
  async fetchData() {
    try {
      const headers = {
        'x-access-token': `${this.props.token}`,
      };
      const response = await fetch(url + "tipos-de-venta?" + new URLSearchParams({
        conDescuento: "1"
      }), { headers });

      const responseData = await response.json();
      this.setState({ data: responseData }, () => {
        this.actualizarValorPorDefecto();
      });
    } catch (error) {
      console.error('Error al obtener los datos:', error);
    }
  }

  actualizarValorPorDefecto() {
    const { data } = this.state;
    let deshabilitado = true;
    let valorPorDefectoEnData = null;

    if (data.length > 0) {
      deshabilitado = false;
      if (data.length === 1) {
        valorPorDefectoEnData = data[0];
      }
    }
    CambioTipoVenta(valorPorDefectoEnData, this.props.token);
    this.setState({ deshabilitado, valorPorDefectoEnData }, () => {
    });
  }

  render() {
    const { data, deshabilitado, valorPorDefectoEnData } = this.state;
    return (
      <div className="form-floating select-met">
        <SelectReact
          name="tipos_de_venta"
          inputId="tipos_de_venta"
          isClearable={false}
          isSearchable={false}
          isDisabled={deshabilitado}
          options={data}
          getOptionLabel={(fila) => fila.nombre}
          getOptionValue={(fila) => fila.id}
          placeholder=""
          menuPortalTarget={document.body}
          menuPosition={'fixed'}
          menuPlacement="auto"
          styles={estilosSelect}
          value={valorPorDefectoEnData}
          onChange={(opcion) => {
            if (opcion) {
              this.setState({ valorPorDefectoEnData: opcion });
              CambioTipoVenta(opcion.id, this.props.token);
            }
            else {
              this.setState({ valorPorDefectoEnData: null });
              CambioTipoVenta(0, this.props.token);
            }
          }}
        />
        <label htmlFor="tipos_de_venta">Tipos de Venta</label>
      </div>
    );
  }
}
class ObtenerCategorias extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tipoDeVenta: props.tipoDeVenta,
      data: [],
    };
  }
  componentDidMount() {
    this.fetchData();
  }
  async fetchData() {
    try {
      const headers = {
        'x-access-token': `${this.props.token}`,
      };
      const response = await fetch(url + "categorias-de-venta?" + new URLSearchParams({
        conDescuento: "1",
        tipoDeVenta: this.props.tipoDeVenta
      }), { headers });

      const responseData = await response.json();
      this.setState({ data: responseData }, () => {
        this.actualizarValorPorDefecto();
      });
    } catch (error) {
      console.error('Error al obtener los datos:', error);
    }
  }

  actualizarValorPorDefecto() {
    const { data } = this.state;
    let deshabilitado = true;
    let valorPorDefectoEnData = null;

    if (data.length > 0) {
      deshabilitado = false;
      if (data.length === 1) {
        valorPorDefectoEnData = data[0];
        CambioCategoria(valorPorDefectoEnData.id, this.props.token);
      }
    }
    this.setState({ deshabilitado, valorPorDefectoEnData }, () => {
    });
  }

  render() {
    const { data, deshabilitado, valorPorDefectoEnData } = this.state;
    return (
      <div className="form-floating  select-met">
        <SelectReact
          name="categoria"
          inputId="categoria"
          isClearable={false}
          isSearchable={false}
          isDisabled={deshabilitado}
          options={data}
          getOptionLabel={(option) => option.nombre}
          getOptionValue={(option) => option.id}
          placeholder=""
          menuPortalTarget={document.body}
          menuPosition={'fixed'}
          menuPlacement="auto"
          styles={estilosSelect}
          value={valorPorDefectoEnData}
          onChange={(opcion) => {
            if (opcion) {
              this.setState({ valorPorDefectoEnData: opcion });
              CambioCategoria(opcion.id, this.props.token);
            } else {
              this.setState({ valorPorDefectoEnData: null });
              CambioCategoria(0, this.props.token);
            }
          }}
        />
        <label htmlFor="categoria">Categoria</label>
      </div>
    );
  }
}
class ObtenerObrasSociales extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categoriaDeVenta: props.categoriaDeVenta,
      data: [],
    };
  }
  componentDidMount() {
    this.fetchData();
  }
  async fetchData() {
    try {
      const headers = {
        'x-access-token': `${this.props.token}`,
      };
      const response = await fetch(url + "obras-sociales?" + new URLSearchParams({
        conDescuento: "1",
        categoriaDeVenta: this.props.categoriaDeVenta
      }), { headers });

      const responseData = await response.json();
      this.setState({ data: responseData }, () => {
        this.actualizarValorPorDefecto();
      });
    } catch (error) {
      console.error('Error al obtener los datos:', error);
    }
  }

  actualizarValorPorDefecto() {
    const { data } = this.state;
    let deshabilitado = true;
    let valorPorDefectoEnData = null;
    if (data.length > 0) {
      deshabilitado = false;
      if (data.length === 1) {
        valorPorDefectoEnData = data[0];
      }
    }
    this.setState({ deshabilitado, valorPorDefectoEnData }, () => {
    });
  }

  render() {
    const { data, valorPorDefectoEnData, deshabilitado } = this.state;
    return (
      <div className="form-floating select-met">
        <SelectReact
          name="obrasSocial"
          inputId="obrasSocial"
          isClearable={false}
          isSearchable={false}
          isDisabled={deshabilitado}
          options={data}
          getOptionLabel={(option) => option.nombre}
          getOptionValue={(option) => option.id}
          placeholder=""
          menuPortalTarget={document.body}
          menuPosition={'fixed'}
          menuPlacement="auto"
          value={valorPorDefectoEnData}
          styles={estilosSelect}
          onChange={(opcion) => {
            if (opcion) {
              this.setState({ valorPorDefectoEnData: opcion });
            } else {
              this.setState({ valorPorDefectoEnData: null });
            }
          }}
        />
        <label htmlFor="obraSocial">Obra Social</label>
      </div>
    );
  }
}
const ObtenerPlanes = ({ idControl, listaDePrecios, token }) => {
  const [data, setData] = useState([]);
  const [deshabilitado, setDeshabilitado] = useState(true);

  useEffect(() => {
    if (listaDePrecios) {
      const fetchData = async () => {
        try {
          const headers = {
            'x-access-token': `${token}`
          };

          const response = await fetch(url + "planes?" + new URLSearchParams({
            conPrecio: "1",
            listaDePrecios: listaDePrecios
          }), { headers });
          const responseData = await response.json();
          setData(responseData);
          if (responseData.length > 0) {
            setDeshabilitado(false);
          }
        } catch (error) {
          console.error('Error al obtener los datos:', error);
        }
      };
      fetchData();
    }
  }, [listaDePrecios, token]);

  return (
    <div className="form-floating  select-met">
      <SelectReact
        name={idControl}
        inputId={idControl}
        isClearable={false}
        isSearchable={false}
        isDisabled={deshabilitado}
        options={data}
        getOptionLabel={(opcion) => opcion.nombre}
        getOptionValue={(opcion) => opcion.id}
        placeholder=""
        menuPortalTarget={document.body}
        menuPosition={'fixed'}
        menuPlacement="auto"
        styles={estilosSelect}
        onChange={(opcion, event) => {
          if (opcion) {
            CambioPlan(opcion.id, event.name, token);
          } else {
            CambioPlan(0, token);
          }
        }}
      />
      <label htmlFor={idControl}>Plan</label>
    </div>
  );
};
const ObtenerRangos = ({ listaDePrecios, plan, idControl, token }) => {
  const [data, setData] = useState([]);
  const [deshabilitado, setDeshabilitado] = useState(true);

  useEffect(() => {
    if (listaDePrecios && plan) {
      const fetchData = async () => {
        try {
          const headers = {
            'x-access-token': `${token}`
          };
          const response = await fetch(url + "rangos-etarios?" + new URLSearchParams({
            conPrecio: "1",
            listaDePrecios: listaDePrecios,
            plan: plan
          }), { headers });
          const responseData = await response.json();
          setData(responseData);
          if (responseData.length > 0) {
            setDeshabilitado(false);
          }
        } catch (error) {
          console.error('Error al obtener los datos:', error);
        }
      };
      fetchData();
    }
  }, [listaDePrecios, plan, token]);

  return (
    <div className="form-floating  select-met">
      <SelectReact
        name={idControl}
        inputId={idControl}
        isClearable={false}
        isSearchable={false}
        isDisabled={deshabilitado}
        options={data}
        getOptionLabel={(option) => option.nombre}
        getOptionValue={(option) => option.id}
        placeholder=""
        menuPortalTarget={document.body}
        menuPosition={'fixed'}
        menuPlacement="auto"
        styles={estilosSelect}
      />
      <label htmlFor={idControl}>Rango etario</label>
    </div>
  );
};
class ObtenerBonificaciones extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
    };
  }

  componentDidMount() {
    CambioProvincia(this.state.valorPorDefecto, this.props.token);
    this.fetchData();
  }

  async fetchData() {
    try {
      const headers = {
        'x-access-token': `${this.props.token}`,
      };
      const response = await fetch(url + "bonificaciones-generales?habilitado=1", { headers });
      const responseData = await response.json();
      this.setState({ data: responseData }, () => {
      });
    } catch (error) {
      console.error('Error al obtener los datos:', error);
    }
  }
  render() {
    const { data } = this.state;
    return (
      <div name="divBonificaciones" className="form-floating select-met">
        <SelectReact
          name="bonificaciones"
          inputId="bonificaciones"
          isClearable={false}
          isSearchable={false}
          options={data}
          getOptionLabel={(option) => option.nombre}
          getOptionValue={(option) => option.id}
          placeholder=""
          menuPortalTarget={document.body}
          menuPosition={'fixed'}
          menuPlacement="auto"
          styles={estilosSelect}
        />
        <label htmlFor="bonificaciones">Bonificaciones</label>
      </div>
    );
  }
}
function ObtenerCantidadAfiliados() {

  const opciones = [
    { value: 1, label: '1' },
    { value: 2, label: '2' },
    { value: 3, label: '3' },
    { value: 4, label: '4' },
    { value: 5, label: '5' },
    { value: 6, label: '6' },
    { value: 7, label: '7' },
    { value: 8, label: '8' },
    { value: 9, label: '9' },
    { value: 10, label: '10' },
  ];

  return (
    <div className="form-floating select-met col-md-3">
      <SelectReact
        name="cantidadAfiliados"
        inputId="cantidadAfiliados"
        isClearable={false}
        isSearchable={false}
        options={opciones}
        placeholder=""
        menuPortalTarget={document.body}
        menuPosition={'fixed'}
        menuPlacement="auto"
        styles={estilosSelect}
        defaultValue={{ value: 1, label: '1' }}
        onChange={(opcion) => {
          if (opcion) {
            MostrarAfiliados(opcion.value);
          }
          else {
            MostrarAfiliados(1);
          }
        }}
      />
      <label htmlFor="Cantidad de afiliados">Cantidad de afiliados</label>
    </div>
  );
};

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


const SelectAfiliados = (props) => {
  let listaDePrecios = 0;
  let cantidadAfiliados = 1;
  let display = "none";

  if (props.listaDePrecios) {
    listaDePrecios = props.listaDePrecios;
  }
  if (props.cantidadAfiliados) {
    cantidadAfiliados = props.cantidadAfiliados;
  }
  if (props.index <= cantidadAfiliados) {
    display = "flex";
  }

  return (
    <div className="row g-3 filaAfiliado" style={{ display: display }}>

      <div className="form-floating col-md-1">
        <div className="form-floating col-md divLabelAfiliado">
          <label
            className="labelAfiliado"
            id={`labelAfiliado${props.index}`}
            style={{
              textAlign: "center",
              padding: "8px 12px 30px 12px"
            }}
          >
            {props.index}
          </label>
        </div>
      </div>
      <div className="form-floating col-md-3" id={`divPlan${props.index}`}>
        <ObtenerPlanes idControl={`plan${props.index}`} listaDePrecios={listaDePrecios} token={props.token} />
      </div>
      <div className="form-floating col-md-3" id={`divRangoEtario${props.index}`}>
        <ObtenerRangos idControl={`rangoEtario${props.index}`} token={props.token} />
      </div>

    </div>
  );
}

function cotizar(usuario, token) {
  let validar = Validar();
  if (validar) {
    let localidad = $("[name='localidad']").val();
    let categoria = $("[name='categoria']").val();
    let obraSocial = $("[name='obrasSocial']").val();
    let listaDePrecio = $("[name='lista_de_precios']").val();
    let tipoDeVenta = $("[name='tipos_de_venta']").val();
    let sueldo = $("#sueldo").val();
    if (tipoDeVenta !== 2) {
      if (sueldo === undefined || sueldo === "") {
        sueldo = "0";
      }
    }

    let nombre = $("#nombre").val();
    let apellido = $("#apellido").val();
    let documento = $("#documento").val();
    let celular = $("#celular").val();

    /*-----------Afiliados---------------*/
    let afiliados = "";
    let cantidadAfiliados = $("[name='cantidadAfiliados']").val();
    for (let indice = 1; indice <= cantidadAfiliados; indice++) {
      var rangoEtario = $(`[name='rangoEtario${indice}']`).val()
      var afiliado = "";
      if (indice === 1) {
        afiliado = `[
          {
            "rangoEtario": ${rangoEtario}
          }`;
      } else {
        afiliado = `,
        {
          "rangoEtario": ${rangoEtario}
        }`;
      }
      afiliados += afiliado;
    }
    afiliados += `
  ]`;
    /*-----------Afiliados---------------*/
    /*-----------Bonificacion---------------*/
    let bonificacionesGenerales = "";
    let bonificacion = "";
    let observacion = "";
    let bonificaciones = $("#tablaBeneficiarios tbody tr");
    let indice = 0;
    let cantidad = 0;
    let bonificacionSeleccionada = "";
    bonificaciones.find('td').each(function (index, element) {
      if (indice === 0) {
        bonificacion = element.getAttribute('id');
        indice++;
      } else if (indice === 1) {
        observacion = element.innerText.toString();
        indice++;
      } else {
        indice = 0;
        if (cantidad === 0) {
          bonificacionSeleccionada = `[
          {
            "bonificacionGeneral":${bonificacion},
            "observaciones":"${observacion}"
          }`;
        } else {
          bonificacionSeleccionada = `,
          {
            "bonificacionGeneral":${bonificacion},
            "observaciones":"${observacion}"
          }`;
        }
        bonificacionesGenerales += bonificacionSeleccionada;
        cantidad++;
      }


    });
    if (bonificacionesGenerales.length > 0) {
      bonificacionesGenerales += "]";
    } else {
      bonificacionesGenerales = "[]";
    }
    /*-----------Bonificacion---------------*/
    let cotizacion = `{
    "usuario":${usuario},
    "localidad":${localidad},
    "categoriaDeVenta":${categoria},
    "obraSocial":${obraSocial},
    "listaDePrecios":${listaDePrecio},
    "nombre":"${nombre}",
    "apellido":"${apellido}",
    "documento":"${documento}",
    "celular":"${celular}",
    "sueldo":${sueldo},
    "afiliados":${afiliados},
    "bonificacionesGenerales":${bonificacionesGenerales}
  }`;
    guardarPresupuesto(cotizacion, token);
  } else {
    Swal.fire({
      icon: "error",
      text: "Para continuar debe completar todos los campos.",
      width: 500,
      height: 200,
    });
  }
};
function guardarPresupuesto(json, token) {
  var respuestaJson;
  fetch(url + "cotizaciones", {
    method: 'POST',
    body: json,
    headers: {
      "Content-Type": "application/json",
      'x-access-token': `${token}`
    }
  }).then(response => {
    if (!response.ok) {
      Swal.fire({
        icon: "error",
        text: "Hubo un error al procesar la solicitud",
        width: 500,
        height: 200,
      });
    }
    else {
      respuestaJson = response.json();
    }
    return respuestaJson;
  }).then(respuestaJson => {
    if (respuestaJson) {
      const content = ReactDOM.createRoot(document.getElementById('content'));
      content.render(
        <Presupuesto json={respuestaJson} token={token} origen={"usuario"}></Presupuesto>
      );
    }
  })
    .catch(err => {
      console.error('Error al enviar la petición POST:', err);
    });
}
function scrollToElement(element) {
  $('html, body').animate({
    scrollTop: $(element).offset().top
  }, 500);
}
function Validar() {
  let respuesta = true;
  var errorStyles = {
    'border-color': '#f56565',
    'color': '#2a2222',
    'border-width': '3px',
  };
  
  let nombre = $("#nombre").val();
  let apellido = $("#apellido").val();
  let documento = $("#documento").val();
  let celular = $("#celular").val();
  let categoria = $("[name='categoria']").val();
  let obraSocial = $("[name='obrasSocial']").val();
  let listaDePrecio = $("[name='lista_de_precios']").val();
  let tipoDeVenta = $("[name='tipos_de_venta']").val();
  let sueldo = $("#sueldo").val();

  var campos = [
    { nombre: nombre, selector: "#nombre", tipo: "id" },
    { nombre: apellido, selector: "#apellido", tipo: "id" },
    { nombre: documento, selector: "#documento", tipo: "id" },
    { nombre: celular, selector: "#celular", tipo: "id" },
  ];
  if (tipoDeVenta !== 2) {
    if (sueldo === undefined || sueldo === "") {
      campos.push({ nombre: sueldo, selector: "#sueldo",tipo:"id" })
    }
  }
  campos.forEach(function (campo) {
    if (campo.nombre === "" || campo.nombre === undefined ) {
        try{
          $(campo.selector).css(errorStyles);
        }catch{
          console.log(campo.selector);
        }
      if(campo.tipo === "id"){
        document.getElementById(campo.selector.substring(1)).focus();
        document.getElementById(campo.selector.substring(1)).select();
      }
      try{
        scrollToElement(campo.selector);
      }catch{
        console.log(campo.selector);
      }
      respuesta = false;
    } else {
      $(campo.selector).removeAttr('style');
      $(campo.selector).addClass('form-control');
    }
  });
  if(listaDePrecio === ""){
    $("#divListasDePrecios .select-met .css-1cza3nx-control").css(errorStyles);
    respuesta = false;
  }else{
    $("#divListasDePrecios .select-met .css-1cza3nx-control").removeAttr('style');
  }
  if(tipoDeVenta === ""){
    $("#divTiposDeVenta .select-met .css-1cza3nx-control").css(errorStyles);
    respuesta = false;
  }else{
    $("#divTiposDeVenta .select-met .css-1cza3nx-control").removeAttr('style');
  }
  if(categoria === ""|| categoria === undefined || categoria === " "){
    $("#divCategorias .select-met .css-3iigni-container .css-1s42kr9-control").css(errorStyles);
    $("#divCategorias .select-met .css-1cza3nx-control").css(errorStyles);
    respuesta = false;
  }else{
    $("#divCategorias .select-met .css-1s42kr9-control").removeAttr('style');
  }
  if(obraSocial === "" || obraSocial === undefined || obraSocial === " "){
    $("#divObrasSociales .select-met .css-3iigni-container .css-1s42kr9-control").css(errorStyles);
    $("#divObrasSociales .select-met .css-1s42kr9-control").css(errorStyles);
    respuesta = false;
  }else{
    $("#divObrasSociales .select-met .css-1s42kr9-control").removeAttr('style');
  }
  let cantidadAfiliados = $("[name='cantidadAfiliados']").val();
    for (let indice = 1; indice <= cantidadAfiliados; indice++) {
      var rangoEtario = $(`[name='rangoEtario${indice}']`).val()
      if(rangoEtario === ""|| rangoEtario === undefined || rangoEtario === " "){
        $(".form-floating .select-met .css-3iigni-container > .css-1s42kr9-control").css(errorStyles);
        respuesta = false;
      }else{
        $(".form-floating .select-met .css-3iigni-container > .css-1s42kr9-control").removeAttr('style');
      }
    }
  return respuesta;
}
function MostrarAfiliados(cantidadAfiliados = 1) {
  let afiliadoContainer = $('#divAfiliados');
  afiliadoContainer.find('.row.g-3').each(function (index, element) {
    $(element).show();
  });
  afiliadoContainer.find('.row.g-3').each(function (index, element) {
    if (index >= cantidadAfiliados) {
      $(element).hide();
      $(element).find('select.form-select').val("");
    }
  });
}
const CambioProvincia = (provincia, token) => {
  const contenedor = ReactDOM.createRoot(document.getElementById('divLocalidades'));
  contenedor.render(
    <React.StrictMode>
      <ObtenerLocalidades provincia={`${provincia}`} token={token} />
    </React.StrictMode>
  );
}
const CambioListaDePrecios = (listaDePrecios, token) => {

  let cantidadAfiliados = document.getElementsByName("cantidadAfiliados")[0].value;
  var contenedor = ReactDOM.createRoot(document.getElementById("divAfiliados"));

  contenedor.render(
    <React.StrictMode>
      <SelectAfiliados index={1} listaDePrecios={listaDePrecios} cantidadAfiliados={cantidadAfiliados} token={token}></SelectAfiliados>
      <SelectAfiliados index={2} listaDePrecios={listaDePrecios} cantidadAfiliados={cantidadAfiliados} token={token}></SelectAfiliados>
      <SelectAfiliados index={3} listaDePrecios={listaDePrecios} cantidadAfiliados={cantidadAfiliados} token={token}></SelectAfiliados>
      <SelectAfiliados index={4} listaDePrecios={listaDePrecios} cantidadAfiliados={cantidadAfiliados} token={token}></SelectAfiliados>
      <SelectAfiliados index={5} listaDePrecios={listaDePrecios} cantidadAfiliados={cantidadAfiliados} token={token}></SelectAfiliados>
      <SelectAfiliados index={6} listaDePrecios={listaDePrecios} cantidadAfiliados={cantidadAfiliados} token={token}></SelectAfiliados>
      <SelectAfiliados index={7} listaDePrecios={listaDePrecios} cantidadAfiliados={cantidadAfiliados} token={token}></SelectAfiliados>
      <SelectAfiliados index={8} listaDePrecios={listaDePrecios} cantidadAfiliados={cantidadAfiliados} token={token}></SelectAfiliados>
      <SelectAfiliados index={9} listaDePrecios={listaDePrecios} cantidadAfiliados={cantidadAfiliados} token={token}></SelectAfiliados>
      <SelectAfiliados index={10} listaDePrecios={listaDePrecios} cantidadAfiliados={cantidadAfiliados} token={token}></SelectAfiliados>
    </React.StrictMode>
  );
  MostrarAfiliados();
}
const CambioTipoVenta = (tipoDeVenta, token) => {
  var contenedorCategorias = ReactDOM.createRoot(document.getElementById('divCategorias'));
  contenedorCategorias.render(
    <React.StrictMode>
      <ObtenerCategorias tipoDeVenta={tipoDeVenta} token={token} />
    </React.StrictMode>
  );
  var contenedorObrasSociales = ReactDOM.createRoot(document.getElementById('divObrasSociales'));
  contenedorObrasSociales.render(
    <React.StrictMode>
      <ObtenerObrasSociales token={token} />
    </React.StrictMode>
  );
}
const CambioCategoria = (categoriaDeVenta, token) => {
  const contenedor = ReactDOM.createRoot(document.getElementById('divObrasSociales'));
  contenedor.render(
    <React.StrictMode>
      <ObtenerObrasSociales categoriaDeVenta={categoriaDeVenta} token={token} />
    </React.StrictMode>
  );
}
const CambioPlan = (plan, idControlPlan, token) => {
  if (!plan) {
    plan = 0;
  }

  let numeroAfiliado = idControlPlan.substring(4);
  let idControl = "rangoEtario" + idControlPlan.substring(4);
  let listaDePrecios = document.getElementsByName("lista_de_precios")[0].value


  const contenedor = ReactDOM.createRoot(document.getElementById("divRangoEtario" + numeroAfiliado));
  //const ObtenerRangos=({listaDePrecios, plan, idControl})=>{
  contenedor.render(
    <React.StrictMode>
      <ObtenerRangos listaDePrecios={listaDePrecios} plan={plan} idControl={idControl} token={token} />
    </React.StrictMode>
  );
}

function agregarBeneficio() {
  let bonificacion = $("#divBonificaciones .css-1cza3nx-control .css-oglk1b-singleValue").text();
  let bonificacionID = $("[name='bonificaciones']").val();
  let observacion = $("#observacion").val();
  let filas = $("#tablaBeneficiarios tbody tr");
  var encontrado = false;
  let boton = '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">  <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6Z"/>  <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z"/></svg>';
  filas.find('td').each(function (index, element) {
    if (element.innerText.toString() === bonificacion.toString()) {
      Swal.fire({
        icon: "error",
        text: "No puede cargar 2 veces la misma bonificación.",
        width: 500,
        height: 200,
      });
      encontrado = true;
    }
  });
  if(bonificacionID !== ""){
    if (encontrado === false ) {
      var nvaFila = '<tr id="' + bonificacion + '">   <td id = ' + bonificacionID + '>' + bonificacion + '</td>  <td>' + observacion + '</td><td><button type="button" class="eliminar">' + boton + '</button></td></tr>'
      $("#tablaBeneficiarios tbody").append(nvaFila);
      /*document.getElementById(bonificacion).addEventListener("click", function () {
        eliminarBeneficio(bonificacion);
      });*/
      $("#tablaBeneficiarios").on("click", ".eliminar", function () {
        $(this).closest("tr").remove();
      });
      $("#observacion").val("");
      $("#divBonificaciones .css-1cza3nx-control .css-oglk1b-singleValue").text("");
    }
  }else{
    Swal.fire({
      icon: "error",
      text: "La bonificación y/o la observacion no puede quedar vacia",
      width: 500,
      height: 200,
    });
  }
  
}
class BotonBonificaciones extends Component {
  componentDidMount() {
    const botonBeneficiario = document.getElementById('boton_nvo_beneficio');
    botonBeneficiario.addEventListener('click', this.handleClick);
  }
  handleClick() {
    agregarBeneficio();
  }

  render() {
    return (
      <div className="row g-2 form-floating col-md-2">
        <button id="boton_nvo_beneficio" type="button" className="btn btn-met float-end">Agregar Bonificación</button>
      </div>

    );
  }
}
class BotonCotizar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: props.token,
      idUsuario: props.idUsuario,
    };
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    const botonCotizar = document.getElementById('boton_cotizar');
    botonCotizar.addEventListener('click', this.handleClick);
  }
  handleClick = () => {
    cotizar(this.props.idUsuario, this.props.token);
  }

  render() {
    return (
      <div className="form-floating mb-3 col-md-2" id="div_boton">
        <button id="boton_cotizar" type="button" className="btn btn-met float-end">Cotizar</button>
      </div>
    );
  }
}
class Cotizador extends Component{
  constructor(props){
    super(props);
    this.state={
      token: this.props.token,
      usuarioId: this.props.usuarioId,
      usuarioNombre: this.props.usuarioNombre,
      sueldo: "",
      celular:"",
    }
  }
  handleSueldoChange = (newValue) => {
    var sueldo = String(newValue).replace(/[^0-9]/g, '');
    this.setState({ sueldo: sueldo });
  }
  handleCelularChange = (newValue) => {
    var celular = String(newValue).replace(/[^0-9]/g, '');
    this.setState({ celular: celular });
  }
  componentDidMount(){
    console.log(this.state.usuarioId)
  }
  
  render(){
  return (
    <div >
      <br></br>
      <form className="row g-3" style={{ backgroundColor: "#f0f0f0" }}>

        <div className="row g-3 col-sm-12 mb-3 mb-sm-0 filaAsesor" >
          <H texto={"Nueva Cotización"} tamano={3}></H>
        </div>
        <br></br>
        <div className="card row g-3 col-sm-12 mb-3 mb-sm-0" >
          <div className="row g-3">
            <H texto="Datos del titular" tamano={5}></H>
            <Input tamano={3} type="text" value="" id="nombre" placeholder="Nombre" name="nombre_titular"></Input>
            <Input tamano={3} type="text" value="" id="apellido" placeholder="Apellido" name="apellido_titular"></Input>
            <Input tamano={3} type="text" value="" id="documento" placeholder="Documento" name="documento"></Input>
            <FormInputEditable
                tamano={3}
                type="text"
                id="celular"
                value={this.state.celular}
                placeholder="Celular"
                name="celular"
                readonly={"false"}
                onChange={this.handleCelularChange}
              />
          </div>
          <div className="row g-3">
            <div name="divProvincias" id="divProvincias" className="col-md-3">
              <ObtenerProvincias token={this.state.token} />
            </div>
            <div name="divLocalidades" id="divLocalidades" className="col-md-3">
              <ObtenerLocalidades token={this.state.token} provincia="0" />
            </div>
          </div>

          <br></br>
        </div>
        <div className="card row g-3 col-sm-12 mb-3 mb-sm-0" >
          <div className="row g-3">
            <H texto="Datos generales" tamano={5}></H>
            <div name="divListasDePrecios" id="divListasDePrecios" className="col-md-3">
              <ObtenerListaPrecios token={this.state.token} />
            </div>
            <div name="divTiposDeVenta" id="divTiposDeVenta" className="col-md-3">
              <ObtenerTiposVenta token={this.state.token} />
            </div>
            <div name="divCategorias" id="divCategorias" className="col-md-3">
              <ObtenerCategorias token={this.state.token} />
            </div>
            <div name="divObrasSociales" id="divObrasSociales" className="col-md-3">
              <ObtenerObrasSociales token={this.state.token} />
            </div>
          </div>

          <div className="row g-3">
              <FormInputEditable
                tamano={3}
                type="decimal"
                id="sueldo"
                value={this.state.sueldo}
                placeholder="Sueldo"
                name="sueldo"
                readonly={"false"}
                onChange={this.handleSueldoChange}
              />
            <ObtenerCantidadAfiliados />
          </div>

          <br></br>
        </div>
        <div className="card row g-3 col-sm-12 mb-3 mb-sm-0" >
          <H texto={"Planes"} tamano={5}></H>
          <div className="row g-3" id="divAfiliados" name="divAfiliados">

            <SelectAfiliados index={1} cantidadAfiliados={1} token={this.state.token}></SelectAfiliados>
            <SelectAfiliados index={2} cantidadAfiliados={1} token={this.state.token}></SelectAfiliados>
            <SelectAfiliados index={3} cantidadAfiliados={1} token={this.state.token}></SelectAfiliados>
            <SelectAfiliados index={4} cantidadAfiliados={1} token={this.state.token}></SelectAfiliados>
            <SelectAfiliados index={5} cantidadAfiliados={1} token={this.state.token}></SelectAfiliados>
            <SelectAfiliados index={6} cantidadAfiliados={1} token={this.state.token}></SelectAfiliados>
            <SelectAfiliados index={7} cantidadAfiliados={1} token={this.state.token}></SelectAfiliados>
            <SelectAfiliados index={8} cantidadAfiliados={1} token={this.state.token}></SelectAfiliados>
            <SelectAfiliados index={9} cantidadAfiliados={1} token={this.state.token}></SelectAfiliados>
            <SelectAfiliados index={10} cantidadAfiliados={1} token={this.state.token}></SelectAfiliados>
          </div>
          <br></br>

        </div>
        <div className="card row g-3 col-sm-12 mb-3 mb-sm-0" >
          <H texto={"Bonificaciones"} tamano={5}></H>
          <div className="row g-3" id="divBonificaciones" name="divBonificaciones">
            <div className="form-floating col-md-3">
              <ObtenerBonificaciones token={this.state.token} />
            </div>
            <Input tamano={4} type="text" id="observacion" placeholder="Observaciones" name="observacion" />
            <div className="form-floating mb-3 col-md-1"></div>
            <BotonBonificaciones />
            <div className="form-floating col-md-7">
              <table className="table table-striped" id="tablaBeneficiarios">
                <thead>
                  <tr>
                    <th scope="col">Bonificación</th>
                    <th scope="col">Observaciones</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                </tbody>
              </table>
            </div>
          </div>
          <br></br>

        </div>
        <div className="card row g-3 col-sm-12 mb-3 mb-sm-0" >
          <div className="row g-3" >
            <div className="form-floating mb-3 col-md-10"></div>
            <BotonCotizar idUsuario={this.state.usuarioId} token={this.state.token} />
          </div>
        </div>
      </form>
    </div>
  )}};
  
export default Cotizador;