// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 30 30%27%3e%3cpath stroke=%27rgba%28,255, 255, 255, 1%29%27 stroke-linecap=%27round%27 stroke-miterlimit=%2713%27 stroke-width=%274%27 d=%27M4 7h22M4 15h22M4 23h22%27/%3e%3c/svg%3e", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.titulo{
    background-color:#009B7C !important;
    margin: 0px 0px 0px 0px;
}
.titulo-texto a{
    color: white;
    font-size: large;
}
.titulo-texto a:hover{
    color: #005040;
}

.navbar-toggler-icon {
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) !important;
}
div.dropdown-menu.show {
    background-color: #009B7C !important;
}

div.dropdown-menu.show {
    background-color: #1eae92 !important;
}

a.dropdown-toggle.show.nav-link{
    color: #005040;
}
a.dropdown-toggle.nav-link{
    color: white;
}
`, "",{"version":3,"sources":["webpack://./src/styles/Menu.css"],"names":[],"mappings":"AAAA;IACI,mCAAmC;IACnC,uBAAuB;AAC3B;AACA;IACI,YAAY;IACZ,gBAAgB;AACpB;AACA;IACI,cAAc;AAClB;;AAEA;IACI,oEAAwS;AAC5S;AACA;IACI,oCAAoC;AACxC;;AAEA;IACI,oCAAoC;AACxC;;AAEA;IACI,cAAc;AAClB;AACA;IACI,YAAY;AAChB","sourcesContent":[".titulo{\r\n    background-color:#009B7C !important;\r\n    margin: 0px 0px 0px 0px;\r\n}\r\n.titulo-texto a{\r\n    color: white;\r\n    font-size: large;\r\n}\r\n.titulo-texto a:hover{\r\n    color: #005040;\r\n}\r\n\r\n.navbar-toggler-icon {\r\n    background-image: url(\"data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 30 30%27%3e%3cpath stroke=%27rgba%28,255, 255, 255, 1%29%27 stroke-linecap=%27round%27 stroke-miterlimit=%2713%27 stroke-width=%274%27 d=%27M4 7h22M4 15h22M4 23h22%27/%3e%3c/svg%3e\") !important;\r\n}\r\ndiv.dropdown-menu.show {\r\n    background-color: #009B7C !important;\r\n}\r\n\r\ndiv.dropdown-menu.show {\r\n    background-color: #1eae92 !important;\r\n}\r\n\r\na.dropdown-toggle.show.nav-link{\r\n    color: #005040;\r\n}\r\na.dropdown-toggle.nav-link{\r\n    color: white;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
