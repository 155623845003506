import React from "react";
const Head = () => {
  return (
    <div>
      <br></br>
      <center>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="158.41"
          height="67"
          viewBox="0 0 158.41 67"
        >
          <g
            id="Grupo_64"
            data-name="Grupo 64"
            transform="translate(-152.449 -196.078)"
          >
            <rect
              id="Rectángulo_67"
              data-name="Rectángulo 67"
              width="158.41"
              height="67"
              transform="translate(152.449 196.078)"
              fill="#009b7c"
            />
            <g
              id="Grupo_63"
              data-name="Grupo 63"
              transform="translate(181.449 210.078)"
            >
              <g
                id="Grupo_61"
                data-name="Grupo 61"
                transform="translate(0.009 31.552)"
              >
                <path
                  id="Trazado_47"
                  data-name="Trazado 47"
                  d="M195.088,304.814h1.319l2.288,6.1,2.293-6.1h1.327v7.256H201.4v-6.04h-.015l-2.272,6.04h-.824l-2.257-6.04H196v6.04h-.917Z"
                  transform="translate(-195.088 -304.646)"
                  fill="#fff"
                />
                <path
                  id="Trazado_48"
                  data-name="Trazado 48"
                  d="M211.408,304.814h5.009v.82H212.37v2.3h3.762v.813H212.37v2.511h4.079v.813h-5.041Z"
                  transform="translate(-202.124 -304.646)"
                  fill="#fff"
                />
                <path
                  id="Trazado_49"
                  data-name="Trazado 49"
                  d="M223.117,304.814h2.49c2.228,0,3.485,1.12,3.485,3.454,0,2.427-1.072,3.8-3.485,3.8h-2.49Zm.966,6.443h1.612c.655,0,2.431-.187,2.431-2.857,0-1.721-.633-2.766-2.421-2.766h-1.621Z"
                  transform="translate(-207.173 -304.646)"
                  fill="#fff"
                />
                <path
                  id="Trazado_50"
                  data-name="Trazado 50"
                  d="M236.554,304.814h.971v7.256h-.971Z"
                  transform="translate(-212.966 -304.646)"
                  fill="#fff"
                />
                <path
                  id="Trazado_51"
                  data-name="Trazado 51"
                  d="M246.739,306.877a1.91,1.91,0,0,0-2.028-1.554c-1.723,0-2.456,1.431-2.456,2.939,0,1.655.733,3.042,2.467,3.042a2.068,2.068,0,0,0,2.094-2.1h.964a2.961,2.961,0,0,1-3.142,2.915c-2.26,0-3.356-1.675-3.356-3.783s1.205-3.816,3.441-3.816a2.777,2.777,0,0,1,2.989,2.359Z"
                  transform="translate(-215.004 -304.518)"
                  fill="#fff"
                />
                <path
                  id="Trazado_52"
                  data-name="Trazado 52"
                  d="M255.682,304.814h.967v7.256h-.967Z"
                  transform="translate(-221.213 -304.646)"
                  fill="#fff"
                />
                <path
                  id="Trazado_53"
                  data-name="Trazado 53"
                  d="M261.025,304.814h1.02l3.823,5.88h.027v-5.88h.905v7.256h-1.06l-3.781-5.821h-.028v5.821h-.907Z"
                  transform="translate(-223.516 -304.646)"
                  fill="#fff"
                />
                <path
                  id="Trazado_54"
                  data-name="Trazado 54"
                  d="M276.051,304.814h1.072l2.834,7.256h-1.065l-.791-2.187h-3.074l-.817,2.187h-.985Zm-.721,4.262h2.459l-1.209-3.387h-.016Z"
                  transform="translate(-228.777 -304.646)"
                  fill="#fff"
                />
                <path
                  id="Trazado_55"
                  data-name="Trazado 55"
                  d="M292.878,304.814h3.177a2.147,2.147,0,1,1,0,4.29h-2.215v2.965h-.961Zm.961,3.475h1.887c1.09.013,1.588-.46,1.588-1.331s-.5-1.325-1.588-1.325h-1.887Z"
                  transform="translate(-237.25 -304.646)"
                  fill="#fff"
                />
                <path
                  id="Trazado_56"
                  data-name="Trazado 56"
                  d="M305.26,304.814h3.408c1.362,0,2.213.745,2.213,1.9a1.75,1.75,0,0,1-1.269,1.839v.02c.841.158,1.022.785,1.088,1.487a3.59,3.59,0,0,0,.436,2.007h-1.085c-.27-.293-.123-1.073-.269-1.79-.1-.709-.267-1.313-1.209-1.313h-2.352v3.1h-.963Zm2.975,3.343c.9,0,1.668-.234,1.668-1.289a1.141,1.141,0,0,0-1.288-1.234h-2.393v2.523Z"
                  transform="translate(-242.588 -304.646)"
                  fill="#fff"
                />
                <path
                  id="Trazado_57"
                  data-name="Trazado 57"
                  d="M318.35,304.814h.965v7.256h-.965Z"
                  transform="translate(-248.232 -304.646)"
                  fill="#fff"
                />
                <path
                  id="Trazado_58"
                  data-name="Trazado 58"
                  d="M325.947,312.07h-1.083l-2.569-7.256h1.045l2.073,6.292h.02l2.095-6.292h1.005Z"
                  transform="translate(-249.933 -304.646)"
                  fill="#fff"
                />
                <path
                  id="Trazado_59"
                  data-name="Trazado 59"
                  d="M336.738,304.814h1.068l2.841,7.256h-1.075l-.791-2.187h-3.072l-.812,2.187h-.992Zm-.719,4.262h2.463l-1.215-3.387h-.02Z"
                  transform="translate(-254.939 -304.646)"
                  fill="#fff"
                />
                <path
                  id="Trazado_60"
                  data-name="Trazado 60"
                  d="M347.782,304.814h2.495c2.233,0,3.481,1.12,3.481,3.454,0,2.427-1.059,3.8-3.481,3.8h-2.495Zm.969,6.443h1.608c.66,0,2.438-.187,2.438-2.857,0-1.721-.637-2.766-2.417-2.766h-1.629Z"
                  transform="translate(-260.921 -304.646)"
                  fill="#fff"
                />
                <path
                  id="Trazado_61"
                  data-name="Trazado 61"
                  d="M362.484,304.814h1.071l2.827,7.256h-1.053l-.8-2.187h-3.07l-.811,2.187h-.993Zm-.724,4.262h2.467l-1.217-3.387h-.017Z"
                  transform="translate(-266.038 -304.646)"
                  fill="#fff"
                />
              </g>
              <g id="Grupo_62" data-name="Grupo 62">
                <path
                  id="Trazado_62"
                  data-name="Trazado 62"
                  d="M195.072,249.053h10.623l8.027,17.7h.1l7.6-17.7h10.623V274.79h-7.065V256.545h-.092l-8.418,18.245h-5.819l-8.417-18.063h-.094V274.79h-7.061Z"
                  transform="translate(-195.072 -249.053)"
                  fill="#fff"
                />
                <path
                  id="Trazado_63"
                  data-name="Trazado 63"
                  d="M272.043,249.053H297.72v4.752H279.6v5.517h16.632v4.4H279.6v6.309H298.1v4.754h-26.06Z"
                  transform="translate(-228.258 -249.053)"
                  fill="#fff"
                />
                <path
                  id="Trazado_64"
                  data-name="Trazado 64"
                  d="M332.7,253.805H322.406v-4.752h28.131v4.752H340.249V274.79H332.7Z"
                  transform="translate(-249.971 -249.053)"
                  fill="#fff"
                />
              </g>
            </g>
          </g>
        </svg>
      </center>
    </div>
  );
};
export default Head;
