import { BrowserRouter ,Routes, Route } from "react-router-dom";
import Login from './Login';
import Home from "./Home";
import RecuperarContraseña from "./RecuperarContraseña";

function App(){
  return(
      <BrowserRouter>
        <Routes>
          <Route path="/" element={ <Login/>} />
          <Route path="/Home" element={ <Home/>}/>
          <Route path="/Recuperar/:token" element={ <RecuperarContraseña/>}/>
        </Routes>
      </BrowserRouter>
  )
}

export default App;
