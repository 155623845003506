import React from "react";
import $ from 'jquery';
import ReactDOM from 'react-dom/client';
import { FormBonificacionesGenerales } from "./abm/BonificacionesGenerales";
import { FormListaPrecio } from "./abm/ListaPrecios";
import "../styles/Menu.css";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Cotizador from "./Cotizador";
import Form from "./Login";
import { root, url } from "../utils/Globals";
import { FormCategorias } from "./abm/Categorias";
import { FormObrasSociales } from "./abm/ObraSocial";
import { FormPlanes } from "./abm/Planes";
import { FormRangosEtarios } from "./abm/RangosEtarios";
import { Cotizaciones } from "./abm/Cotizaciones";
import { FormPrecios } from "./abm/Precios";
import { FormBonificacionesPorPlan } from "./abm/BonificacionesPorPlan";
import { FormCantidadesParaBonificacion } from "./abm/CantidadParaBonificacion";
import { FromDescuentosEstimados } from "./abm/DescuentosEstimados";
import { FormUsuarios } from "./abm/Usuarios";
import DivHome from "./DivHome";
//import { MiPerfil } from "./abm/MiPerfil";
import { FormTiposDeVenta } from "./abm/TiposDeVenta";
import Swal from 'sweetalert2'
import { H, BotonCancelar, FormInput, FormInputEditable } from "../utils/Controles";
//info
import { Alert } from "@mui/material";
import Cotizador2 from "./Cotizador2";
import { Reportes } from "./abm/Reportes";
import { FormCombinacionPorPlan } from "./abm/CombinacionPorPlan";
import { FormCombinacionPorBonificacion } from "./abm/CombinacionPorCombinacion";

var propsHome;
var errorStyles = {
    'border-color': '#f56565',
    'color': '#2a2222',
    'border-width': '3px',
};
const salir = () => {
    root.render(
        <React.StrictMode>
            <Form />
        </React.StrictMode>
    );
}
const mostrarCotizador = () => {
    $("#content").show();
    $("#miPerfil").hide();
    const content = ReactDOM.createRoot(document.getElementById('content'));
    content.render(
        <React.StrictMode>
            <Cotizador token={propsHome.token} usuarioId={propsHome.usuarioId} usuarioNombre={propsHome.usuarioNombre} />
        </React.StrictMode>
    );
}
const mostrarCotizador2 = () => {
    $("#content").show();
    $("#miPerfil").hide();
    let json = {

    }
    const content = ReactDOM.createRoot(document.getElementById('content'));
    content.render(
        <React.StrictMode>
            <Cotizador2 token={propsHome.token} usuarioId={propsHome.usuarioId} usuarioNombre={propsHome.usuarioNombre} json={json} />
        </React.StrictMode>
    );
}
const mostrarUsuarios = () => {
    $("#content").show();
    $("#miPerfil").hide();
    const content = ReactDOM.createRoot(document.getElementById('content'));
    content.render(
        <React.StrictMode>
            <FormUsuarios token={propsHome.token} />
        </React.StrictMode>
    );
}
const mostrarTipoVenta = () => {
    $("#content").show();
    $("#miPerfil").hide();

    const content = ReactDOM.createRoot(document.getElementById('content'));
    content.render(
        <React.StrictMode>
            <FormTiposDeVenta token={propsHome.token} />
        </React.StrictMode>
    );
}
const mostrarMiPerfil = () => {
    /*
    const content = ReactDOM.createRoot(document.getElementById('content'));
    content.render(
        <React.StrictMode>
            <MiPerfil token={propsHome.token} id={propsHome.usuarioId} />
        </React.StrictMode>
    );*/
    $("#content").hide();
    $("#miPerfil").show();
}
const mostrarDescuentosEstimados = () => {
    $("#content").show();
    $("#miPerfil").hide();
    const content = ReactDOM.createRoot(document.getElementById('content'));
    content.render(
        <React.StrictMode>
            <FromDescuentosEstimados token={propsHome.token} />
        </React.StrictMode>
    );
}
const mostrarCantidadParaBonficacion = () => {
    $("#content").show();
    $("#miPerfil").hide();

    const content = ReactDOM.createRoot(document.getElementById('content'));
    content.render(
        <React.StrictMode>
            <FormCantidadesParaBonificacion token={propsHome.token} />
        </React.StrictMode>
    );
}
const mostrarPrecios = () => {
    $("#content").show();
    $("#miPerfil").hide();

    const content = ReactDOM.createRoot(document.getElementById('content'));
    content.render(
        <React.StrictMode>
            <FormPrecios token={propsHome.token} />
        </React.StrictMode>
    );
}
const mostrarBonificaciones = () => {
    $("#content").show();
    $("#miPerfil").hide();

    const content = ReactDOM.createRoot(document.getElementById('content'));
    content.render(
        <React.StrictMode>
            <FormBonificacionesGenerales token={propsHome.token} />
        </React.StrictMode>
    );
}
const mostrarBonificacionesPorPlan = () => {
    $("#content").show();
    $("#miPerfil").hide();

    const content = ReactDOM.createRoot(document.getElementById('content'));
    content.render(
        <React.StrictMode>
            <FormBonificacionesPorPlan token={propsHome.token} />
        </React.StrictMode>
    );
}
const mostrarListasPrecio = () => {
    $("#content").show();
    $("#miPerfil").hide();

    const content = ReactDOM.createRoot(document.getElementById('content'));
    content.render(
        <React.StrictMode>
            <FormListaPrecio token={propsHome.token} />
        </React.StrictMode>
    );
}
const mostrarCategorias = () => {
    $("#content").show();
    $("#miPerfil").hide();

    const content = ReactDOM.createRoot(document.getElementById('content'));
    content.render(
        <React.StrictMode>
            <FormCategorias token={propsHome.token} />
        </React.StrictMode>
    );
}
const mostrarObrasSociales = () => {
    $("#content").show();
    $("#miPerfil").hide();

    const content = ReactDOM.createRoot(document.getElementById('content'));
    content.render(
        <React.StrictMode>
            <FormObrasSociales token={propsHome.token} />
        </React.StrictMode>
    );
}
const mostrarPlanes = () => {
    $("#content").show();
    $("#miPerfil").hide();

    const content = ReactDOM.createRoot(document.getElementById('content'));
    content.render(
        <React.StrictMode>
            <FormPlanes token={propsHome.token} />
        </React.StrictMode>
    );

}
const mostrarRangosEtarios = () => {
    $("#content").show();
    $("#miPerfil").hide();

    const content = ReactDOM.createRoot(document.getElementById('content'));
    content.render(
        <React.StrictMode>
            <FormRangosEtarios token={propsHome.token} />
        </React.StrictMode>
    );
}
const mostrarCotizaciones = (origen) => {
    $("#content").show();
    $("#miPerfil").hide();

    const content = ReactDOM.createRoot(document.getElementById('content'));
    content.render(
        <React.StrictMode>
            <Cotizaciones token={propsHome.token} usuarioId={propsHome.usuarioId} origen={origen} />
        </React.StrictMode>
    );
}
const mostrarHome = () => {
    const content = document.getElementById('content');
    if (content) {
        ReactDOM.createRoot(content).render(
            <React.StrictMode>
                <DivHome />
            </React.StrictMode>
        );
    } else {
        console.error("Element with ID 'content' not found.");
    }
};
const mostrarReportes = () => {
    const content = ReactDOM.createRoot(document.getElementById('content'));
    content.render(
        <React.StrictMode>
            <Reportes/>
        </React.StrictMode>
    );
};
const mostrarCombinacionesPorPlan = () => {
    const content = ReactDOM.createRoot(document.getElementById('content'));
    content.render(
        <React.StrictMode>
            <FormCombinacionPorPlan token={propsHome.token}/>
        </React.StrictMode>
    );
};
const mostrarCombinacionesPorBonificacion = () => {
    const content = ReactDOM.createRoot(document.getElementById('content'));
    content.render(
        <React.StrictMode>
            <FormCombinacionPorBonificacion token={propsHome.token}/>
        </React.StrictMode>
    );
};
class Home extends React.Component {
    constructor(props) {
        super(props);
        propsHome = props;
        this.state = {
            isAdmin: propsHome.perfil && propsHome.perfil.includes("Administrador"),
            token: propsHome.token,
            id: propsHome.usuarioId,
            data: [],
            nombre: propsHome.usuarioNombre,
            nombretemp: "",
            email: "",
            emailtemp: "",
            contraseña: "",
            repetirContraseña: "",
            puedeActualizar: true,
            cotizador2: propsHome.usuarioNombre === 'Cintia Gonzalez' || 
                        propsHome.usuarioNombre === 'DI DIO ENZO' || 
                        propsHome.usuarioNombre === 'Cintia Gonzalez' ||
                        propsHome.usuarioNombre === 'BURNI MELISA' ||
                        propsHome.usuarioNombre === 'GUELL ESTEFANIA' ||
                        propsHome.usuarioNombre === 'Usuario MET' ||
                        propsHome.usuarioNombre === 'FANDIÑO FRANCO NICOLAS' 
        };
    }
    componentDidMount() {
        mostrarHome();
        $(document).off("keypress", "input");
        this.fetch();
        $("#miPerfil").hide();
        const botonGuardar = document.getElementById('boton_guardar');
        botonGuardar.addEventListener('click', this.handleActualizarClick);
        const botonCancelar = document.getElementById('boton_cancelar');
        botonCancelar.addEventListener('click', () => {
            $("#content").show();
            $("#miPerfil").hide();
            $("#contrasena").val("")
            $("#repetirContraseña").val("")
            this.limpiarCampo("#nombre")
            this.limpiarCampo("#email")
            this.limpiarCampo("#contrasena")
            this.limpiarCampo("#repetirContraseña")
            this.setState({ nombretemp: this.state.nombre, emailtemp: this.state.email, puedeActualizar: true })
            
            /*const content = ReactDOM.createRoot(document.getElementById('content'));
            content.render(
                <React.StrictMode>
                    <DivHome />
                </React.StrictMode>
            );*/
        });
    }
    limpiarCampo = (campo) => {
        $(campo).removeAttr('style');
        $(campo).addClass('form-control');
    }
    validar = () => {
        let respuesta = true;
        var campos = [
            { nombre: this.state.nombretemp, selector: "#nombre", tipo: "id" },
            { nombre: this.state.emailtemp, selector: "#email", tipo: "id" },

        ];
        campos.forEach(function (campo) {
            if (campo.nombre === "" || campo.nombre === null || campo.nombre === undefined) {
                try {
                    var elementos = document.querySelectorAll(campo.selector);
                    elementos.forEach(function (elemento) {
                        $(elemento).css(errorStyles);
                    })
                } catch {
                    console.log(campo.selector);
                }
                if (campo.tipo === "id") {
                    document.getElementById(campo.selector.substring(1)).focus();
                    document.getElementById(campo.selector.substring(1)).select();
                }
                respuesta = false;
            } else {
                $(campo.selector).removeAttr('style');
                $(campo.selector).addClass('form-control');
            }
        });
        return respuesta;
    }
    handleNombreChange = (newValue) => {
        this.setState({ nombretemp: newValue });
    }
    handleEmailChange = (newValue) => {
        this.setState({ emailtemp: newValue });
    }
    handleActualizarClick = async () => {
        this.limpiarCampo("#nombre")
        this.limpiarCampo("#email")
        this.limpiarCampo("#contrasena")
        this.limpiarCampo("#repetirContraseña")

        let validacion = this.validar();
        if (validacion) {
            await this.actualizar();
        } else {
            Swal.fire({
                icon: "error",
                text: "Para continuar debe completar todos los campos.",
                width: 500,
                height: 200,
            });
        }

    }
    validarContraseña = (contraseña, repetirContraseña) => {
        var errorStyles = {
            'border-color': '#f56565',
            'color': '#2a2222',
            'border-width': '3px',
        };

        let resultadoContraseña = contraseña.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\w\W]{8,}$/gm);
        let igual = contraseña === repetirContraseña;

        var puede;
        if (igual) {
            if (resultadoContraseña) {
                puede = true;
            } else {
                Swal.fire({
                    icon: "error",
                    text: "La contraseña debe poseer al menos una letra minúscula, una mayúscula, un número y un mínimo de 8 carácteres.",
                    width: 500,
                    height: 200,
                });
                var elementos = document.querySelectorAll("#contrasena");
                elementos.forEach(function (elemento) {
                    $(elemento).css(errorStyles);
                })
                puede = false;
            }
        } else {
            Swal.fire({
                icon: "error",
                text: "Las contraseñas no coinciden.",
                width: 500,
                height: 200,
            });
            elementos = document.querySelectorAll("#repetirContraseña");
            elementos.forEach(function (elemento) {
                $(elemento).css(errorStyles);
            })
            puede = false;
        }
        this.setState({ puedeActualizar: puede });
        return puede;
    }
    actualizar = async () => {
        await this.setState({ puedeActualizar: true });
        var contraseña = $("#contrasena").val().trim();
        var repetirContraseña = $("#repetirContraseña").val().trim();

        var Data;
        var respuestaJson;

        if (contraseña !== "" || repetirContraseña !== "") {
            var validar = await this.validarContraseña(contraseña, repetirContraseña);
            if (validar) {
                Data = {
                    nombre: `${this.state.nombretemp}`,
                    email: `${this.state.emailtemp}`,
                    contraseña: `${contraseña}`,
                };
            }
        } else {
            Data = {
                nombre: `${this.state.nombretemp}`,
                email: `${this.state.emailtemp}`,
            };
        }
        if (this.state.puedeActualizar === true) {
            try {
                const headers = {
                    'x-access-token': `${this.state.token}`,
                    "Content-Type": "application/json"
                };
                var error;
                fetch(url + "usuarios/" + this.state.id, {
                    method: 'PUT',
                    body: JSON.stringify(Data),
                    headers
                }).then(response => {
                    if (!response.ok) {
                        console.log("Error en la petición");
                        respuestaJson = response.json();
                    }
                    else {
                        respuestaJson = response.json();
                        this.setState({ nombre: this.state.nombretemp, email: this.state.emailtemp, contraseña: "", repetirContraseña: "" });
                        $("#contrasena").val("")
                        $("#repetirContraseña").val("")
                        $("#miPerfil").hide();
                        $("#content").show();
                        Swal.fire({
                            icon: "success",
                            title: "Perfil actualizado",
                            timer: 2000,
                            text: "Sus datos han sido guardados correctamente.",
                        });
                    }
                    return respuestaJson;
                }).then(respuestaJson => {
                    if (respuestaJson.mensaje === "Valor inválido") {

                        var alertMessage = "Los valores de los siguientes campos no son válidos: \n";

                        for (var key in respuestaJson.errores) {
                            if (respuestaJson.errores.hasOwnProperty(key)) {
                                alertMessage += "-" + key + ": " + respuestaJson.errores[key] + "\n";
                            }
                        }
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: alertMessage,
                        });
                    }
                })
                    .catch(err => {
                        console.log(error)
                        console.error('Error al enviar la petición POST:', err);
                    });
            } catch (error) {
                console.error('Error al obtener los datos:', error);
            }

        }
    }
    fetch = async () => {
        try {
            const headers = {
                'x-access-token': `${this.state.token}`,
            };
            const response = await fetch(url + "usuarios/" + this.state.id, { headers });
            let responseData = await response.json();
            this.setState({ data: responseData }, () => { });
            this.setState({ nombre: responseData.nombre, nombretemp: responseData.nombre, email: responseData.email, emailtemp: responseData.email });
        } catch (error) {
            console.error('Error al obtener los datos:', error);
        }
    }
    render() {
        return (
            <div>
                <div id="menu">
                    <Navbar collapseOnSelect expand="lg" className="titulo" style={{ color: "white" }}>
                        <Container>
                            <Navbar.Brand href="" onClick={mostrarHome}><img alt="MET" style={{ height: "4rem" }} src={require('./logoMET.png')} /></Navbar.Brand>
                            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                            <Navbar.Collapse id="responsive-navbar-nav">
                                <Nav className="me-auto">
                                    <NavDropdown title="Cotizaciones" className="titulo-texto">
                                        <NavDropdown.Item href="" onClick={mostrarCotizador}>Nueva cotización</NavDropdown.Item>
                                        {this.state.cotizador2 && (<NavDropdown.Item href="" onClick={mostrarCotizador2}>Nueva cotización 2</NavDropdown.Item>)}
                                        <NavDropdown.Item href="" onClick={() => mostrarCotizaciones("usuario")}>Mis cotizaciones</NavDropdown.Item>
                                    </NavDropdown>
                                    {this.state.isAdmin && (
                                        <>
                                            <NavDropdown title="Administración" className="titulo-texto">
                                                <NavDropdown.Item href="" onClick={mostrarBonificaciones}>Bonificaciones generales</NavDropdown.Item>
                                                <NavDropdown.Item href="" onClick={mostrarBonificacionesPorPlan}>Bonificaciones por plan</NavDropdown.Item>
                                                <NavDropdown.Item href="" onClick={mostrarCantidadParaBonficacion}>Cantidades para bonificación</NavDropdown.Item>
                                                <NavDropdown.Item href="" onClick={mostrarCategorias}>Categorías de venta</NavDropdown.Item>
                                                <NavDropdown.Item href="" onClick={mostrarCombinacionesPorPlan}>Combinaciones por plan</NavDropdown.Item>
                                                <NavDropdown.Item href="" onClick={mostrarCombinacionesPorBonificacion}>Combinaciones por bonificación</NavDropdown.Item>
                                                <NavDropdown.Item href="" onClick={() => mostrarCotizaciones("todas")}>Todas las Cotizaciones</NavDropdown.Item>
                                                <NavDropdown.Item href="" onClick={mostrarDescuentosEstimados}>Descuentos estimados</NavDropdown.Item>
                                                <NavDropdown.Item href="" onClick={mostrarListasPrecio}>Listas de precios</NavDropdown.Item>
                                                <NavDropdown.Item href="" onClick={mostrarObrasSociales}>Obras sociales</NavDropdown.Item>
                                                <NavDropdown.Item href="" onClick={mostrarPlanes}>Planes</NavDropdown.Item>
                                                <NavDropdown.Item href="" onClick={mostrarPrecios}>Precios</NavDropdown.Item>
                                                <NavDropdown.Item href="" onClick={mostrarRangosEtarios}>Rangos etarios</NavDropdown.Item>
                                                <NavDropdown.Item href="" onClick={mostrarTipoVenta}>Tipos de venta</NavDropdown.Item>
                                                <NavDropdown.Item href="" onClick={mostrarUsuarios}>Usuarios</NavDropdown.Item>
                                            </NavDropdown>
                                            <NavDropdown title={"Reportes"} className="titulo-texto">
                                                <NavDropdown.Item href="" onClick={mostrarReportes}>Power Bi</NavDropdown.Item>
                                            </NavDropdown>
                                        </>
                                    )}
                                </Nav>
                                <Nav>
                                    <NavDropdown title={this.state.nombre} className="titulo-texto">
                                        <NavDropdown.Item href="" onClick={mostrarMiPerfil}>Mi perfil</NavDropdown.Item>
                                        <NavDropdown.Divider />
                                        <NavDropdown.Item href="" onClick={salir}>Salir</NavDropdown.Item>
                                    </NavDropdown>
                                </Nav>
                            </Navbar.Collapse>
                        </Container>
                    </Navbar>
                </div>
                <div className="card col-sm-12" style={{ minHeight: "75vh", marginTop: 0 }}>
                    <div className="row g-3">
                        <div className="content" id="content">
                        </div>
                        <div id="miPerfil">
                            <div>
                                <br></br>
                                <div className="card col-sm-12" >
                                    <br></br>
                                    <H texto={"Mi Perfil"} tamano={3}></H>
                                    <br></br>
                                    <div className="row g-3">
                                        <FormInput tamano={3} type="text" id="usuario" value={this.state.data.usuario} placeholder="Usuario" name="usuario" readonly={"true"} />
                                        <FormInputEditable
                                            tamano={3}
                                            type="text"
                                            id="nombre"
                                            value={this.state.nombretemp}
                                            placeholder="Nombre"
                                            name="nombre"
                                            readonly={"false"}
                                            onChange={this.handleNombreChange}
                                        />
                                        <FormInputEditable
                                            tamano={3}
                                            type="text"
                                            id="email"
                                            value={this.state.emailtemp}
                                            placeholder="Email"
                                            name="email"
                                            readonly="false"
                                            onChange={this.handleEmailChange}
                                        />
                                    </div>
                                    <div className="row g-3">
                                    </div>
                                    <br></br>
                                    <div className="row g-3">
                                        <div className="form-floating mb-4 col-md-3">
                                            <input type="password" id="contrasena" className="form-control" autoComplete="off" />
                                            <label className="form-label" htmlFor="contrasena">
                                                Nueva Contraseña
                                            </label>
                                        </div>
                                        <div className="form-floating col-4">
                                            <Alert severity="info" color="info">
                                                Si desea modificar la contraseña, por favor, complete los campos siguientes: nueva contraseña y repetir contraseña.
                                            </Alert>
                                        </div>
                                    </div>
                                    <div className="row g-3">
                                        <div className="form-floating mb-4 col-md-3">
                                            <input type="password" id="repetirContraseña" className="form-control" autoComplete="off" />
                                            <label className="form-label" htmlFor="repetirContraseña">
                                                Repetir Contraseña
                                            </label>
                                        </div>
                                    </div>
                                    <div className="row g-3">

                                    </div>

                                    <br></br>

                                    <div className="row g-3">
                                        <div className="form-floating mb-3 col-md-8"></div>
                                        <div className="form-floating mb-3 col-md-2" id="div_boton">
                                            <button id="boton_guardar" type="button" className="btn btn-met float-end">Actualizar</button>
                                        </div>
                                        <BotonCancelar />
                                    </div>
                                    <br></br>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <center><footer style={{ color: "white" }}>&copy; Verticall S.A</footer></center>
            </div>
        );
    }
}
export default Home
