import React, { Component } from "react";
import Box from '@mui/material/Box';
import $ from 'jquery';
//Grilla
import { AgGridReact } from 'ag-grid-react';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { localeEs } from "../../utils/locale.es";
import {  url } from "../../utils/Globals";
import { FormInputEditable, H, FormInput, BotonCancelar, SwitchActivo } from "../../utils/Controles";

import { FormControl, FormControlLabel, FormGroup } from "@mui/material";
import Swal from  'sweetalert2';
class FormCantidadesParaBonificacion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: this.props.token,
      titulo: 'Cantidades para Bonificación',
      tituloExistente: null,
      nombre: null,
      creadoEl: null,
      desde: null,
      hasta: null,
      idRegistro: null,
      habilitado: null,
      data: [],
      headers: {
        'x-access-token': `${this.props.token}`,
      },
    }
  }
  componentDidMount() {
    this.fetchData();
    $("#agregar").hide();
    $("#existente").hide();
    $("#creado_el").parent().hide();
    $("#switchActivo").hide();
    $(".btn-met-cancelar").on("click", () => {
      var campos = [
        { nombre: "#nombre"},
        { nombre: "#desde"},
        { nombre: "#hasta"},
      ];
      campos.forEach(function (campo) {
        var elementos = document.querySelectorAll(campo.nombre);
        elementos.forEach(function (elemento) {
          $(elemento).removeAttr('style');
        })
      });
        $("#existente").hide();
        $("#listado").show();
        $("#agregar").hide();  
    });
  }
  
  crearRegistro = () => {
    this.setState({ nombre: '', desde: '', hasta: '', creadoEl: '', idRegistro: '' }, () => {
      $("#nombre").removeAttr('style');
      $("#desde").removeAttr('style');
      $("#hasta").removeAttr('style');
      $("#listado").hide();
      $("#existente").hide();
      $("#agregar").show();
    })
  }
  guardarNuevo = () => {
    let validacion = this.validar();
    if (validacion) {
      var respuestaJson;
      const Data = {
        nombre: `${this.state.nombre}`,
        desde: `${this.state.desde}`,
        hasta: `${this.state.hasta}`
      };
      const headers = {
        'x-access-token': `${this.state.token}`,
        "Content-Type": "application/json"
      };

      fetch(url + "cantidades-para-bonificacion", {
        method: 'POST',
        body: JSON.stringify(Data),
        headers
      }).then(response => {
        if (!response.ok) {
          console.log("Error en la petición");
          console.log(response);
        }
        else {
          respuestaJson = response.json();
        }
        return respuestaJson;
      }).then(() => {
        this.setState({ nombre: '', desde: '', hasta: '', creadoEl: '', idRegistro: '' });
        Swal.fire({
          icon: "success",
          title: "Cantidad para Bonificación creada",
          timer: 2000,
          text: "Se creo el registro correctamente.",
        });
        this.actualizarGrilla();
        $("#listado").show();
        $("#agregar").hide();
      }).catch(err => {
        console.error('Error al enviar la petición POST:', err);
      });
    } else {
      Swal.fire({
        icon: "error",
        text: 'Para continuar debe completar todos los campos.',
        width: 500,
        height: 200,
      });
    }

  }
  actualizarRegistro = () => {
    let validacion = this.validar();
    if (validacion) {
      var respuestaJson;
      const headers = {
        'x-access-token': `${this.state.token}`,
        "Content-Type": "application/json"
      };
      const Data = {
        nombre: `${this.state.nombre}`,
        desde: `${this.state.desde}`,
        hasta: `${this.state.hasta}`
      };
      fetch(url + "cantidades-para-bonificacion/" + this.state.idRegistro, {
        method: 'PUT',
        body: JSON.stringify(Data),
        headers
      }).then(response => {
        if (!response.ok) {
          console.log("Error en la petición");
        }
        else {
          respuestaJson = response.json();
        }
        return respuestaJson;
      }).then(() => {
        this.setState({ nombre: '', desde: '', hasta: '', creadoEl: '', idRegistro: '' }, () => {
          Swal.fire({
            icon: "success",
            title: "Cantidad para Bonificación actualizada",
            timer: 2000,
            text: "Se actualizo el registro correctamente.",
          });
          this.actualizarGrilla();
        });
        $("#existente").hide();
            $("#listado").show();
            $("#agregar").hide();
      }).catch(err => {
        console.error('Error al enviar la petición POST:', err);
      });
    } else {
      Swal.fire({
        icon: "error",
        text: 'Para continuar debe completar todos los campos.',
        width: 500,
        height: 200,
      });
    }

  }
  async fetchData() {
    try {
      const { headers } = this.state;
      const response = await fetch(url + "cantidades-para-bonificacion?habilitado=1", { headers });
      const responseData = await response.json();
      this.setState({ data: responseData }, () => { });
    } catch (error) {
      console.error('Error al obtener los datos:', error);
    }
  }
  async obtenerRegistro(id) {
    try {
      const { headers } = this.state;
      const response = await fetch(url + "cantidades-para-bonificacion/" + id, { headers });
      let responseData = await response.json();
      if (responseData) {
        let habilitado_ = responseData.deshabilitadoEl === null ? 'Si' : 'No';
        this.setState({ nombre: responseData.nombre, desde: responseData.desde, hasta: responseData.hasta, creadoEl: responseData.creadoEl.substring(0, 10), idRegistro: responseData.id, habilitado: habilitado_ },()=>{
          let tituloExistente = this.state.nombre !== null ? `Cantidad para bonificación: ${this.state.nombre}` : "";
          this.setState({tituloExistente: tituloExistente},()=>{
            $("#listado").hide();
            $("#existente").show();
            $("#agregar").hide();
          });
          
        });
        

      }
    } catch (error) {
      console.error('Error al obtener los datos:', error);
    }
  }
  validar = () => {
    let respuesta = true;
    var errorStyles = {
      'border-color': '#f56565',
      'color': '#2a2222',
      'border-width': '3px',
    };
    var campos = [
      { nombre: this.state.nombre, selector: "#nombre", tipo: "id" },
      { nombre: this.state.desde, selector: "#desde", tipo: "id" },
      { nombre: this.state.hasta, selector: "#hasta", tipo: "id" },
    ];
    var desde = parseFloat(this.state.desde);
    var hasta = parseFloat(this.state.hasta);

    if (desde > hasta) {
      Swal.fire({
        icon: "error",
        text: 'El valor del campo "desde" no puede ser mayor que el campo "hasta".',
        width: 500,
        height: 200,
      });
      respuesta = false;
    }
    campos.forEach(function (campo) {
      if (campo.nombre === "" || campo.nombre === null || campo.nombre === undefined) {
        try {
          var elementos = document.querySelectorAll(campo.selector);
          elementos.forEach(function (elemento) {
            $(elemento).css(errorStyles);
          })
        } catch {
          console.log(campo.selector);
        }
        if (campo.tipo === "id") {
          document.getElementById(campo.selector.substring(1)).focus();
          document.getElementById(campo.selector.substring(1)).select();
        }
        
        respuesta = false;
      } else {
        $(campo.selector).removeAttr('style');
        $(campo.selector).addClass('form-control');
      }

    }

    );
    return respuesta;
  }
  async eliminar(id) {
    Swal.fire({
      title: "¿Está seguro que quiere eliminar el registro?",
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "No",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si"
    }).then((result) => {
      if (result.isConfirmed) {
        const headers = {
          'x-access-token': `${this.state.token}`,
          "Content-Type": "application/json"
        };
        fetch(url + "cantidades-para-bonificacion/" + id, {
          method: 'Delete',
          headers
        }).then(() => {
          Swal.fire({
            title: "Se elimino el registro",
            icon: "success",
            timer: 2000
          });
          this.actualizarGrilla();
        }).catch(err => {
          console.error('Error al enviar la petición POST:', err);
        });
        
      }
    });
  }
  handleNombreChange = (newValue) => {
    this.setState({ nombre: newValue });
  }
  handleDesde = (newValue) => {
    var desde = String(newValue).replace(/[^0-9]/g, '');
    this.setState({ desde: desde });
  }
  handleHasta = (newValue) => {
    var hasta = String(newValue).replace(/[^0-9]/g, '');
    this.setState({ hasta: hasta });
  }
  handleActivoChange = (event) => {
    let habilitar = ''
    if (event.target.checked === true) {
      habilitar = 'Si';
    } else {
      habilitar = 'No';
    }
    this.setState({ habilitado: habilitar });
  }
  actualizarGrilla() {
    this.fetchData();
  }
  handleDoubleClick = (event) => {
    this.obtenerRegistro(event.data.id);
  }
  render() {
    const { data } = this.state;
    const rows = data.map((item) => ({
      id: item.id,
      nombre: item.nombre,
      desde: item.desde,
      hasta: item.hasta,
    }));
    const agregarBotones = props => {
      return (
        <div className="contenedorBoton">
          <button  className="botonVer" onClick={() => this.obtenerRegistro(props.data.id)}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-eye" viewBox="0 0 16 16">
              <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
              <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
            </svg>
          </button>
          <button onClick={() => this.eliminar(props.data.id)} className="botonBorrar">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16">
                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z" />
                <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z" />
              </svg>
          </button>
        </div>
        
      )
    }
    const Columnas = [
      {
        field: 'nombre', flex: 1, type: 'text',filter: 'agSetColumnFilter', renderHeader: () => (<strong> {'Nombre'}</strong>),
      },
      {
        field: 'desde', flex: 1, type: 'text',filter: 'agSetColumnFilter', renderHeader: () => (<strong> {'Desde'}</strong>),
      },
      {
        field: 'hasta', flex: 1, type: 'text',filter: 'agSetColumnFilter', renderHeader: () => (<strong> {'Hasta'}</strong>),
      },
      {
        headerName: "",
        field: "",
        sortable: false,
        filter: false,
        width: "100px",
        cellRenderer: agregarBotones,
        cellRendererParams: {
          id: 1
        }
      },
    ];
    return (
      <div style={{ height: '100%', width: '100%' }}>
        <div id="listado">
          <div>
            <section>
              <div style={{ height: '50%', width: '100%', padding: "10px 0px 0px 0px" }}>
                <Box sx={{ width: '100%' }} style={{ backgroundColor: "white" }}>
                  <H texto={this.state.titulo} tamano={3}></H>
                  <div className="row g-3">
                    <div className="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups">
                      <div className="btn-group btn-group-lg" role="group" aria-label="First group">
                        <button type="button" className="btn btn-secondary" id="boton_nuevo" onClick={() => { this.crearRegistro() }}>
                          <center>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus" viewBox="0 0 16 16">
                              <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                            </svg>
                          </center>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div
                    className="ag-theme-quartz"
                    style={{
                      width: "100%",
                      "--ag-font-size": "16px"
                    }}
                  >
                    <AgGridReact
                      columnDefs={Columnas}
                      rowData={rows}
                      pagination={true}
                      paginationPageSize={10}
                      paginationPageSizeSelector={[10, 20, 50, 100]}
                      domLayout='autoHeight'
                      localeText={localeEs}
                      reactiveCustomComponents={true}
                      onCellDoubleClicked={this.handleDoubleClick}
                    />

                  </div>
                </Box>
              </div>
            </section>
          </div>
        </div>
        <div id="agregar">
          <br></br>
          <H texto={"Nueva Cantidad para Bonificación"} tamano={3}></H>
          <br></br>
          <div className="card col-sm-12" >
            <br></br>
            <div className="row g-3">
              <FormInputEditable
                tamano={3}
                type="text"
                id="nombre"
                value={this.state.nombre}
                placeholder="Nombre"
                name="nombre"
                readonly={"false"}
                onChange={this.handleNombreChange}
              />
              <FormInputEditable
                tamano={3}
                type="int"
                id="desde"
                value={this.state.desde}
                placeholder="Desde"
                name="desde"
                readonly={"false"}
                onChange={this.handleDesde}
              />
              <FormInputEditable
                tamano={3}
                type="int"
                id="hasta"
                value={this.state.hasta}
                placeholder="Hasta"
                name="hasta"
                readonly={"false"}
                onChange={this.handleHasta}
              />
            </div>
            <br></br>
            <div className="row g-3">
              <div className="form-floating mb-3 col-md-8"></div>
              <div className="form-floating mb-3 col-md-2" id="div_boton">
                <button
                  type="button"
                  className="btn btn-met float-end"
                  id="boton_generar"
                  onClick={this.guardarNuevo}
                >
                  Guardar
                </button>
              </div>
              <BotonCancelar />
            </div>
          </div>
        </div>
        <div id="existente">
          <div>
            <br></br>
            <div className="card col-sm-12">
              <br></br>
              <H texto={this.state.tituloExistente} tamano={3}></H>
              <br></br>
              <div className="row g-3">
                <FormInputEditable
                  tamano={3}
                  type="text"
                  id="nombre"
                  value={this.state.nombre}
                  placeholder="Nombre"
                  name="nombre"
                  readonly={"false"}
                  onChange={this.handleNombreChange}
                />
                <FormInputEditable
                  tamano={3}
                  type="int"
                  id="desde"
                  value={this.state.desde}
                  placeholder="Desde"
                  name="desde"
                  readonly={"false"}
                  onChange={this.handleDesde}
                />
                <FormInputEditable
                  tamano={3}
                  type="int"
                  id="hasta"
                  value={this.state.hasta}
                  placeholder="Hasta"
                  name="hasta"
                  readonly={"false"}
                  onChange={this.handleHasta}
                />
                <FormInput tamano={3} type="text" id="creado_el" value={this.state.creadoEl} placeholder="Creado el" name="creado_el" />
              </div>
              <div id="switchActivo" className="row g-3">
                <FormControl component="fieldset">
                  <FormGroup aria-label="position" row>
                    <FormControlLabel
                      control={<SwitchActivo checked={this.state.habilitado === 'Si'} />}
                      label="Activo"
                      labelPlacement="start"
                      onChange={this.handleActivoChange}
                    />
                  </FormGroup>
                </FormControl>
              </div>
              <div className="row g-3">
                <div className="form-floating mb-3 col-md-8"></div>
                <div className="form-floating mb-3 col-md-2" id="div_boton">
                  <button id="boton_actualizar" type="button" className="btn btn-met float-end" onClick={() => this.actualizarRegistro()}>Actualizar</button>
                </div>
                <div className="form-floating mb-3 col-md-2" id="div_boton">
                  <button id="boton_cancelar" type="button" className="btn btn-met-cancelar float-end">Cancelar</button>
                </div>
              </div>
              <br></br>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export { FormCantidadesParaBonificacion }