import React, { Component } from "react";
import Box from '@mui/material/Box';
import $ from 'jquery';
//Grilla
import { AgGridReact } from 'ag-grid-react';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { localeEs } from "../../utils/locale.es";
import { url } from "../../utils/Globals";
import { FormInputEditable, H, FormInput, BotonCancelar, SwitchActivo } from "../../utils/Controles";

import { FormControl, FormControlLabel, FormGroup, Radio } from "@mui/material";
import Swal from  'sweetalert2';
class RadioPerfiles extends Component {
  
  render() {
    return (
      <FormControl component="fieldset">
        <FormGroup aria-label="position" row>
          <FormControlLabel
            value={"Administrador"}
            control={<Radio />}
            label="Administrador"
            checked={this.props.perfil === 'Administrador'}
            onChange={this.props.onPerfilChange}
          />
          <FormControlLabel
            value={"Vendedor"}
            control={<Radio />}
            label="Vendedor"
            checked={this.props.perfil === 'Vendedor'}
            onChange={this.props.onPerfilChange}
          />
          <FormControlLabel
            value={"Supervisor"}
            control={<Radio />}
            label="Supervisor"
            checked={this.props.perfil === 'Supervisor'}
            onChange={this.props.onPerfilChange}
          />
        </FormGroup>
      </FormControl>
    );
  }
}
class FormUsuarios extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: this.props.token,
      perfil: 'Vendedor',
      usuario: null,
      creadoEl: null,
      contraseña: null,
      repetirContraseña: null,
      email: null,
      nombre: null,
      puedeActualizar: true,
      idRegistro: null,
      habilitado: null,
      data: [],
      headers: {
        'x-access-token': `${this.props.token}`,
      },
    }
  }
  crearRegistro = () => {
    this.setState({ nombre: '', usuario: '', email: '', creadoEl: '', contraseña: '', repetirContraseña: '', idRegistro: '' }, () => {
      var campos = [
        { nombre: "#contraseña" },
        { nombre: "#repetirContraseña" },
        { nombre: "#nombre" },
        { nombre: "#usuario" },
        { nombre: "#email" },
      ];
      campos.forEach(function (campo) {
        var elementos = document.querySelectorAll(campo.nombre);
        elementos.forEach(function (elemento) {
          $(elemento).removeAttr('style');
        })
      });
      $("#existente").hide();
      $("#listado").hide();
      $("#agregar").show();
    })
  }
  
  componentDidMount = () => {
    this.fetchData();
    $("#agregar").hide();
    $("#existente").hide();
    $("#creado_el").parent().hide();
    $("#contraseña").attr("autocomplete", "new-password");
    $("#contraseña").attr("autocomplete", "off");
    $("#repetirContraseña").attr("autocomplete", "new-password");
    $("#repetirContraseña").attr("autocomplete", "off");

    $(".btn-met-cancelar").on("click", () => {
      this.setState({ nombre: '', usuario: '', email: '', creadoEl: '', contraseña: '', repetirContraseña: '', idRegistro: '' }, () => {
        var campos = [
          { nombre: "#contraseña" },
          { nombre: "#repetirContraseña" },
          { nombre: "#nombre" },
          { nombre: "#usuario" },
          { nombre: "#email" },
        ];
        campos.forEach(function (campo) {
          var elementos = document.querySelectorAll(campo.nombre);
          elementos.forEach(function (elemento) {
            $(elemento).removeAttr('style');
          })
        });
        $("#existente").hide();
        $("#listado").show();
        $("#agregar").hide();
      })

    });
  }
  
  validar = () => {
    let respuesta = true;
    var errorStyles = {
      'border-color': '#f56565',
      'color': '#2a2222',
      'border-width': '3px',
    };
    var campos = [
      { nombre: this.state.usuario, selector: "#usuario", tipo: "id" },
      { nombre: this.state.nombre, selector: "#nombre", tipo: "id" },
      { nombre: this.state.email, selector: "#email", tipo: "id" },

    ];
    if (this.state.idRegistro === '') {
      campos.push({ nombre: this.state.contraseña, selector: "#contraseña", tipo: "id" },
        { nombre: this.state.repetirContraseña, selector: "#repetirContraseña", tipo: "id" },);
    }
    campos.forEach(function (campo) {
      if (campo.nombre === "" || campo.nombre === null || campo.nombre === undefined) {
        try {
          var elementos = document.querySelectorAll(campo.selector);
          elementos.forEach(function (elemento) {
            $(elemento).css(errorStyles);
          })
        } catch {
          console.log(campo.selector);
        }
        if (campo.tipo === "id") {
          document.getElementById(campo.selector.substring(1)).focus();
          document.getElementById(campo.selector.substring(1)).select();
        }
        
        respuesta = false;
      } else {
        $(campo.selector).removeAttr('style');
        $(campo.selector).addClass('form-control');
      }
    });
    return respuesta;
  }
  guardarNuevo = () => {
    let validacion = this.validar();
    if (validacion) {
      let nombre = $("#nombre").val();
      let email = $("#email").val();
      let usuario = $("#usuario").val();
      let perfil = this.state.perfil;
      let habilitado = this.state.habilitado === 'Si' ? true : false;
      var contraseña = this.state.contraseña;
      var repetirContraseña = this.state.repetirContraseña;
      var Data;
      var respuestaJson;
      var puede = this.validarContraseña(contraseña,repetirContraseña);
      this.setState({ puedeActualizar: puede });
      if (contraseña !== "" && repetirContraseña !== "") {
        if (puede) {
          Data = {
            nombre: `${nombre}`,
            email: `${email}`,
            perfil: `${perfil}`,
            usuario: `${usuario}`,
            contraseña: `${contraseña}`,
            habilitado: habilitado,
          };
        }
      } else {
        Data = {
          nombre: `${nombre}`,
          email: `${email}`,
          usuario: `${usuario}`,
          perfil: `${perfil}`,
          habilitado: habilitado,
        };
      }
      if (puede === true) {
        try {
          const headers = {
            'x-access-token': `${this.state.token}`,
            "Content-Type": "application/json"
          };
          fetch(url + "usuarios/", {
            method: 'POST',
            body: JSON.stringify(Data),
            headers
          }).then(response => {
            if (!response.ok) {
              console.log("Error en la petición");
              respuestaJson = response.json();
            }
            else {
              respuestaJson = response.json();
            }
            return respuestaJson;
          }).then(respuestaJson => {
            if(respuestaJson.mensaje === "El usuario ya existe"){
              Swal.fire({
                icon: "error",
                text: `El usuario ${this.state.usuario} ya existe, para continuar ingrese un usuario distinto`,
                width: 500,
                height: 200,
              });
            }else if(respuestaJson.mensaje === "Valor inválido") {

              var alertMessage ="Los valores de los siguientes campos no son válidos: \n";

              for (var key in respuestaJson.errores) {
                if (respuestaJson.errores.hasOwnProperty(key)) {
                  alertMessage += "-"+ key + ": " + respuestaJson.errores[key] + "\n";
                }
              }
              Swal.fire({
                icon: "error",
                text: alertMessage,
                width: 500,
                height: 200,
              });
            }else{
              this.setState({ nombre: '', usuario: '', email: '', creadoEl: '', contraseña: '', repetirContraseña: '', idRegistro: '' }, () => {
                Swal.fire({
                  icon: "success",
                  title: "Usuario creado",
                  timer: 2000,
                  text: "Se creo el registro correctamente.",
                });
                this.actualizarGrilla()
                $("#listado").show();
                $("#existente").hide();
                $("#agregar").hide();
  
              })
            }
          }).catch(err => {
            console.log(err)
            console.error('Error al enviar la petición POST:', err);
          });
        } catch (error) {
          console.error('Error al obtener los datos:', error);
        }
      }
    } else {
      Swal.fire({
        icon: "error",
        text: 'Para continuar debe completar todos los campos.',
        width: 500,
        height: 200,
      });
    }

  }
  actualizarRegistro = async () => {
    let validacion = this.validar();
    if (validacion) {
      let nombre = $("#nombre").val();
      let email = $("#email").val();
      let perfil = this.state.perfil;
      let habilitado = this.state.habilitado === 'Si' ? true : false;
      var contraseña = this.state.contraseña;
      var repetirContraseña = this.state.repetirContraseña;
      var Data;
      var respuestaJson;
      var puede = true;
      if(contraseña !== '' && contraseña !== null){
        let resultadoContraseña = contraseña.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\w\W]{8,}$/gm);
        let igual = contraseña === repetirContraseña;
        if (igual) {
          if (resultadoContraseña) {
            puede = true;
          } else {
            puede = false;
            Swal.fire({
              icon: "error",
              text: 'La contraseña debe poseer al menos una letra minúscula, una mayúscula, un número y un mínimo de 8 carácteres.',
              width: 500,
              height: 200,
            });
          }
        } else {
          Swal.fire({
            icon: "error",
            text: 'Las contraseñas no coinciden.',
            width: 500,
            height: 200,
          });
          puede = false;
        }
        this.setState({ puedeActualizar: puede });
      }
      
      if (contraseña !== "" && repetirContraseña !== "" && contraseña !== null && repetirContraseña !== null) {
        if (puede) {
          Data = {
            nombre: `${nombre}`,
            email: `${email}`,
            perfil: `${perfil}`,
            contraseña: `${contraseña}`,
            habilitado: habilitado,
          };
        }
      } else {
        Data = {
          nombre: `${nombre}`,
          email: `${email}`,
          perfil: `${perfil}`,
          habilitado: habilitado,
        };
      }
      if (puede === true) {
        try {
          const headers = {
            'x-access-token': `${this.state.token}`,
            "Content-Type": "application/json"
          };
          var error;
          fetch(url + "usuarios/" + this.state.idRegistro, {
            method: 'PUT',
            body: JSON.stringify(Data),
            headers
          }).then(response => {
            if (!response.ok) {
              console.log("Error en la petición");
              respuestaJson = response.json();
            }
            else {
              respuestaJson = response.json();
            }
            return respuestaJson;
          }).then(respuestaJson => {
            if(respuestaJson.mensaje === "Valor inválido") {
              var alertMessage ="Los valores de los siguientes campos no son válidos: \n";
              for (var key in respuestaJson.errores) {
                if (respuestaJson.errores.hasOwnProperty(key)) {
                  alertMessage += "-"+ key + ": " + respuestaJson.errores[key] + "\n";
                }
              }
              Swal.fire({
                icon: "error",
                text: alertMessage,
                width: 500,
                height: 200,
              });
            }else{
              this.setState({ nombre: '', usuario: '', email: '', creadoEl: '', contraseña: '', repetirContraseña: '', idRegistro: '' }, () => {
                Swal.fire({
                  icon: "success",
                  title: "Usuario actualizado",
                  timer: 2000,
                  text: "Se actualizo el registro correctamente.",
                });
                this.actualizarGrilla();
                $("#listado").show();
                $("#existente").hide();
                $("#agregar").hide();
              })
            }
            

          })
            .catch(err => {
              console.log(error)
              console.error('Error al enviar la petición POST:', err);
            });
        } catch (error) {
          console.error('Error al obtener los datos:', error);
        }
      }
    } else {
      Swal.fire({
        icon: "error",
        text: "Para continuar debe completar todos los campos.",
        width: 500,
        height: 200,
      });
    }

  }
  async fetchData() {
    try {
      const headers = {
        'x-access-token': `${this.props.token}`,
      };
      const response = await fetch(url + "usuarios", { headers });
      const responseData = await response.json();
      this.setState({ data: responseData }, () => { });
    } catch (error) {
      console.error('Error al obtener los datos:', error);
    }
  }
  async obtenerRegistro(id) {
    try {
      const { headers } = this.state;
      const response = await fetch(url + "usuarios/" + id, { headers });
      let responseData = await response.json();
      if (responseData) {
        let nombre = responseData.nombre;
        let usuario = responseData.usuario;
        let email = responseData.email;
        let perfil = responseData.perfil;
        let creado_el = responseData.creadoEl.substring(0, 10);
        let habilitado = responseData.deshabilitadoEl == null ? 'Si' : 'No';
        this.setState(
          { nombre: nombre, usuario: usuario, email: email, perfil: perfil, creadoEl: creado_el, idRegistro: responseData.id, habilitado: habilitado },
          () => {
            $("#listado").hide();
            $("#existente").show();
            $("#agregar").hide();
          }
        );
      }
    } catch (error) {
      console.error('Error al obtener los datos:', error);
    }
  }
  handlePerfilChange = (event) => {
    const newPerfil = event.target.value;
    this.setState({ perfil: newPerfil });
  }
  handleEmailChange = (newValue) => {
    this.setState({ email: newValue });
  }
  handleActivoChange = (event) => {
    let habilitar = ''
    if (event.target.checked === true) {
      habilitar = 'Si';
    } else {
      habilitar = 'No';
    }
    this.setState({ habilitado: habilitar });
  }
  handleNombreChange = (newValue) => {
    this.setState({ nombre: newValue });
  }
  handleUsuarioChange = (newValue) => {
    this.setState({ usuario: newValue });
  }
  handleActualizarClick = async () => {
    await this.actualizar();
  }
  handleContraseñaChange = (newValue) => {
    let contraseña = newValue.trim();
    this.setState({ contraseña: contraseña });
  }
  handleRepetirContraseñaChange = (newValue) => {
    let repetirContraseña = newValue.trim();
    this.setState({ repetirContraseña: repetirContraseña });
  }
  validarContraseña = (contraseña, repetirContraseña) => {
    var errorStyles = {
      'border-color': '#f56565',
      'color': '#2a2222',
      'border-width': '3px',
    };
    let resultadoContraseña = contraseña.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\w\W]{8,}$/gm);
    
    let igual = contraseña === repetirContraseña;
    
    var puede;
    if (igual) {
      if (resultadoContraseña) {
        puede = true;
      } else {
        Swal.fire({
          icon: "error",
          text: "La contraseña debe poseer al menos una letra minúscula, una mayúscula, un número y un mínimo de 8 carácteres",
          width: 500,
          height: 200,
        });
        var elementos = document.querySelectorAll("#contraseña");
          elementos.forEach(function (elemento) {
            $(elemento).css(errorStyles);
          })
        puede = false;
      }
    } else {
      Swal.fire({
        icon: "error",
        text: "Las contraseñas no coinciden.",
        width: 500,
        height: 200,
      });
      puede = false;
      elementos = document.querySelectorAll("#repetirContraseña");
          elementos.forEach(function (elemento) {
            $(elemento).css(errorStyles);
          })
    }
    
    this.setState({ puedeActualizar: puede });
    return puede;
  }
  actualizarGrilla = () => {
    this.fetchData();
  }
  handleDoubleClick = (event) => {
    this.obtenerRegistro(event.data.id);
  }
  render() {
    const { data } = this.state;
    const rows = data.map((item) => ({
      id: item.id,
      usuario: item.usuario,
      nombre: item.nombre,
      email: item.email,
      habilitado: item.deshabilitadoEl == null ? 'Si' : 'No',
    }));
    const agregarBotones = props => {
      return (
        <div className="contenedorBoton">
          <button  className="botonVer" onClick={() => this.obtenerRegistro(props.data.id)}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-eye" viewBox="0 0 16 16">
              <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
              <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
            </svg>
          </button>
        </div>
      )
    }
    const Columnas = [
      {
        field: 'usuario', flex: 1, type: 'text',filter: 'agSetColumnFilter', renderHeader: () => (<strong> {'Usuario'}</strong>),
      },
      {
        field: 'nombre', flex: 1, type: 'text',filter: 'agSetColumnFilter', renderHeader: () => (<strong> {'Nombre'}</strong>),
      },
      {
        field: 'email', flex: 1, type: 'text',filter: 'agSetColumnFilter', renderHeader: () => (<strong> {'Email'}</strong>),
      },
      {
        field: 'habilitado', flex: 1, type: 'text',filter: 'agSetColumnFilter', renderHeader: () => (<strong> {'Habilitado'}</strong>),
      },
      {
        headerName: "",
        field: "",
        sortable: false,
        filter: false,
        width: "100px",
        cellRenderer: agregarBotones,
        cellRendererParams: {
          id: 1
        }
      },

    ];
    return (
      <div style={{ height: '100%', width: '100%' }}>
        <div id="listado">
          <div>
            <section>
              <div style={{ height: '50%', width: '100%', padding: "10px 0px 0px 0px" }}>
                <Box sx={{ width: '100%' }} style={{ backgroundColor: "white" }}>
                  <H texto={"Usuarios"} tamano={3}></H>
                  <div className="row g-3">
                    <div className="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups">
                      <div className="btn-group btn-group-lg" role="group" aria-label="First group">
                        <button type="button" className="btn btn-secondary" onClick={() => this.crearRegistro()} id="boton_nuevo">
                          <center>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus" viewBox="0 0 16 16">
                              <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                            </svg>
                          </center>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div
                    className="ag-theme-quartz"
                    style={{
                      width: "100%",
                      "--ag-font-size": "16px"
                    }}
                  >
                    <AgGridReact
                      columnDefs={Columnas}
                      rowData={rows}
                      pagination={true}
                      paginationPageSize={10}
                      paginationPageSizeSelector={[10, 20, 50, 100]}
                      domLayout='autoHeight'
                      localeText={localeEs}
                      reactiveCustomComponents={true}
                      onCellDoubleClicked={this.handleDoubleClick}
                    />

                  </div>
                </Box>
              </div>
            </section>
          </div>
        </div>
        <div id="agregar">
          <br></br>
          <H texto={"Nuevo usuario"} tamano={3}></H>
          <br></br>
          <div className="card col-sm-12" >
            <br></br>
            <div className="row g-3">
              <FormInputEditable
                tamano={3}
                type="text"
                id="usuario"
                value={this.state.usuario}
                placeholder="Usuario"
                name="usuario"
                readonly={"false"}
                onChange={this.handleUsuarioChange}
              />
              <FormInputEditable
                tamano={3}
                type="text"
                id="nombre"
                value={this.state.nombre}
                placeholder="Nombre"
                name="nombre"
                readonly={"false"}
                onChange={this.handleNombreChange}
              />
              <FormInputEditable
                tamano={3}
                type="text"
                id="email"
                value={this.state.email}
                placeholder="Email"
                name="email"
                readonly="false"
                onChange={this.handleEmailChange}
              />
            </div>
            <br></br>
            <div className="row g-3">
              <FormInputEditable
                tamano={3}
                type="password"
                id="contraseña"
                value={this.state.contraseña}
                placeholder="Contraseña"
                name="contraseña"
                readonly={"false"}
                onChange={this.handleContraseñaChange}
              />

            </div>
            <div className="row g-3">
              <FormInputEditable
                tamano={3}
                type="password"
                id="repetirContraseña"
                value={this.state.repetirContraseña}
                placeholder="Repetir Contraseña"
                name="repetirContraseña"
                readonly={"false"}
                onChange={this.handleRepetirContraseñaChange}
              />
            </div>

            <br></br>
            <div className="row g-3">
              <RadioPerfiles perfil={this.state.perfil} onPerfilChange={this.handlePerfilChange} />
            </div>
            <br></br>
            <div className="row g-3">
              <FormControl component="fieldset">
                <FormGroup aria-label="position" row>
                  <FormControlLabel
                    control={<SwitchActivo checked={true} />}
                    label="Activo"
                    labelPlacement="start"
                    onChange={this.handleActivoChange}
                  />
                </FormGroup>
              </FormControl>
            </div>
            <br></br>
            <div className="row g-3">
              <div className="form-floating mb-3 col-md-8"></div>
              <div className="form-floating mb-3 col-md-2" id="div_boton">
                <button
                  type="button"
                  className="btn btn-met float-end"
                  id="boton_generar"
                  onClick={this.guardarNuevo}
                >
                  Guardar
                </button>
              </div>
              <BotonCancelar />
            </div>
          </div>
        </div>
        <div id="existente">
          <div>
            <br></br>
            <div className="card col-sm-12" >
              <br></br>
              <H texto={"Usuario: " + this.state.usuario} tamano={3}></H>
              <br></br>
              <div className="row g-3">
                <FormInput tamano={3} type="text" id="usuario" value={this.state.usuario} placeholder="Usuario" name="usuario" readonly={"true"} />
                <FormInputEditable
                  tamano={3}
                  type="text"
                  id="nombre"
                  value={this.state.nombre}
                  placeholder="Nombre"
                  name="nombre"
                  readonly={"false"}
                  onChange={this.handleNombreChange}
                />
                <FormInputEditable
                  tamano={3}
                  type="text"
                  id="email"
                  value={this.state.email}
                  placeholder="Email"
                  name="email"
                  readonly="false"
                  onChange={this.handleEmailChange}
                />
                <FormInput tamano={3} type="text" id="creado_el" value={this.state.creadoEl} placeholder="Creado el" name="creado_el" readonly={"true"} />
              </div>
              <br></br>
              <div className="row g-3">
                <FormInputEditable
                  tamano={3}
                  type="password"
                  id="contraseña"
                  value={this.state.contraseña}
                  placeholder="Cambiar Contraseña"
                  name="contraseña"
                  readonly={"false"}
                  onChange={this.handleContraseñaChange}
                />

              </div>
              <div className="row g-3">
                <FormInputEditable
                  tamano={3}
                  type="password"
                  id="repetirContraseña"
                  value={this.state.repetirContraseña}
                  placeholder="Repetir Contraseña"
                  name="repetirContraseña"
                  readonly={"false"}
                  onChange={this.handleRepetirContraseñaChange}
                />
              </div>

              <br></br>
              <div className="row g-3">
                <RadioPerfiles perfil={this.state.perfil} onPerfilChange={this.handlePerfilChange} />
              </div>
              <br></br>
              <div id="switchActivo" className="row g-3">
                <FormControl component="fieldset">
                  <FormGroup aria-label="position" row>
                    <FormControlLabel
                      control={<SwitchActivo checked={this.state.habilitado === 'Si'} />}
                      label="Activo"
                      labelPlacement="start"
                      onChange={this.handleActivoChange}
                    />
                  </FormGroup>
                </FormControl>
              </div>
              <div className="row g-3">
                <div className="form-floating mb-3 col-md-8"></div>
                <div className="form-floating mb-3 col-md-2" id="div_boton">
                  <button id="boton_actualizar" type="button" onClick={() => this.actualizarRegistro()} className="btn btn-met float-end">Actualizar</button>
                </div>
                <BotonCancelar />
              </div>
              <br></br>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export { FormUsuarios }