import "../styles/Login.css";
import Head from "../utils/Head";
import React, { Component } from "react";
import { useParams } from 'react-router-dom';
import $ from 'jquery';
import { url, root } from "../utils/Globals";
import Swal from 'sweetalert2';
import Form from "./Login";

function withRouter(Component) {
  return function (props) {
    const params = useParams();
    return <RecuperarContraseña {...props} params={params} />;
  };
}
class RecuperarContraseña extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEventBound: false,
      token: '',
    };

  }

  componentDidMount() {
    const { token } = this.props.params;
    this.setState({ token: token },()=>{
      console.log(this.state.token);
    });
    
    if (!this.state.isEventBound) {
      document.addEventListener("keypress", this.handleKeyPress);
      this.setState({ isEventBound: true });
    }
    const botonRecuperar = document.getElementById('botonRecuperar');
    botonRecuperar.addEventListener('click', this.handleRecuperar);

  }

  componentWillUnmount() {
    document.removeEventListener("keypress", this.handleKeyPress);
  }

  handleKeyPress = (e) => {
    if (e.which === 13) {
      this.handleRecuperar();
    }
  };
  handleRecuperar = () => {
    let puedeActualizar = this.validarContraseña();
    let contraseña = $("#contrasena").val();
    if (puedeActualizar) {
      const Data = {
        contraseña: `${contraseña}`,
      };
      fetch(url + `recuperar/${this.state.token}`, {
        method: 'PUT',
        body: JSON.stringify(Data),
        headers: {
          "Content-Type": "application/json"
        }
      }).then(response => {
        Swal.fire({
          icon: "success",
          timer: 5000,
          text: `Se reestablecio la contraseña correctamente.`
        });
        window.location.href = `https://budget.verticall.ar/`;

      })
    }
  }
  validarContraseña = () => {
    let contraseña = $("#contrasena").val();
    let repetirContraseña = $("#repetirContraseña").val();
    var errorStyles = {
      'border-color': '#f56565',
      'color': '#2a2222',
      'border-width': '3px',
    };
    let resultadoContraseña = contraseña.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\w\W]{8,}$/gm);

    let igual = contraseña === repetirContraseña;

    var puede;
    if (igual) {
      if (resultadoContraseña) {
        puede = true;
      } else {
        Swal.fire({
          icon: "error",
          text: "La contraseña debe poseer al menos una letra minúscula, una mayúscula, un número y un mínimo de 8 carácteres",
          width: 500,
          height: 200,
        });
        var elementos = document.querySelectorAll("#contraseña");
        elementos.forEach(function (elemento) {
          $(elemento).css(errorStyles);
        })
        puede = false;
      }
    } else {
      Swal.fire({
        icon: "error",
        text: "Las contraseñas no coinciden.",
        width: 500,
        height: 200,
      });
      puede = false;
      elementos = document.querySelectorAll("#repetirContraseña");
      elementos.forEach(function (elemento) {
        $(elemento).css(errorStyles);
      })
    }

    this.setState({ puedeActualizar: puede });
    return puede;
  }
  render() {
    return (
      <div>
        <center>
          <form id="recoveryForm" style={{ backgroundColor: "#009B7C" }}>
            <Head></Head>
            <br></br>
            <h1>Recuperar Contraseña</h1>
            <br></br>
            <div className="form-floating col-md-2">
              <div className="row g-3">
                <div className="form-floating mb-4">
                  <input type="password" id="contrasena" className="form-control" autoComplete="off" />
                  <label className="form-label" htmlFor="contrasena">
                    Nueva Contraseña
                  </label>
                </div>
              </div>
              <div className="row g-3">
                <div className="form-floating mb-4">
                  <input type="password" id="repetirContraseña" className="form-control" autoComplete="off" />
                  <label className="form-label" htmlFor="repetirContraseña">
                    Repetir Contraseña
                  </label>
                </div>
              </div>
            </div>
            <div className="form-floating col-md-2">
              <button id="botonRecuperar" type="button" className="btn btn-met float-middle">Recuperar</button>
            </div>
          </form>
        </center>
      </div>
    )
  }
}

export default withRouter(RecuperarContraseña);