import React, { Component } from "react";
import Box from '@mui/material/Box';
import $ from 'jquery';
//Grilla
import { AgGridReact } from 'ag-grid-react';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { localeEs } from "../../utils/locale.es";
import { url, estilosSelect } from "../../utils/Globals";
import { H, FormInput, BotonCancelar, SwitchActivo, FormInputEditablePesos, FormInputEditablePorcentaje } from "../../utils/Controles";
import SelectReact from 'react-select';

import { FormControl, FormControlLabel, FormGroup } from "@mui/material";
import Swal from 'sweetalert2';
class FromDescuentosEstimados extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: this.props.token,
      titulo: 'Descuentos estimados',
      tituloExistente: null,
      categoriaDeVenta: null,
      nombre: null,
      creadoEl: null,
      tipoDeVenta: null,
      obraSocial: null,
      idRegistro: null,
      multiplicaPorAfiliados: true,
      tipo: null,
      valor: null,
      habilitado: null,
      data: [],
      dataTipoVenta: [],
      dataCategoria: [],
      dataObraSocial: [],
      dataTipo: [{ id: "Porcentaje", nombre: "Porcentaje" }, { id: "Fijo", nombre: "Fijo" }],
      clearSelectTipoDeVenta: false,
      clearSelectObraSocial: false,
      clearSelectCategoria: false,
      clearSelectTipo: false,
      TipoVentaKey: Date.now(),
      TipoKey: Date.now(),
      modo: null,
      headers: {
        'x-access-token': `${this.props.token}`,
      },
    }
  }
  componentDidMount() {
    this.fetchData();
    this.fetchDataTipoVenta();
    $("#creado_el").parent().hide();
    $("#switchActivo").hide();
    $("#agregar").hide();
    $("#existente").hide();
    $(".btn-met-cancelar").on("click", () => {
      this.setState({ categoriaDeVenta: '', obraSocial: '', tipoDeVenta: '', tipo: '', valor: '', idRegistro: '' }, () => {
        var campos = [
          { nombre: "#valor" },
        ];
        campos.forEach(function (campo) {
          var elementos = document.querySelectorAll(campo.nombre);
          elementos.forEach(function (elemento) {
            $(elemento).removeAttr('style');
          })
        });
        $("#selectTipoDeVenta .css-1cza3nx-control").removeAttr('style');
        $("#selectCategoria .css-1cza3nx-control").removeAttr('style');
        $("#selectObraSocial .css-1cza3nx-control").removeAttr('style');
        $("#selectTipo .css-1cza3nx-control").removeAttr('style');
        $("#existente").hide();
        $("#listado").show();
        $("#agregar").hide();
      })

    });
  }
  crearRegistro = () => {
    this.setState({
      categoriaDeVenta: '',
      obraSocial: '',
      tipoDeVenta: '',
      tipo: '',
      valor: '',
      idRegistro: '',
      clearSelectTipoDeVenta: !this.state.clearSelectTipoDeVenta,
      clearSelectObraSocial: !this.state.clearSelectObraSocial,
      clearSelectCategoria: !this.state.clearSelectCategoria,
      clearSelectTipo: !this.state.clearSelectTipo,
      TipoVentaKey: Date.now(),
      TipoKey: Date.now(),
      modo: "crear",

    }, () => {
      $("#selectTipoDeVenta .css-1cza3nx-control").removeAttr('style');
      $("#selectCategoria .css-1cza3nx-control").removeAttr('style');
      $("#selectObraSocial .css-1cza3nx-control").removeAttr('style');
      $("#selectTipo .css-1cza3nx-control").removeAttr('style');
      $("#valor").removeAttr('style');
      $("#listado").hide();
      $("#existente").hide();
      $("#agregar").show();
    })
  }
  validar = () => {
    let campoVacio = false;
    let mensaje = "";
    var errorStyles = {
      'border-color': '#f56565',
      'color': '#2a2222',
      'border-width': '3px',
    };
    let tipoDeVenta = this.state.tipoDeVenta;
    let categoria = this.state.categoriaDeVenta;
    let obraSocial = this.state.obraSocial;
    let tipo = this.state.tipo;
    var campos = [
      { nombre: this.state.valor, selector: "#valor", tipo: "id" },
    ];

    if (tipo !== "") {
      campos.forEach(function (campo) {
        if (campo.nombre === "" || campo.nombre === null || campo.nombre === undefined) {
          try {
            var elementos = document.querySelectorAll(campo.selector);
            elementos.forEach(function (elemento) {
              $(elemento).css(errorStyles);
            })
          } catch {
          }
          if (campo.tipo === "id") {
            document.getElementById(campo.selector.substring(1)).focus();
            document.getElementById(campo.selector.substring(1)).select();
          }
          try {
            this.scrollToElement(campo.selector);
          } catch {
          }
          campoVacio = true;
        }
        else {
          //Validar que tenga el formato decimal correcto
          var re = new RegExp(/(?<=^| )\d+(\.\d+)?(?=$| )/);
          if (re.test(campo.nombre)) {
            $(campo.selector).removeAttr('style');
            $(campo.selector).addClass('form-control');
          }
          else {
              elementos = document.querySelectorAll(campo.selector);
              elementos.forEach(function (elemento) {
                $(elemento).css(errorStyles);
              })
            
            if (campo.tipo === "id") {
              document.getElementById(campo.selector.substring(1)).focus();
              document.getElementById(campo.selector.substring(1)).select();
            }
            try {
              this.scrollToElement(campo.selector);
            } catch {
            }
            mensaje = "El campo Valor debe contener un número decimal válido.";
          }
        }
      });
    }

    if (tipoDeVenta === "") {
      $("#selectTipoDeVenta .css-1cza3nx-control").css(errorStyles);
      campoVacio = true;
    } else {
      $("#selectTipoDeVenta .css-1cza3nx-control").removeAttr('style');
    }
    if (categoria === "") {
      $("#selectCategoria .css-1cza3nx-control").css(errorStyles);
      campoVacio = true;
    } else {
      $("#selectCategoria .css-1cza3nx-control").removeAttr('style');
    }
    if (obraSocial === "") {
      $("#selectObraSocial .css-1cza3nx-control").css(errorStyles);
      campoVacio = true;
    } else {
      $("#selectObraSocial .css-1cza3nx-control").removeAttr('style');
    }
    if (tipo === "") {
      $("#selectTipo .css-1cza3nx-control").css(errorStyles);
      campoVacio = true;
    } else {
      $("#selectTipo .css-1cza3nx-control").removeAttr('style');
    }


    if (campoVacio) {
      return {
        error: true,
        mensaje: "Para continuar debe completar todos los campos."
      };
    }
    else {
      if (mensaje !== "") {
        return {
          error: true,
          mensaje: mensaje
        };
      }
      else {
        return {
          error: false
        };
      }
    }
  }

  async eliminar(id) {
    Swal.fire({
      title: "¿Está seguro que quiere eliminar el registro?",
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "No",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si"
    }).then((result) => {
      if (result.isConfirmed) {
        const headers = {
          'x-access-token': `${this.state.token}`,
          "Content-Type": "application/json"
        };
        fetch(url + "descuentos-estimados/" + id, {
          method: 'Delete',
          headers
        }).then(() => {
          Swal.fire({
            title: "Se elimino el registro",
            icon: "success",
            timer: 2000
          });
          this.actualizarGrilla();
        }).catch(err => {
          console.error('Error al enviar la petición POST:', err);
        });

      }
    });
  }
  guardarNuevo = () => {
    let validacion = this.validar();
    if (!validacion.error) {
      var respuestaJson;
      const Data = {
        categoriaDeVenta: this.state.categoriaDeVenta,
        obraSocial: this.state.obraSocial,
        tipo: this.state.tipo,
        valor: this.state.valor,
        multiplicaPorAfiliados: this.state.multiplicaPorAfiliados,
      };
      const headers = {
        'x-access-token': `${this.state.token}`,
        "Content-Type": "application/json"
      };

      fetch(url + "descuentos-estimados", {
        method: 'POST',
        body: JSON.stringify(Data),
        headers
      }).then(response => {
        if (!response.ok) {
          console.log("Error en la petición");
          console.log(response);
        }
        else {
          respuestaJson = response.json();
        }
        return respuestaJson;
      }).then(() => {
        Swal.fire({
          icon: "success",
          title: "Descuento creada",
          timer: 2000,
          text: "Se creo el registro correctamente.",
        });
        this.actualizarGrilla();
        $("#listado").show();
        $("#agregar").hide();
      })
        .catch(err => {
          console.error('Error al enviar la petición POST:', err);
        });
    } else {
      Swal.fire({
        icon: "error",
        text: validacion.mensaje,
        width: 500,
        height: 200,
      });
    }

  }
  actualizarRegistro = () => {
    let validacion = this.validar();
    if (!validacion.error) {
      var respuestaJson;
      const headers = {
        'x-access-token': `${this.state.token}`,
        "Content-Type": "application/json"
      };
      let valor = this.state.valor;
      valor = valor.replace("$", "");
      valor = valor.replace("%", "");
      valor = valor.replace(".", "");
      valor = valor.replace(",", ".");
      const Data = {
        categoriaDeVenta: this.state.categoriaDeVenta,
        obraSocial: this.state.obraSocial,
        tipo: this.state.tipo,
        valor: `${valor}`,
        multiplicaPorAfiliados: this.state.multiplicaPorAfiliados,
      };
      fetch(url + "descuentos-estimados/" + this.state.idRegistro, {
        method: 'PUT',
        body: JSON.stringify(Data),
        headers
      }).then(response => {
        if (!response.ok) {
          console.log("Error en la petición");
          console.log(response);
        }
        else {
          respuestaJson = response.json();
        }
        return respuestaJson;
      }).then(() => {
        this.setState({ categoriaDeVenta: '', obraSocial: '', tipo: '', valor: '', idRegistro: '' }, () => {
          Swal.fire({
            icon: "success",
            title: "Descuento actualizado",
            timer: 2000,
            text: "Se actualizo el registro correctamente.",
          });
          this.actualizarGrilla();
          $("#existente").hide();
          $("#listado").show();
          $("#agregar").hide();
        });
      }).catch(err => {
        console.error('Error al enviar la petición POST:', err);
      });
    } else {
      Swal.fire({
        icon: "error",
        text: validacion.mensaje,
        width: 500,
        height: 200,
      });
    }
  }
  async fetchData() {
    try {
      const { headers } = this.state;
      const response = await fetch(url + "descuentos-estimados?detallado=1&habilitado=1", { headers });
      const responseData = await response.json();
      this.setState({ data: responseData }, () => { });
    } catch (error) {
      console.error('Error al obtener los datos:', error);
    }
  }
  async fetchDataTipoVenta() {
    try {
      const { headers } = this.state;
      const response = await fetch(url + "tipos-de-venta?habilitado=1", { headers });
      const responseData = await response.json();
      this.setState({ dataTipoVenta: responseData }, () => { });
    } catch (error) {
      console.error('Error al obtener los datos:', error);
    }
  }
  async fetchDataCategoria() {
    try {
      const { headers } = this.state;
      const response = await fetch(url + `categorias-de-venta?tipoDeVenta=${this.state.tipoDeVenta}`, { headers });
      const responseData = await response.json();
      this.setState({ dataCategoria: responseData });
    } catch (error) {
      console.error('Error al obtener los datos:', error);
    }
  }
  async fetchDataObraSocial() {
    try {
      const { headers } = this.state;
      const response = await fetch(url + `obras-sociales?habilitado=1`, { headers });
      const responseData = await response.json();
      this.setState({ dataObraSocial: responseData });
    } catch (error) {
      console.error('Error al obtener los datos:', error);
    }
  }
  async obtenerRegistro(id) {
    try {
      const { headers } = this.state;
      const response = await fetch(url + "descuentos-estimados?detallado=1", { headers });
      const responseData = await response.json();

      if (responseData) {
        const filas = responseData.map((item) => ({
          id: item.id,
          creadoEl: item.creadoEl.substring(0, 10),
          tipoDeVenta: item.tipoDeVenta,
          categoriaDeVenta: item.categoriaDeVenta,
          obraSocial: item.obraSocial,
          tipo: item.tipo,
          valor: item.valor,
          precios: item.precio,
        }));

        for (const element of filas) {
          if (element.id === id) {
            const habilitado_ = element.deshabilitadoEl == null ? 'Si' : 'No';

            await this.setState({
              tipoDeVenta: element.tipoDeVenta,
              categoriaDeVenta: element.categoriaDeVenta,
              tipo: element.tipo,
              valor: this.formatearPrecio(element.valor),
              obraSocial: element.obraSocial,
              creadoEl: element.creadoEl,
              idRegistro: element.id,
              habilitado: habilitado_,
            }, async () => {
              await this.fetchDataTipoVenta();
              this.setState({ tipoDeVenta: this.state.dataTipoVenta.find(option => option.nombre === this.state.tipoDeVenta).id }, async () => {

                await this.fetchDataCategoria();
                await this.fetchDataObraSocial();

                this.setState({ categoriaDeVenta: this.state.dataCategoria.find(option => option.nombre === this.state.categoriaDeVenta).id })
                await this.fetchDataObraSocial();
                const tituloExistente = this.state.tipoDeVenta !== null ? `Descuento Estimado: ${element.tipoDeVenta} - ${element.categoriaDeVenta} - ${this.state.obraSocial}` : "";
                this.setState({ tituloExistente: tituloExistente });
                this.setState({ obraSocial: this.state.dataObraSocial.find(option => option.nombre === this.state.obraSocial).id }, () => {
                  $("#listado").hide();
                  $("#existente").show();
                  $("#agregar").hide();

                })

              });
            });


          }
        }
      }
    } catch (error) {
      console.error('Error al obtener los datos:', error);
    }
  }
  formatearPrecio = valor => {
    let numero = parseFloat(valor);
    if (!numero) {
      return "0,00";
    }
    else {
      numero = Math.round(numero * 100) / 100
      let resultado = numero.toLocaleString("es-ar");
      if (resultado === "0") {
        return "0,00";
      }
      else {
        let posicionComa = resultado.indexOf(",");
        if (posicionComa === -1) {
          resultado = resultado.concat(",00");
        }
        else if (resultado.length - posicionComa === 2) {
          resultado = resultado.concat("0");
        }
        return resultado;
      }
    }
  }
  handleValor = (newValue) => {
    var valor = newValue.replace(/[^0-9.]/g, '');
    this.setState({ valor: valor });
  }
  handleActivoChange = (event) => {
    let habilitar = ''
    if (event.target.checked === true) {
      habilitar = 'Si';
    } else {
      habilitar = 'No';
    }
    this.setState({ habilitado: habilitar });
  }
  handleTipoDeVentaChange = (opcion) => {
    if (opcion) {
      if (this.state.modo === "crear") {
        this.setState(
          {
            tipoDeVenta: opcion.id,
            clearSelectCategoria: true,
            categoriaDeVenta: '',
            clearSelectObraSocial: true,
            obraSocial: '',
            dataObraSocial: [],
          },
          () => {
            this.fetchDataCategoria();
          }
        );
      }

    }
  };
  handleCategoriaChange = (opcion) => {
    if (opcion) {
      this.setState({
        categoriaDeVenta: opcion.id,
        clearSelectCategoria: false,
        clearSelectObraSocial: true,
        obraSocial: '',
        dataObraSocial: [],
      },
        () => {
          this.fetchDataObraSocial();
        });
    }
  };
  handleObraSocialChange = (opcion) => {
    if (opcion) {
      this.setState({ obraSocial: opcion.id, clearSelectObraSocial: false, }, () => { });
    };
  };
  actualizarGrilla() {
    this.fetchData();
  }
   handleDoubleClick = (event) => {
    this.obtenerRegistro(event.data.id);
  }
render() {
    const { data } = this.state;
    const rows = data.map((item) => ({
      id: item.id,
      tipoDeVenta: item.tipoDeVenta,
      categoriaDeVenta: item.categoriaDeVenta,
      obraSocial: item.obraSocial,
      tipo: item.tipo,
      valor: item.tipo === "Porcentaje" ? this.formatearPrecio(item.valor) + "%" : "$" + this.formatearPrecio(item.valor),
      precios: item.precio,
    }));
    const agregarBotones = props => {
      return (
        <div className="contenedorBoton">
          <button className="botonVer" onClick={() => this.obtenerRegistro(props.data.id)}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-eye" viewBox="0 0 16 16">
              <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
              <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
            </svg>
          </button>
          <button onClick={() => this.eliminar(props.data.id)} className="botonBorrar">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16">
              <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z" />
              <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z" />
            </svg>
          </button>
        </div>

      )
    }
    const Columnas = [
      {
        field: 'tipoDeVenta', flex: 1, type: 'text', filter: 'agSetColumnFilter', renderHeader: () => (<strong> {'Tipo de Venta'}</strong>),
      },
      {
        field: 'categoriaDeVenta', flex: 1, type: 'text', filter: 'agSetColumnFilter', renderHeader: () => (<strong> {'Categoria'}</strong>),
      },
      {
        field: 'obraSocial', flex: 1, type: 'text', filter: 'agSetColumnFilter', renderHeader: () => (<strong> {'Obra Social'}</strong>),
      },
      {
        field: 'tipo', flex: 1, type: 'text', filter: 'agSetColumnFilter', renderHeader: () => (<strong> {'Tipo'}</strong>),
      },
      {
        field: 'valor', flex: 1, type: 'text', filter: 'agSetColumnFilter', renderHeader: () => (<strong> {'Valor'}</strong>),
      },
      {
        headerName: "",
        field: "",
        sortable: false,
        filter: false,
        width: "100px",
        cellRenderer: agregarBotones,
        cellRendererParams: {
          id: 1
        }
      },
    ];
    return (
      <div style={{ height: '100%', width: '100%' }}>
        <div id="listado">
          <div>
            <section>
              <div style={{ height: '50%', width: '100%', padding: "10px 0px 0px 0px" }}>
                <Box sx={{ width: '100%' }} style={{ backgroundColor: "white" }}>
                  <H texto={this.state.titulo} tamano={3}></H>
                  <div className="row g-3">
                    <div className="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups">
                      <div className="btn-group btn-group-lg" role="group" aria-label="First group">
                        <button type="button" className="btn btn-secondary" onClick={() => this.crearRegistro()} id="boton_nuevo">
                          <center>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus" viewBox="0 0 16 16">
                              <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                            </svg>
                          </center>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div
                    className="ag-theme-quartz"
                    style={{
                      width: "100%",
                      "--ag-font-size": "16px"
                    }}
                  >
                    <AgGridReact
                      columnDefs={Columnas}
                      rowData={rows}
                      pagination={true}
                      paginationPageSize={10}
                      paginationPageSizeSelector={[10, 20, 50, 100]}
                      domLayout='autoHeight'
                      localeText={localeEs}
                      reactiveCustomComponents={true}
                      onCellDoubleClicked={this.handleDoubleClick}
                    />

                  </div>
                </Box>
              </div>
            </section>
          </div>
        </div>
        <div id="agregar">
          <br></br>
          <H texto={`Nuevo Descuento Estimado`} tamano={3}></H>
          <br></br>
          <div className="card col-sm-12" >
            <br></br>
            <div className="row g-3">
              <div className="form-floating select-met col-md-3" id="selectTipoDeVenta">
                <SelectReact
                  name={"tipoDeVenta"}
                  inputId={"tipoDeVenta"}
                  isClearable={false}
                  isSearchable={false}
                  options={this.state.dataTipoVenta}
                  getOptionLabel={(opcion) => opcion.nombre}
                  getOptionValue={(opcion) => opcion.id}
                  placeholder=""
                  menuPortalTarget={document.body}
                  menuPosition={'fixed'}
                  menuPlacement="auto"
                  value={this.state.dataTipoVenta.find(option => option.id === this.state.tipoDeVenta)}
                  styles={estilosSelect}
                  onChange={this.handleTipoDeVentaChange}
                  key={this.state.TipoVentaKey}
                />
                <label htmlFor="tipoDeVenta">Tipo de Venta</label>
              </div>
              <div className="form-floating select-met col-md-3" id="selectCategoria">
                <SelectReact
                  name={"categoria"}
                  inputId={"categoria"}
                  isClearable={false}
                  isSearchable={false}
                  options={this.state.dataCategoria}
                  getOptionLabel={(opcion) => opcion.nombre}
                  getOptionValue={(opcion) => opcion.id}
                  placeholder=""
                  menuPortalTarget={document.body}
                  menuPosition={'fixed'}
                  menuPlacement="auto"
                  value={this.state.dataCategoria.find(option => option.id === this.state.categoriaDeVenta)}
                  styles={estilosSelect}
                  onChange={this.handleCategoriaChange}
                  key={this.state.clearSelectCategoria ? 'clear' : 'not-clear'}
                />
                <label htmlFor="categoria">Categoría</label>
              </div>
              <div className="form-floating select-met col-md-3" id="selectObraSocial">
                <SelectReact
                  name={"obraSocial"}
                  inputId={"obraSocial"}
                  isClearable={false}
                  isSearchable={false}
                  options={this.state.dataObraSocial}
                  getOptionLabel={(opcion) => opcion.nombre}
                  getOptionValue={(opcion) => opcion.id}
                  placeholder=""
                  menuPortalTarget={document.body}
                  menuPosition={'fixed'}
                  menuPlacement="auto"
                  value={this.state.dataObraSocial.find(option => option.id === this.state.obraSocial)}
                  styles={estilosSelect}
                  onChange={this.handleObraSocialChange}
                  key={this.state.clearSelectObraSocial ? 'clear' : 'not-clear'}
                />
                <label htmlFor="obraSocial">Obra Social</label>
              </div>
              <div className="form-floating select-met col-md-3" id="selectTipo">
                <SelectReact
                  name={"tipo"}
                  inputId={"tipo"}
                  isClearable={false}
                  isSearchable={false}
                  options={this.state.dataTipo}
                  getOptionLabel={(opcion) => opcion.nombre}
                  getOptionValue={(opcion) => opcion.id}
                  placeholder=""
                  menuPortalTarget={document.body}
                  menuPosition={'fixed'}
                  menuPlacement="auto"
                  value={this.state.dataTipo.find(option => option.id === this.state.tipo)}
                  styles={estilosSelect}
                  onChange={(opcion) => {
                    if (opcion) {
                      this.setState({ tipo: opcion.id }, () => {
                      });
                    }
                  }}
                  key={this.state.TipoKey}
                />
                <label htmlFor="tipo">Tipo</label>
              </div>
              {(() => {
                switch (this.state.tipo) {
                  case "Porcentaje":
                    return (
                      <FormInputEditablePorcentaje
                        tamano={3}
                        type="decimal"
                        id="valor"
                        value={this.state.valor}
                        placeholder="Valor"
                        name="valor"
                        readonly={false}
                        onChange={this.handleValor}
                      />
                    );
                  case "Fijo":
                    return (
                      <FormInputEditablePesos
                        tamano={3}
                        type="decimal"
                        id="valor"
                        value={this.state.valor}
                        placeholder="Valor"
                        name="valor"
                        readonly={false}
                        onChange={this.handleValor}
                      />
                    );
                  default:
                    return null;
                }
              })()}

            </div>
            <br></br>
            <div className="row g-3">
              <div className="form-floating mb-3 col-md-8"></div>
              <div className="form-floating mb-3 col-md-2" id="div_boton">
                <button
                  type="button"
                  className="btn btn-met float-end"
                  id="boton_generar"
                  onClick={this.guardarNuevo}
                >
                  Guardar
                </button>
              </div>
              <BotonCancelar />
            </div>
          </div>
        </div>
        <div id="existente">
          <div>
            <br></br>
            <div className="card col-sm-12">
              <br></br>
              <H texto={this.state.tituloExistente} tamano={3}></H>
              <br></br>
              <div className="row g-3">
                <div className="form-floating select-met col-md-3" id="selectTipoDeVenta">
                  <SelectReact
                    name={"tipoDeVenta"}
                    inputId={"tipoDeVenta"}
                    isClearable={false}
                    isSearchable={false}
                    options={this.state.dataTipoVenta}
                    getOptionLabel={(opcion) => opcion.nombre}
                    getOptionValue={(opcion) => opcion.id}
                    placeholder=""
                    menuPortalTarget={document.body}
                    menuPosition={'fixed'}
                    menuPlacement="auto"
                    value={this.state.dataTipoVenta.find(option => option.id === this.state.tipoDeVenta)}
                    styles={estilosSelect}
                    onChange={this.handleTipoDeVentaChange}
                  />
                  <label htmlFor="tipoDeVenta">Tipo de Venta</label>
                </div>
                <div className="form-floating select-met col-md-3" id="selectCategoria">
                  <SelectReact
                    name={"categoria"}
                    inputId={"categoria"}
                    isClearable={false}
                    isSearchable={false}
                    options={this.state.dataCategoria}
                    getOptionLabel={(opcion) => opcion.nombre}
                    getOptionValue={(opcion) => opcion.id}
                    placeholder=""
                    menuPortalTarget={document.body}
                    menuPosition={'fixed'}
                    menuPlacement="auto"
                    value={this.state.dataCategoria.find(option => option.id === this.state.categoriaDeVenta)}
                    styles={estilosSelect}
                    onChange={this.handleCategoriaChange}
                    key={this.state.clearSelectCategoria ? 'clear' : 'not-clear'}
                  />
                  <label htmlFor="categoria">Categoría</label>
                </div>
                <div className="form-floating select-met col-md-3" id="selectObraSocial">
                  <SelectReact
                    name={"obraSocial"}
                    inputId={"obraSocial"}
                    isClearable={false}
                    isSearchable={false}
                    options={this.state.dataObraSocial}
                    getOptionLabel={(opcion) => opcion.nombre}
                    getOptionValue={(opcion) => opcion.id}
                    placeholder=""
                    menuPortalTarget={document.body}
                    menuPosition={'fixed'}
                    menuPlacement="auto"
                    value={this.state.dataObraSocial.find(option => option.id === this.state.obraSocial)}
                    styles={estilosSelect}
                    onChange={this.handleObraSocialChange}
                    key={this.state.clearSelectObraSocial ? 'clear' : 'not-clear'}
                  />
                  <label htmlFor="obraSocial">Obra Social</label>
                </div>
                <div className="form-floating select-met col-md-3">
                  <SelectReact
                    name={"tipo"}
                    inputId={"tipo"}
                    isClearable={false}
                    isSearchable={false}
                    options={this.state.dataTipo}
                    getOptionLabel={(opcion) => opcion.nombre}
                    getOptionValue={(opcion) => opcion.id}
                    placeholder=""
                    menuPortalTarget={document.body}
                    menuPosition={'fixed'}
                    menuPlacement="auto"
                    value={this.state.dataTipo.find(option => option.id === this.state.tipo)}
                    styles={estilosSelect}
                    onChange={(opcion) => {
                      if (opcion) {
                        this.setState({ tipo: opcion.id }, () => {
                        });
                      }
                    }}
                  />
                  <label htmlFor="tipo">Tipo</label>
                </div>
                {(() => {
                  switch (this.state.tipo) {
                    case "Porcentaje":
                      return (
                        <FormInputEditablePorcentaje
                          tamano={3}
                          type="decimal"
                          id="valor"
                          value={this.state.valor}
                          placeholder="Valor"
                          name="valor"
                          readonly={false}
                          onChange={this.handleValor}
                        />
                      );
                    case "Fijo":
                      return (
                        <FormInputEditablePesos
                          tamano={3}
                          type="decimal"
                          id="valor"
                          value={this.state.valor}
                          placeholder="Valor"
                          name="valor"
                          readonly={false}
                          onChange={this.handleValor}
                        />
                      );
                    default:
                      return null;
                  }
                })()}

                <FormInput tamano={3} type="text" id="creado_el" value={this.state.creadoEl} placeholder="Creado el" name="creado_el" />
              </div>
              <div id="switchActivo" className="row g-3">
                <FormControl component="fieldset">
                  <FormGroup aria-label="position" row>
                    <FormControlLabel
                      control={<SwitchActivo checked={this.state.habilitado === 'Si'} />}
                      label="Activo"
                      labelPlacement="start"
                      onChange={this.handleActivoChange}
                    />
                  </FormGroup>
                </FormControl>
              </div>
              <div className="row g-3">
                <div className="form-floating mb-3 col-md-8"></div>
                <div className="form-floating mb-3 col-md-2" id="div_boton">
                  <button id="boton_actualizar" type="button" className="btn btn-met float-end" onClick={() => this.actualizarRegistro()}>Actualizar</button>
                </div>
                <div className="form-floating mb-3 col-md-2" id="div_boton">
                  <button id="boton_cancelar" type="button" className="btn btn-met-cancelar float-end">Cancelar</button>
                </div>
              </div>
              <br></br>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export { FromDescuentosEstimados }