const TraduccionListado = {
    noRowsLabel: 'Sin resultados',
    labelRowsPerPage: 'Filas por página',
    rowsPerPage: 'Filas por pagina',
    columnMenuLabel: 'Menu',
    columnMenuShowColumns: 'Mostrar columna',
    columnMenuManageColumns: 'Organizar',
    columnMenuFilter: 'Filtro',
    columnMenuHideColumn: 'Ocultar columna',
    columnMenuUnsort: 'Limpiar orden',
    columnMenuSortAsc: 'Ordenar de mayor a menor',
    columnMenuSortDesc: 'Ordenar de menor a mayor',
    // Filter panel text
    filterPanelAddFilter: 'Agregar filtro',
    filterPanelRemoveAll: 'Remover todos',
    filterPanelDeleteIconLabel: 'Borrar',
    filterPanelLogicOperator: 'Operador lógico',
    filterPanelOperator: 'Operadores',
    filterPanelOperatorAnd: 'Y',
    filterPanelOperatorOr: 'O',
    filterPanelColumns: 'Columnas',
    filterPanelInputLabel: 'Valor',
    filterPanelInputPlaceholder: 'Valor de filtro',
     // Filter operators text
     filterOperatorContains: 'contiene',
     filterOperatorEquals: 'es igual',
     filterOperatorStartsWith: 'comienza con',
     filterOperatorEndsWith: 'termina con',
     filterOperatorIs: 'es',
     filterOperatorNot: 'no es',
     filterOperatorAfter: 'es posterior',
     filterOperatorOnOrAfter: 'es en o posterior',
     filterOperatorBefore: 'es anterior',
     filterOperatorOnOrBefore: 'es en o anterior',
     filterOperatorIsEmpty: 'esta vacío',
     filterOperatorIsNotEmpty: 'no esta vacío',
     filterOperatorIsAnyOf: 'es cualquiera de',
     'filterOperator=': '=',
     'filterOperator!=': '!=',
     'filterOperator>': '>',
     'filterOperator>=': '>=',
     'filterOperator<': '<',
     'filterOperator<=': '<=',
    // Header filter operators text
    headerFilterOperatorContains: 'Contiene',
    headerFilterOperatorEquals: 'Igual que',
    headerFilterOperatorStartsWith: 'Empieza con',
    headerFilterOperatorEndsWith: 'Termina con',
    headerFilterOperatorIs: 'Es',
    headerFilterOperatorNot: 'No es',
    headerFilterOperatorAfter: 'Esta después de',
    headerFilterOperatorOnOrAfter: 'Esta en o después de',
    headerFilterOperatorBefore: 'Esta antes',
    headerFilterOperatorOnOrBefore: 'Esta en o antes de',
    headerFilterOperatorIsEmpty: 'Es vacio',
    headerFilterOperatorIsNotEmpty: 'No es vacio',
    headerFilterOperatorIsAnyOf: 'Es cualquiera de',
    'headerFilterOperator=': 'Igual a',
    'headerFilterOperator!=': 'Distinto de',
    'headerFilterOperator>': 'Mayor que',
    'headerFilterOperator>=': 'Mayor o igual que',
    'headerFilterOperator<': 'Menor que',
    'headerFilterOperator<=': 'Menor o igual que',
  }

export default TraduccionListado;