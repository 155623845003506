import ReactDOM from 'react-dom/client';
const url = 'https://budget.verticall.ar/api/';
//const url = 'http://217.21.78.142:3002/';
const root = ReactDOM.createRoot(document.getElementById('root'));
var globals = {
  nombreUsuario: ""
};

const estilosSelect = {
    control: (baseStyles) => ({
    ...baseStyles,
    borderWidth: "3px",
    borderColor: "#dee2e6",
    borderRadius: "6px",
    maxHeight : "58px"
  }),
  indicatorSeparator:(baseStyles) => ({
    ...baseStyles,
    height: '37px'
  }),
  indicatorsContainer: (baseStyles) => ({
    ...baseStyles,
    height: '58px'
  }),
  input: (baseStyles) => ({
    ...baseStyles,
    paddingTop: "5px"
  }),
  singleValue: (baseStyles) => ({
    ...baseStyles,
    paddingTop: "5px"
  }),
  placeholder: (baseStyles) => ({
    ...baseStyles,
    paddingTop: "5px"
  })
  };
  const agregarBotones = props => {
    return (
      <div className="contenedorBoton">
        <button  className="botonVer" onClick={() => this.ver(props.data.id)}>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-eye" viewBox="0 0 16 16">
            <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
            <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
          </svg>
        </button>
        <button onClick={() => this.eliminar(props.data.id)} className="botonBorrar">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16">
              <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z" />
              <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z" />
            </svg>
        </button>
      </div>
      
    )
  }
export {
    url,
    root,
    estilosSelect,
    globals,
    agregarBotones
};