import PropTypes from 'prop-types';
import { DataGrid } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import TraduccionListado from './Traduccion';
import Switch from '@mui/material/Switch';
import { styled } from '@mui/material/styles';

const SwitchActivo = styled(Switch)(({ theme }) => ({
  padding: 8,
  '& .MuiSwitch-track': {
    borderRadius: 22 / 2,
    '&:before, &:after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      width: 16,
      height: 16,
    },
    '&:before': {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main),
      )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
      left: 12,
    },
    '&:after': {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main),
      )}" d="M19,13H5V11H19V13Z" /></svg>')`,
      right: 12,
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: 'none',
    width: 16,
    height: 16,
    margin: 2,
  },
}));
const Input = (props) => {
  return (
    <div className={"form-floating col-md-" + props.tamano}>
      <input type={props.type} className="form-control" id={props.id} placeholder={props.placeholder} name={props.name} maxLength={36}></input>
      <label htmlFor={props.name}>{props.placeholder}</label>
    </div>
  )
}

Input.propTypes = {
  tamano: PropTypes.number,
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string
}
const FormInput = (props) =>{
  if(props.placeholder){
    return(
      <div className={"form-floating col-md-" + props.tamano}>
          <input type={props.type} className="form-control" value={props.value} id={props.id} placeholder={props.placeholder} name={props.name} readOnly/>
          <label htmlFor={props.id}>{props.placeholder}</label>
      </div>
    )
  }
  else{
    return(
      <div className={"form-floating col-md-" + props.tamano}>
          <input type={props.type} className="form-control" value={props.value} id={props.id} name={props.name} readOnly/>
      </div>
    )
  }
}
const FormInputEditable = (props) => {
  const handleChange = (event) => {
    props.onChange(event.target.value);
  };

  if (props.placeholder) {
    return (
      <div className={"form-floating col-md-" + props.tamano}>
        <input
          type={props.type}
          className="form-control"
          value={props.value}
          id={props.id}
          placeholder={props.placeholder}
          name={props.name}
          readOnly={props.readonly === "true" ? true : false}
          onChange={handleChange} 
        />
        <label htmlFor={props.id}>{props.placeholder}</label>
      </div>
    )
  } else {
    return (
      <div className={"form-floating col-md-" + props.tamano}>
        <input
          type={props.type}
          className="form-control"
          value={props.value}
          id={props.id}
          name={props.name}
          readOnly
          onChange={handleChange}
        />
      </div>
    )
  }
}

const FormInputEditablePorcentaje = (props) => {
  const handleChange = (event) => {
    props.onChange(event.target.value);
  };

  if (props.placeholder) {
    return (
      <div className={"form-floating col-md-" + props.tamano}>
        <input
          type={props.type}
          className="form-control"
          value={props.value}
          id={props.id}
          placeholder={props.placeholder}
          name={props.name}
          readOnly={props.readonly === "true" ? true : false}
          onChange={handleChange} 
          style={{paddingRight: "1.5rem"}}
        />
        <label htmlFor={props.id}>{props.placeholder}</label>
        <span style={{float: "right", marginTop: "-2.06rem", marginRight: "0.5rem"}}>%</span>
      </div>
    )
  } else {
    return (
      <div className={"form-floating col-md-" + props.tamano}>
        <input
          type={props.type}
          className="form-control"
          value={props.value}
          id={props.id}
          name={props.name}
          readOnly
          onChange={handleChange}
          style={{paddingRight: "1.5rem"}}
        />
        <span className={"signo-porcentaje"} style={{float: "right", marginTop: "-2.06rem", marginRight: "0.5rem"}}>%</span>
      </div>
    )
  }
}

const FormInputEditablePesos = (props) => {
  const handleChange = (event) => {
    props.onChange(event.target.value);
  };

  if (props.placeholder) {
    return (
      <div className={"form-floating col-md-" + props.tamano}>
        <input
          type={props.type}
          className="form-control"
          value={props.value}
          id={props.id}
          placeholder={props.placeholder}
          name={props.name}
          readOnly={props.readonly === "true" ? true : false}
          onChange={handleChange} 
          style={{paddingLeft: "1.5rem"}}
        />
        <label htmlFor={props.id}>{props.placeholder}</label>
        <span style={{float: "left", marginTop: "-2.06rem", marginLeft: "0.5rem"}}>$</span>
      </div>
    )
  } else {
    return (
      <div className={"form-floating col-md-" + props.tamano}>
        <input
          type={props.type}
          className="form-control"
          value={props.value}
          id={props.id}
          name={props.name}
          readOnly
          onChange={handleChange}
          style={{paddingLeft: "1.5rem"}}
        />
        <span style={{float: "left", marginTop: "-2.06rem", marginLeft: "0.5rem"}}>$</span>
      </div>
    )
  }
}


const FormInputEditablePesosPorcentaje = (props) => {
  const handleChange = (event) => {
    props.onChange(event.target.value);
  };

  if (props.placeholder) {
    return (
      <div className={"form-floating col-md-" + props.tamano}>
        <input
          type={props.type}
          className="form-control"
          value={props.value}
          id={props.id}
          placeholder={props.placeholder}
          name={props.name}
          readOnly={props.readonly === "true" ? true : false}
          onChange={handleChange} 
        />
        <label htmlFor={props.id}>{props.placeholder}</label>
        <span className={"signo-porcentaje"} style={{display:"none", float: "right", marginTop: "-2.06rem", marginRight: "0.5rem"}}>%</span>
        <span className={"signo-pesos"} style={{display:"none", float: "left", marginTop: "-2.06rem", marginLeft: "0.5rem"}}>$</span>
      </div>
    )
  } else {
    return (
      <div className={"form-floating col-md-" + props.tamano}>
        <input
          type={props.type}
          className="form-control"
          value={props.value}
          id={props.id}
          name={props.name}
          readOnly
          onChange={handleChange}
        />
        <span className={"signo-porcentaje"} style={{display:"none", float: "right", marginTop: "-2.06rem", marginRight: "0.5rem"}}>%</span>
        <span className={"signo-pesos"} style={{display:"none", float: "left", marginTop: "-2.06rem", marginLeft: "0.5rem"}}>$</span>
      </div>
    )
  }
}

const H = (props) => {
  const Etiqueta = `h${props.tamano}`;
  return (<Etiqueta className="text-muted" style={{ color: "#27ae6d !important" }}>{props.texto}</Etiqueta>);
}
H.propTypes = {
  tamano: PropTypes.number.isRequired,
  texto: PropTypes.string.isRequired,
}

const Select = ({ options, id, name, tamano, etiqueta }) => {
  return (
    <div className={"form-floating col-md-" + tamano}>
      <select className="form-select" id={id} name={name}>
        {options.map((option, index) => (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      <label htmlFor={id}>{etiqueta}</label>
    </div>
  );
};

Select.prototype = {
  options: PropTypes.arrayOf({ value: PropTypes.number, label: PropTypes.string }).isRequired,
  etiqueta: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  tamano: PropTypes.number,
}
const SelectAPI = ({ options, id, name, tamano, etiqueta }) => {
  return (
    <div className={"form-floating col-md-" + tamano} name={`div${etiqueta}`}>
      <select className="form-select" id={id} name={name}>
        {options.map((option, index) => (
          <option key={index} value={option.id}>
            {option.nombre}
          </option>
        ))}
      </select>
      <label htmlFor={id}>{etiqueta}</label>
    </div>
  );
};
function Listado({ titulo, filas, columnas }) {
  return (
    <div>
      <section>
        <div style={{ height: '100%', width: '100%', padding: "10px 0px 0px 0px" }}>
          <Box sx={{ width: '100%' }} style={{ backgroundColor: "white" }}>
            <H texto={titulo} tamano={3} ></H>
            <DataGrid
              disableRowSelectionOnClick
              rows={filas}
              columns={columnas}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 10, labelRowsPerPage: "Filas por página" },
                },
              }}
              style={{ backgroundColor: "white" }}
              pageSizeOptions={[5, 10]}
              localeText={TraduccionListado}
            />
          </Box>
        </div>
      </section>
    </div>

  );
}
const BotonCancelar = () => {
  return (
    <div className="form-floating mb-3 col-md-2" id="div_boton">
      <button id="boton_cancelar" type="button" className="btn btn-met-cancelar float-end">Cancelar</button>
    </div>
  )
}

export {
  Input,
  FormInput,
  FormInputEditable,
  FormInputEditablePorcentaje,
  FormInputEditablePesos,
  FormInputEditablePesosPorcentaje,
  Select,
  SelectAPI,
  H,
  Listado,
  BotonCancelar,
  SwitchActivo
};