export const localeEs = {
    // Set Filter
    selectAll: 'Seleccionar todo',
    selectAllSearchResults: 'Seleccionar todos los resultados de búsqueda',
    addCurrentSelectionToFilter: 'Agregar selección actual al filtro',
    searchOoo: 'Buscar...',
    blanks: 'Vacíos',
    noMatches: 'Sin coincidencias',

    // Number Filter & Text Filter
    filterOoo: 'Filtro...',
    equals: 'Igual a',
    notEqual: 'Diferente a',
    blank: 'Vacíos',
    notBlank: 'No vacíos',
    empty: 'Seleccionar',

    // Number Filter
    lessThan: 'Menor que',
    greaterThan: 'Mayor que',
    lessThanOrEqual: 'Menor o igual que',
    greaterThanOrEqual: 'Mayor o igual que',
    inRange: 'Entre las fechas',
    inRangeStart: 'Desde',
    inRangeEnd: 'Hasta',

    // Text Filter
    contains: 'Contiene',
    notContains: 'No contiene',
    startsWith: 'Comienza con',
    endsWith: 'Termina con',

    // Date Filter
    dateFormatOoo: 'dd/mm/Yyyy',
    before: 'Anterior a',
    after: 'Posterior a',

    // Filter Conditions
    andCondition: 'Y',
    orCondition: 'O',

    // Filter Buttons
    applyFilter: 'Aplicar',
    resetFilter: 'Reiniciar',
    clearFilter: 'Borrar',
    cancelFilter: 'Cancelar',

    // Side Bar
    columns: 'Columnas',
    filters: 'Filtros',

    // columns tool panel
    pivotMode: 'Modo Pivote',
    groups: 'Grupos de columnas',
    rowGroupColumnsEmptyMessage: 'Soltar aquí para crear un grupo de columnas',
    values: 'Valores',
    valueColumnsEmptyMessage: 'Soltar aquí para agregar',
    pivots: 'Column Labels',
    pivotColumnsEmptyMessage: 'Soltar aquí para indicar etiquetas de columna',

    // Header of the Default Group Column
    group: 'Grupo',

    // Row Drag
    rowDragRow: 'fila',
    rowDragRows:'filas',
    
    // Other
    loadingOoo: 'Cargando...',
    loadingError: 'ERROR',
    noRowsToShow: 'No hay filas para mostrar',
    enabled: 'Activado',

    // Menu
    pinColumn: 'Fijar Columna',
    pinLeft: 'Fijar a Izquierda',
    pinRight: 'Fijar a Derecha',
    noPin: 'Soltar',
    valueAggregation: 'Agregación por valor',
    noAggregation: 'Ninguna',
    autosizeThiscolumn: 'Tamaño automático para esta Columna',
    autosizeAllColumns: 'Tamaño automático para todas las Columnas',
    groupBy: 'Agrupar por',
    ungroupBy: 'Desagrupar por',
    ungroupAll: 'Desagrupar todo',
    addToValues: 'Agregar ${variable} a valores',
    removeFromValues: 'Eliminar ${variable} de valores',
    addToLabels: 'Agregar ${variable} a etiquetas',
    removeFromLabels: 'Remove ${variable} from labels',
    resetColumns: 'Reiniciar Columnas',
    expandAll: 'Expandir Todo',
    collapseAll: 'Cerrar Todo',
    copy: 'Copiar',
    ctrlC: 'Ctrl+C',
    ctrlX: 'Ctrl+X',
    copyWithHeaders: 'Copiar con Cabeceras',
    copyWithGroupHeaders: 'Copiar con Cabeceras de Grupo',
    cut: 'Cut',
    paste: 'Pegar',
    ctrlV: 'Ctrl+V',
    export: 'Exportar',
    csvExport: 'Exportar a CSV',
    excelExport: 'Exportar a Excel (.xlsx)',
    excelXmlExport: 'Exportar a Excel antiguo (.xml)',

    // Enterprise Menu Aggregation and Status Bar
    sum: 'Sum',
    first: 'Primero',
    last: 'Último',
    min: 'Min',
    max: 'Max',
    none: 'Nada',
    count: 'Cuenta',
    avg: 'Media',
    filteredRows: 'Filtrado',
    selectedRows: 'Seleccionado',
    totalRows: 'Total Filas',
    totalAndFilteredRows: 'Filas',
    more: 'Más',
    to: 'a',
    of: 'de',
    page: 'Página',
    pageLastRowUnknown: '?',
    nextPage: 'Siguiente',
    lastPage: 'Última',
    firstPage: 'Primera',
    previousPage: 'Anterior',
    pageSizeSelectorLabel: 'Cantidad por Página:',
    footerTotal: 'Total',
    next: 'Siguiente',
    last: 'Último',
    first: 'Primero',
    previous: 'Anterior',

    // Pivoting
    pivotColumnGroupTotals: 'Total',

    // Enterprise Menu (Charts)
    pivotChartAndPivotMode: 'Gráfico de Pivote y Modo Pivote',
    pivotChart: 'Gráfico de Pivote',
    chartRange: 'Gráfico de Intervalo',

    columnChart: 'Columna',
    groupedColumn: 'Agrupado',
    stackedColumn: 'Apilado',
    normalizedColumn: '100% Apilado',

    barChart: 'Barra',
    groupedBar: 'Agrupado',
    stackedBar: 'Apilado',
    normalizedBar: '100% Apilado',

    pieChart: 'Torta',
    pie: 'Torta',
    doughnut: 'Anillo',

    line: 'Línea',

    xyChart: 'X Y (Dispersión)',
    scatter: 'Dispersión',
    bubble: 'Burbuja',

    areaChart: 'Area',
    area: 'Area',
    stackedArea: 'Apilado',
    normalizedArea: '100% Apilado',

    histogramChart: 'Histograma',
    histogramFrequency: "Frecuencia",

    combinationChart: 'Combinación',
    columnLineCombo: 'Columnas y Líneas',
    AreaColumnCombo: 'Área & Columnas',

    // Charts
    pivotChartTitle: 'Gráfico de Pivote',
    rangeChartTitle: 'Gráfico de Intervalo',
    settings: 'Configuración',
    data: 'Datos',
    format: 'Formato',
    categories: 'Categorías',
    defaultCategory: '(Ninguna)',
    series: 'Series',
    xyValues: 'Valores X Y',
    paired: 'Mode pareado',
    axis: 'Eje',
    navigator: 'Navegador',
    color: 'Color',
    thickness: 'Grosor',
    xType: 'Tipo X',
    automatic: 'Automático',
    category: 'Categoría',
    number: 'Número',
    time: 'Tiempo',
    autoRotate: 'Rotación automática',
    xRotation: 'Rotación X',
    yRotation: 'Rotación Y',
    ticks: 'Ticks',
    width: 'Ancho',
    height: 'Alto',
    length: 'Longitud',
    padding: 'Relleno',
    spacing: 'Espaciado',
    chart: 'Gráfico',
    title: 'Título',
    titlePlaceholder: 'Título del Gráfico - doble clic para editar',
    background: 'Fondo',
    font: 'Fuente',
    top: 'Arriba',
    right: 'Derecha',
    bottom: 'Abajo',
    left: 'Izquierda',
    labels: 'Etiquetas',
    size: 'Tamaño',
    minSize: 'Tamaño mínimo',
    maxSize: 'Tamaño máximo',
    legend: 'Leyenda',
    position: 'Posición',
    markerSize: 'Ancho pincel',
    markerStroke: 'Trazo pincel',
    markerPadding: 'Relleno pincel',
    itemSpacing: 'Espaciado del ítem',
    itemPaddingX: 'Relleno X del ítem',
    itemPaddingY: 'Relleno Y del ítem',
    layoutHorizontalSpacing: 'Espaciado horizontal',
    layoutVerticalSpacing: 'Espaciado vertical',
    strokeWidth: 'Ancho del trazo',
    lineDash: 'Punteado',
    offset: 'Desplazamiento',
    offsets: 'Desplazamiento',
    tooltips: 'Tooltips',
    callout: 'Rótulo',
    markers: 'Pinceles',
    shadow: 'Sombra',
    blur: 'Difuminado',
    xOffset: 'Desplazamiento X',
    yOffset: 'Desplazamiento Y',
    lineWidth: 'Ancho de línea',
    normal: 'Normal',
    bold: 'Negrita',
    italic: 'Itálica',
    boldItalic: 'Negrita Itálica',
    predefined: 'Predefinido',
    fillOpacity: 'Opacidad de relleno',
    strokeOpacity: 'Opacidad de línea',
    histogramBinCount: 'Total papelera',
    columnGroup: 'Columna',
    barGroup: 'Barra',
    pieGroup: 'Tarta',
    lineGroup: 'Línea',
    scatterGroup: 'X Y (Dispersión)',
    areaGroup: 'Area',
    histogramGroup: 'Histograma',
    groupedColumnTooltip: 'Agrupado',
    stackedColumnTooltip: 'Apilado',
    normalizedColumnTooltip: '100% Apilado',
    groupedBarTooltip: 'Agrupado',
    stackedBarTooltip: 'Apilado',
    normalizedBarTooltip: '100% Apilado',
    pieTooltip: 'Torta',
    doughnutTooltip: 'Anillo',
    lineTooltip: 'Línea',
    groupedAreaTooltip: 'Area',
    stackedAreaTooltip: 'Apilado',
    normalizedAreaTooltip: '100% Apilado',
    scatterTooltip: 'Dispersión',
    bubbleTooltip: 'Burbuja',
    histogramTooltip: 'Histograma',
    columnLineComboTooltip: 'Columnas y Líneas',
    areaColumnComboTooltip: 'Área y Columnas',
    customComboTooltip: 'Combinación personalizada',
    noDataToChart: 'No hay datos disponibles para hacer el gráfico.',
    pivotChartRequiresPivotMode: 'Gráfico de Pivote requiere activar el modo Pivote.',
    chartSettingsToolbarTooltip: 'Menú',
    chartLinkToolbarTooltip: 'Vinculado a la grilla',
    chartUnlinkToolbarTooltip: 'Desvinculado de la grilla',
    chartDownloadToolbarTooltip: 'Descargar Gráfico',
    seriesChartType: 'Tipo de Gráfico de Series',
    seriesType: 'Tipo de Series',
    secondaryAxis: 'Eje secundario',

    // ARIA
    ariaAdvancedFilterBuilderItem: '${variable}. Level ${variable}. Press ENTER to edit.',
    ariaAdvancedFilterBuilderItemValidation: '${variable}. Level ${variable}. ${variable} Press ENTER to edit.',
    ariaAdvancedFilterBuilderList: 'Advanced Filter Builder List',
    ariaAdvancedFilterBuilderFilterItem: 'Filter Condition',
    ariaAdvancedFilterBuilderGroupItem: 'Filter Group',
    ariaAdvancedFilterBuilderColumn: 'Column',
    ariaAdvancedFilterBuilderOption: 'Option',
    ariaAdvancedFilterBuilderValueP: 'Value',
    ariaAdvancedFilterBuilderJoinOperator: 'Join Operator',
    ariaAdvancedFilterInput: 'Advanced Filter Input',
    ariaChecked: 'checked',
    ariaColumn: 'Column',
    ariaColumnGroup: 'Column Group',
    ariaColumnFiltered: 'Column Filtered',
    ariaColumnSelectAll: 'Toggle Select All Columns',
    ariaDateFilterInput: 'Date Filter Input',
    ariaDefaultListName: 'List',
    ariaFilterColumnsInput: 'Filter Columns Input',
    ariaFilterFromValue: 'Filter from value',
    ariaFilterInput: 'Filter Input',
    ariaFilterList: 'Filter List',
    ariaFilterToValue: 'Filter to value',
    ariaFilterValue: 'Filter Value',
    ariaFilterMenuOpen: 'Open Filter Menu',
    ariaFilteringOperator: 'Filtering Operator',
    ariaHidden: 'hidden',
    ariaIndeterminate:'indeterminate',
    ariaInputEditor: 'Input Editor',
    ariaMenuColumn: 'Press CTRL ENTER to open column menu',
    ariaRowDeselect: 'Press SPACE to deselect this row',
    ariaRowSelectAll: 'Press Space to toggle all rows selection',
    ariaRowToggleSelection: 'Press Space to toggle row selection',
    ariaRowSelect: 'Press SPACE to select this row',
    ariaSearch: 'Search',
    ariaSortableColumn: 'Press ENTER to sort',
    ariaToggleVisibility: 'Press SPACE to toggle visibility',
    ariaToggleCellValue: 'Press SPACE to toggle cell value',
    ariaUnchecked: 'unchecked',
    ariaVisible: 'visible',
    ariaSearchFilterValues: 'Search filter values',
    ariaPageSizeSelectorLabel: 'Page Size',

    // ARIA Labels for Drop Zones
    ariaRowGroupDropZonePanelLabel: 'Row Groups',
    ariaValuesDropZonePanelLabel: 'Values',
    ariaPivotDropZonePanelLabel: 'Column Labels',
    ariaDropZoneColumnComponentDescription: 'Press DELETE to remove',
    ariaDropZoneColumnValueItemDescription: 'Press ENTER to change the aggregation type',
    ariaDropZoneColumnGroupItemDescription: 'Press ENTER to sort',
    // used for aggregate drop zone, format: {aggregation}{ariaDropZoneColumnComponentAggFuncSeparator}{column name}
    ariaDropZoneColumnComponentAggFuncSeparator: ' of ',
    ariaDropZoneColumnComponentSortAscending: 'ascending',
    ariaDropZoneColumnComponentSortDescending: 'descending',

    // ARIA Labels for Dialogs
    ariaLabelColumnMenu: 'Column Menu',
    ariaLabelCellEditor: 'Cell Editor',
    ariaLabelDialog: 'Dialog',
    ariaLabelSelectField: 'Select Field',
    ariaLabelRichSelectField: 'Rich Select Field',
    ariaLabelTooltip: 'Tooltip',
    ariaLabelContextMenu: 'Context Menu',
    ariaLabelSubMenu: 'SubMenu',
    ariaLabelAggregationFunction: 'Aggregation Function',
    ariaLabelAdvancedFilterAutocomplete: 'Advanced Filter Autocomplete',
    ariaLabelAdvancedFilterBuilderAddField: 'Advanced Filter Builder Add Field',
    ariaLabelAdvancedFilterBuilderColumnSelectField: 'Advanced Filter Builder Column Select Field',
    ariaLabelAdvancedFilterBuilderOptionSelectField: 'Advanced Filter Builder Option Select Field',
    ariaLabelAdvancedFilterBuilderJoinSelectField: 'Advanced Filter Builder Join Operator Select Field',

    // ARIA Labels for the Side Bar
    ariaColumnPanelList: 'Column List',
    ariaFilterPanelList: 'Filter List',

    // Number Format (Status Bar, Pagination Panel)
    thousandSeparator: ',',
    decimalSeparator: '.',

    // Data types
    true: 'True',
    false: 'False',
    invalidDate: 'Invalid Date',
    invalidNumber: 'Invalid Number',
    january: 'January',
    february: 'February',
    march: 'March',
    april: 'April',
    may: 'May',
    june: 'June',
    july: 'July',
    august: 'August',
    september: 'September',
    october: 'October',
    november: 'November',
    december: 'December',
}